import React, { useState } from "react";
import styled from "styled-components";

const Alert = styled.div`
  padding: 20px;
  color: white;
  margin-bottom: 15px;
  opacity: 1;
  transition: opacity 0.6s;
  background-color: ${(props) =>
    props.type === "success" ? "#4CAF50" : "#f44336"};
`;

const CloseBtn = styled.span`
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: black;
  }
`;

const MessageBox = ({ type, message }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Alert type={type}>
      <CloseBtn onClick={handleClose}>&times;</CloseBtn>
      {message}
    </Alert>
  );
};

export default MessageBox;
