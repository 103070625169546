import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";

const SearchFormWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center; /* Align items in the center vertically */
    margin-bottom: 25px;
`;

const SearchInput = styled.input`
    flex: 1; /* Take up remaining space */
    padding: 7px;
    font-size: 16px;
    margin-right: 1rem; /* Add some space between input and button */
    border: 1px solid #aaa;
`;

const SearchForm = ({ primaryColor }) => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const params = useParams();
    const { companyName } = params;
    const [searchQuery, setSearchQuery] = useState(query.get('q') || '');

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            navigate(`/${companyName}/search-result?q=${event.target.value}`);
        }
    };

    const handleButtonClick = () => {
        navigate(`/${companyName}/search-result?q=${searchQuery}`);
    };

    return (
        <SearchFormWrapper>
            <SearchInput
                type="text"
                placeholder="Search..."
                onKeyPress={handleSearch}
                defaultValue={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button onClick={handleButtonClick} primaryColor={primaryColor}>
                Search
            </Button>
        </SearchFormWrapper>
    );
};

export default SearchForm;

const Button = styled.div`
    display: flex;
    justify-content: center;
    font-family: "AlternateGotNo2D", sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    background-color: ${props => props.primaryColor ? props.primaryColor : '#000000'};
    padding: 13px 20px 9px 20px;
    margin: 0.25rem 0;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.primaryColor};
        color: white;
    }
`;