import {useEffect, } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation, useParams, Navigate, useNavigate } from "react-router-dom";
import Modal from "../../components/modal";
import { useState } from "react";
import { SideBar } from "./SideBar";
import MediaPlayer from "../../components/media-hub/media-player";
import { getMediaId } from "../../utils/media/media";
import { 
  MEDIA_HUB_CONTENT, IN_A_BOX_CONTENT, MEDIA_HUB_TITLE_TILE, 
  IN_A_BOX_TITLE_TILE, IN_A_BOX, MEDIA_HUB, VIDEO_TYPE, LINK_TYPE
} from "../../utils/constants/gls-hub-content";


const getContent = (mediaHubContent, section, itemId) => {
  const item = mediaHubContent.find((item) => item.slug === section);
  const content = item?.contents.find((item) => item.id === parseInt(itemId));
  return content;
};


const getInnerContents = (contents, innerSection) => {
  const item = contents?.find((item) => item.slug === innerSection);
  return item?.contents;
};

const MediaHub = ({selectedHub, }) => {
  const navigate = useNavigate();
  const [mediaHubContent, setMediaHubContent] = useState([]);
  const [titleTile, setTitleTile] = useState([]);

  useEffect(() => {
    switch(selectedHub) {
      case MEDIA_HUB:
        setMediaHubContent(MEDIA_HUB_CONTENT);
        setTitleTile(MEDIA_HUB_TITLE_TILE)
        break;
      case IN_A_BOX:
        setMediaHubContent(IN_A_BOX_CONTENT);
        setTitleTile(IN_A_BOX_TITLE_TILE)
        break;
      default:
        break;
    }
  }, [selectedHub]);
  const [currentMedia, setCurrentMedia] = useState(null);
  const onClick = (media) => {
    const type = media.type ? media.type : VIDEO_TYPE;
    if (type === VIDEO_TYPE) {
      setCurrentMedia({
        title: media?.title,
        url: media?.url,
        id: 1,
      });
    } else if (type === LINK_TYPE) {
      if (media.url.startsWith('http') || media.url.startsWith('https')) {
        window.open(media.url, '_blank');
      } else {
        navigate(media.url);
      }
    }
  };
  const { pathname } = useLocation();
  const { section, item, innerSection } = useParams();
  const content = getContent(mediaHubContent, section, item);

  const contents = content?.innerContent
  const headerUrl = content?.innerHeader
  const innerContents = getInnerContents(contents, innerSection);
  if (contents?.length > 0 && !innerContents) {
    return <Navigate to={contents[0].path} replace />;
  }
  return (
    <OuterWrapper className="page-content-outter">
      <div className="page-content-inner">
        <SideBar
          menuItems={mediaHubContent}
          currentItem={pathname}
          title={<img alt={pathname} src={titleTile} />}
        />
        <ContentWrapper>
          <div className="page-close-wrapper">
            <Link role="link" to="/gls/lobby" className="page-close-link">
              <i className="fal fa-times" />
            </Link>
          </div>
          <MediaHubWrapper>
              <MediaHubItem key={0}>
                <Link
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    navigate(-1); // Navigate back
                  }}
                  className="media-item-link"
                >
                  <img src={headerUrl} alt={"header and back button"} className="img-fluid" />
                </Link>
              </MediaHubItem>
            {innerContents?.map((media) => (
              <MediaHubItem key={media.id} onClick={() => onClick(media)} bottomSpacer={media.bottomSpacer}>
                <div className="media-item-link">
                  <img src={media?.image} alt={media?.title} className="img-fluid" />
                </div>
              </MediaHubItem>
            ))}
          </MediaHubWrapper>
        </ContentWrapper>
      </div>
      <Modal
        footer={null}
        isOpen={!!currentMedia?.id}
        title={currentMedia?.title}
        closeModal={() => setCurrentMedia(null)}
      >
        <MediaPlayer mediaId={getMediaId(currentMedia?.url)} />
      </Modal>
    </OuterWrapper>
  );
};

export default MediaHub;

const MediaHubItem = styled.div`
  padding: 1rem;
  padding-bottom: ${(props) => (props.bottomSpacer ? '1rem' : '0')};
  padding-top: 0;
  cursor: pointer; /* This makes the cursor a pointer by default */
  
  &:hover {
    cursor: pointer; /* This ensures the cursor remains a pointer when hovered */
  }
  
  img {
    max-width: 100%;
    height: auto;
    transition: transform 0.2s; /* Optional: Adds a smooth transition effect */
  }
`;

const MediaHubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-bottom: 60px;
    margin-top: 20px;
  }
`;

const ContentWrapper = styled.div`
  padding: 2rem 0;
  flex-grow: 10;
  display: flex;

  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

const OuterWrapper = styled.div`
  max-width: calc(100vw - 4rem - 10px);
  margin-right: 10px;
  > div {
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    max-width: calc(100vw - 3rem - 10px);
    margin-bottom: 260px;
  }
`;