import { Helmet } from "react-helmet";
import { useAppConfig } from "../../contexts/app-config/app-config-context";

export const Head = () => {
  const { config } = useAppConfig();
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{config.general_title}</title>
      <link rel="apple-touch-icon" sizes="64x64" href={config.favicon} />
      <link
        rel="shortcut icon"
        id="favicon"
        sizes="32x32"
        href={config.favicon}
      />
      <link
        rel="mask-icon"
        sizes="32x32"
        href={config.favicon}
      />
      <link rel="icon" sizes="32x32" href={config.favicon} />
      <link rel="icon" sizes="192x192" href={config.favicon} />
      <link rel="icon" sizes="48x48" href={config.favicon} />
    </Helmet>
  );
};
