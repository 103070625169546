import { useState } from "react";
import styled from "styled-components";

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  align-self: end;
`;

const Switch = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 35px;
    top: 50%;
    left: 1px;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(19px, -50%);
    }
  }
`;

export const SwitchButton = ({ onChange, checked, label }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleOnChange = (e) => {
    setIsChecked(e.target.checked);
    onChange(e);
  };
  return (
    <Label>
      <span>{label}</span>
      <Input checked={isChecked} type="checkbox" onChange={handleOnChange} />
      <Switch />
    </Label>
  );
};
