import styled from "styled-components";

const FormSelect = styled.select`
  display: flex;
  box-sizing: border-box;
  padding: 0.4rem 0.3rem;
  width: 100%;
  outline: none;
  color: ${(props) => (props.error ? "red" : "#727272")};
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #727272")};
  background-color: transparent;
  box-shadow: none;
  :focus {
    box-shadow: 0 0 2px 2px rgba(0, 155, 119, 0.5);
  }
  text-align-last: center;
`;

export default FormSelect;
