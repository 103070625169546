import styled, { keyframes } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { SearchForm } from "../search-form";

const Layout = ({ children, sideBar, hidden }) => (
  <FullWidth id="content">
    <StyledLayout hidden={hidden}>
      <Content>
        { sideBar && <SideBar>{sideBar}</SideBar> }
        <ContentArea>
          
          {children}
        </ContentArea>
      </Content>
    </StyledLayout>
  </FullWidth>
);

const FullWidth = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 0.5rem;
  background-color: white;
  transform: translate3d(0, 0, 0);
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  ${breakpoint('xs')`
    flex-direction: column;
  `}
  ${breakpoint('lg')` // 24
    flex-direction: row;
  `}
`;

const SideBar = styled.div`
  flex-direction: column;
  flex: 0.25;
  animation-duration: 1s;
`;

const ContentArea = styled.div`
  flex: 0.75;
  padding-top: 3rem;
  ${breakpoint('xs')` // 24
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  `}*/
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /*  max-width: 700px;
  ${breakpoint("md")` // 24
    max-width: 900px;
  `}*/
  ${breakpoint("lg")` // 24
    max-width: 1000px;
  `}
  padding: 3rem 0;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
  animation: ${(props) => (props.hidden ? fadeOut : fadeIn)} 0.2s linear;
  transition: visibility 0.2s linear;
`;

export default Layout;
