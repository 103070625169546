import {mapImageData, mapVideoData} from "../../../utils/mappings";
import {IMAGE_TYPES, WHATS_NEW} from "../../../utils/constants";

const STATE = {
    loaded: false,
    error: false,
    active: false,
    title: 'UNDEFINED',
    media: {},
    visible: undefined,
    link_url: null, 
    open_in_new_tab: false
}

const mapWhatsNewData = (payload) => {
    const { data } = payload;
    if(data && data.length) {
        const { active, title, media, visible, text_color, link_url, open_in_new_tab } = [...data].shift();
        return {
            active: active && media.length > 0,
            title,
            visible: active && media.length > 0 && visible,
            textColor: text_color,
            media: media.length > 0 ? mapMedia(media) : {},
            link_url, 
            open_in_new_tab
        };
    }
    return {}
}

const mapMedia = (media) => {
    const element = media.shift();
    const { __component: type, coverImage, videoFile, image, url } = element;
    switch(type) {
        case 'elements.video':
            return {
                type: 'video',
                cover: mapImageData(coverImage, IMAGE_TYPES.FULL),
                video: mapVideoData(videoFile),
            }
        case 'elements.image':
            return {
                type: 'image',
                image: mapImageData(image, IMAGE_TYPES.FULL),
            }
        case 'elements.3-q':
            return {
                type: '3-q',
                url,
            }
        default:
            return {}
    }
}

const company = (state = STATE, action) => {
    const {type, payload} = action;
    switch (type) {
        case WHATS_NEW.LOAD:
            return {...state, loaded: false, error: false, active: false, title: 'UNDEFINED', media: {}, visible: undefined};
        case WHATS_NEW.LOAD_SUCCESS:
            const mappedData = mapWhatsNewData(payload);
            const {active, title, media, textColor, link_url, open_in_new_tab} = mappedData;
            let visible = mappedData.visible;

            // Only set visible from mappedData if the current state's visible is null or undefined
            if (state.visible === false) {
                visible = false
            }

            return {
                ...state,
                loaded: true,
                error: false,
                active,
                title,
                media,
                visible,
                textColor,
                link_url,
                open_in_new_tab
            };
        case WHATS_NEW.LOAD_ERROR:
            return {...state, loaded: false, error: true, active: false, title: 'UNDEFINED', media: {}, visible: false};
        case WHATS_NEW.SET_VISIBLE:
            return {...state, visible: payload && state.active };
        default:
            return state;
    }
}

export default company;
