import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Form from "../../components/form";
import FormAnnotation from "../../components/form-annotation";
import FormButton from "../../components/gls/form/FormButton";
import FormErrorMessage from "../../components/form-error-message";
import FormNotice from "../../components/form-notice/FormNotice";
import FormInput from "../../components/gls/form/FormInput";
import FormPanel from "../../container/form-panel";
import {resetPassword} from "../../store/user/actions";
import {passwordResetSchema} from "../../utils/form-schemata";
import useQuery from "../../utils/hooks/use-query";
import { trackEvent } from "../../store/tracking/actions";
import { TrackingEvent, TrackingObject, TrackingPageName } from "../../store/tracking/TrackingObject";

const ResetPassword = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const code = query.get('code') ?? 'unknown';

    useEffect(() => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.PAGE_VIEW, {
            pageName: TrackingPageName.PASSWORD_RESET,
            platformArea: "system"
        })))
    }, []);

    const {success, error, loading} = useSelector(state => {
        const {user: {reset: {success, error, loading}}} = state;
        return {success, error, loading};
    });

    const {
        register,
        setValue,
        handleSubmit,
        setError,
        formState: {
            errors
        }
    } = useForm({
        resolver: yupResolver(passwordResetSchema),
    });

    useEffect(() => {
        if (code && code !== 'unknown') {
            setValue('code', code);
        }
    }, [code, setValue]);

    const onSubmit = (data) => {
        console.log(data);
        dispatch(resetPassword(data));
    }

    const handleCancelClick = () => {
        navigate('..');
    }

    if(!code || code === 'unknown') {
        return (
            <FormPanel>
                <Form>
                    <h2>RESET PASSWORD</h2>
                    <p style={{"marginBottom": "1rem"}}>You've reached this page without a valid reset code. Please check your email inbox for a valid link.</p>
                    <FormButton onClick={handleCancelClick} value="GO BACK TO LOGIN" type="reset" primary/>
                </Form>
            </FormPanel>
        )
    }

    if (success) {
        return (
            <FormPanel>
                <Form>
                    <FormNotice>
                        <strong>SUCCESS</strong>
                    </FormNotice>
                    <p style={{"marginBottom": "1rem"}}>You've changed your password successfully.</p>
                    <FormButton onClick={handleCancelClick} title="GO BACK TO LOGIN" type="reset" primary/>
                </Form>
            </FormPanel>
        )
    }

    return (
        <FormPanel>
            <FormNotice>
                <strong>RESET YOUR PASSWORD</strong>
            </FormNotice>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                    type="password"
                    {...register("password", { required: true })}
                    placeholder="Password*"
                    error={errors.password}
                />
                <FormErrorMessage message={errors.password}/>
                <FormInput
                    type="password"
                    {...register("passwordConfirmation", { required: true })}
                    placeholder="Confirm password*"
                    error={errors.passwordConfirmation}
                />
                <FormErrorMessage message={errors.passwordConfirmation}/>
                <FormErrorMessage message={errors.code}/>
                <FormErrorMessage message={error}/>
                <FormAnnotation>* required field</FormAnnotation>
                <FormButton loading={loading} type="submit" title="SAVE NEW PASSWORD" primary/>
                <FormButton onClick={handleCancelClick} title="CANCEL" type="reset"/>
            </Form>
        </FormPanel>
    );
}

export default ResetPassword;
