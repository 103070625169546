import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import Center from '../../components/center';
import Layout from "../../components/content-area";
import Markdown from "../../components/markdown";
import LinkRenderer from "../../components/markdown/LinkRenderer";
import MediaContent from "../../components/media-hub/media-content";
import MediaList from "../../components/media-list";
import NewsContent from "../../components/news/news-content";
import SectionHeader from "../../components/section-header";
import SideBar from "../../components/side-bar";
import TopLogo from "../../components/top-logo";
import {API, GENERAL} from "../../utils/constants";
import {loadItem} from "../../store/media-hub/actions";
import {loadContents, loadNavigation} from "../../store/news/actions";
import {TrackingEvent, TrackingObject, TrackingPageName} from "../../store/tracking/TrackingObject";
import {trackEvent} from "../../store/tracking/actions";
import useCompany from "../../utils/hooks/use-company";
import { SearchForm } from "../../components/search-form";

const News = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        sectionId,
        itemId,
        mediaId
    } = useParams();
    const { pathname} = useLocation();
    const sharePath = GENERAL.LIVE_DEEPLINK + pathname.slice(1);

    const {
        items,
        initialSectionId,
        content,
        mediaContent,
        primaryColor,
        showNewsCoverPages,
        topLogo,
        newsBannerImage,
        cornerRadius,
        news_categories
    } = useSelector(state => {
        const {
            news: {
                items,
                initialSectionId,
                content
            },
            mediaHub: {
                item: mediaContent
            },
            company: {
                config: {
                    primaryColor,
                    newsBannerImage,
                    topLogo,
                    showNewsCoverPages,
                    cornerRadius,
                    news_categories
                },
            }
        } = state;
        return {
            items: news_categories,
            initialSectionId,
            content,
            mediaContent,
            primaryColor,
            showNewsCoverPages,
            topLogo,
            newsBannerImage,
            cornerRadius,
            // news_categories
        };
    });

    const {companySlug, companyId} = useCompany()
    const setPath = (selected) => {
        navigate(`/${companySlug}/news/${selected[0]}/${selected[1]}#content`);
    }

    // Load navigation
    useEffect(() => {
        dispatch(loadNavigation(companyId))
    }, [companyId]);

    useEffect(() => {
        if (items?.length > 0 && sectionId) {
            if (itemId) {
                if (!mediaId) {
                    dispatch(trackEvent(TrackingObject(TrackingEvent.PAGE_VIEW, {
                        pageName: TrackingPageName.NEWS,
                        mainCategory: items.find(item => item.id === sectionId)?.title,
                        subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
                    })));
                }
            } else {
                dispatch(trackEvent(TrackingObject(TrackingEvent.PAGE_VIEW, {
                    pageName: TrackingPageName.NEWS,
                    mainCategory: items.find(item => item.id === sectionId)?.title,
                })));
            }
        }
        if (itemId) {
            dispatch(loadContents([itemId]));
        }
    }, [items, sectionId, itemId, mediaId, dispatch])

    useEffect(() => {
        if (mediaId) {
            dispatch(loadItem(mediaId));
        }
    }, [mediaId, dispatch]);

    useEffect(() => {
        if (mediaContent) {
            dispatch(trackEvent(TrackingObject(TrackingEvent.CONTENT_VIEW, {
                pageName: TrackingPageName.NEWS,
                mainCategory: items.find(item => item.id === sectionId)?.title,
                subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
                contentTitle: mediaContent.title,
            })));
        }
    }, [mediaContent, dispatch, itemId, items, sectionId]);

    const trackDownloadPDF = ({title, url}) => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.PDF_DOWNLOAD, {
            pageName: TrackingPageName.NEWS,
            mainCategory: items.find(item => item.id === sectionId)?.title,
            subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
            contentTitle: title,
        })));
    }

    const trackVideoPlay = ({caption, url}) => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.VIDEO_PLAY, {
            pageName: TrackingPageName.NEWS,
            mainCategory: items.find(item => item.id === sectionId)?.title,
            subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
            videoCaption: caption,
            videoFileName: url,
        })));
    }

    const trackMediaHubVideoPlay = ({caption, url}) => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.VIDEO_PLAY, {
            pageName: TrackingPageName.NEWS,
            mainCategory: items.find(item => item.id === sectionId)?.title,
            subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
            contentTitle: mediaContent.title,
            videoCaption: caption,
            videoFileName: url,
        })));
    }

    const trackExternalLinkClick = ({title, url}) => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.EXTERNAL_LINK_CLICK, {
            pageName: TrackingPageName.NEWS,
            mainCategory: items.find(item => item.id === sectionId)?.title,
            subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
            externalContentTitle: title,
            externalContentUrl: url,
        })));
    }

    const trackMediaHubExternalLinkClick = ({title, url}) => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.EXTERNAL_LINK_CLICK, {
            pageName: TrackingPageName.NEWS,
            mainCategory: items.find(item => item.id === sectionId)?.title,
            subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
            contentTitle: mediaContent.title,
            externalContentTitle: title,
            externalContentUrl: url,
        })));
    }

    const trackShareEvent = (event, {title, path}) => {
        const events = [TrackingEvent.SHARE_COPY_LINK, TrackingEvent.SHARE_EMAIL, TrackingEvent.SHARE]
        if (events.includes(event)) {
            dispatch(trackEvent(TrackingObject(event, {
                pageName: TrackingPageName.NEWS,
                mainCategory: items.find(item => item.id === sectionId)?.title,
                subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
                contentTitle: mediaContent.title,
                externalContentTitle: title,
                externalContentUrl: path,
                path,
            })));
        }
    }

    let item = null;
    let itemContent = null;
    let firstDetailId = null;
    let children = null;
    if (items && (sectionId || initialSectionId)) {
        item = items.find(item => item.id === (sectionId || initialSectionId));
        if (item && item?.children?.length) {
            const {children} = item;
            firstDetailId = children[0].id;
        }
    }
    if (item) {
        itemContent = item.content;
        children = item.children;
    }

    return (
        <Center>
            <SectionHeader>
                <img src={`${API.URL}${newsBannerImage}`} alt="News Header"/>
                <TopLogo src={`${API.URL}${topLogo}`}/>
            </SectionHeader>
            <Layout hidden={!items} sideBar={
                <SideBar items={items}
                         pageName={TrackingPageName.NEWS}
                         primaryColor={primaryColor}
                         selected={[sectionId || initialSectionId, itemId || ""]}
                         setSelected={setPath}
                         initialChildSelected={false}
                />
            }>
                <SearchForm primaryColor={primaryColor} />
                {
                    !itemId ?
                        showNewsCoverPages ?
                            <MediaList items={children} primaryColor={primaryColor}
                                       borderRadius={cornerRadius}
                                       path={`/${companySlug}/news/${sectionId || initialSectionId}/`}/> :
                            <div>
                                <Markdown components={{a: LinkRenderer}}>{itemContent}</Markdown>
                                {
                                    firstDetailId ?
                                        <GoToDetails
                                            to={`/${companySlug}/news/${sectionId || initialSectionId}/${firstDetailId}/#content`}
                                            primaryColor={primaryColor}
                                        >
                                            EXPLORE MORE
                                        </GoToDetails>
                                        : null
                                }
                            </div>
                        
                        : content ? (
                            <>
                                <NewsContent content={content}
                                             borderRadius={cornerRadius}
                                             path={`/${companySlug}/news/${sectionId}/${itemId}/`}
                                             primaryColor={primaryColor}
                                             onDownloadPDF={trackDownloadPDF}
                                             onPlayVideo={trackVideoPlay}
                                             onExternalLinkClick={trackExternalLinkClick}
                                />
                            </>
                        ) : null
                }
            </Layout>
            {
                mediaId && mediaContent ?
                    <MediaContent
                        closePath={`/${companySlug}/news/${sectionId || initialSectionId}/${itemId}`}
                        primaryColor={primaryColor}
                        onPlayVideo={trackMediaHubVideoPlay}
                        onExternalLinkClick={trackMediaHubExternalLinkClick}
                        sharePath={sharePath}
                        onShareSuccess={trackShareEvent}
                        content={mediaContent}/>
                    : null
            }
        </Center>
    )
}

const GoToDetails = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  width: 200px;
  background-color: ${(props) => props.primaryColor || '#009b77'}; // Default color is provided if primaryColor is not available
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.8rem 0 0.5rem 0;
  margin: 2rem 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.primaryColor || '#009b77'}; // Default color is provided if primaryColor is not available
    color: white;
  }
`;


export default News;
