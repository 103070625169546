import {useEffect} from "react";
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Backdrop from "../backdrop";
import CloseButton from "../close-button";

const LightBox = ({children, closePath, padding, centered, primaryColor}) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
    }, []);

    return (
        <Backdrop centered={centered}>
            <Layout centered={centered}>
                <Header>
                    <CloseButton
                        to={closePath}
                        color={primaryColor}
                        preventScrollReset={true}>
                        <i className="fal fa-window-close"/>
                    </CloseButton>
                </Header>
                <Content padding={padding}>
                    {children}
                </Content>
            </Layout>
        </Backdrop>
    )
}

const Layout = styled.div`
  position: relative;
  width: 800px;
  max-height: calc(100vh - 6rem);
  ${breakpoint('xl')` // 24
    width: 1000px;
  `}
  //height: ${props => props.centered ? 'unset' : 'calc(100%)'};
  background-color: white;
  box-shadow: 0 0 25.55px 9.45px rgba(0,0,0,.29);
  //box-shadow: 0 0 1rem #00000030;
  //margin: 0 0 7rem 0;
  //margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  padding: .5rem 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${props => props.padding ? '0 5rem 3rem 5rem' : 'unset'};
  gap: ${props => props.padding ? '2rem' : 'unset'};
  overflow: scroll;
`;




export default LightBox;
