import { baseService } from "../service";
import qs from "qs";  

const learningJourneyQuery = (learningJourneyCategoryId) => {
  return qs.stringify({
    filter: {
      journey_category: {
        $eq: learningJourneyCategoryId,
      },
    },
    sort: "createdAt",
    order: "desc",
  });
};

export const getLearningJourneyList = async (learningJourneyCategoryId) => {
  const response = await baseService({
    url: "/journey",
    query: learningJourneyQuery(learningJourneyCategoryId),
  });
  return response.data;
};
