import {COMPANY} from "../../../utils/constants";

const setQueryParams = (source) => (
    {
        type: COMPANY.SET_QUERY_PARAMS,
        payload: {
            source,
        },
    }
)


const loadCompany = () => (
    {
        type: COMPANY.LOAD,
    }
)

const resetCompany = () => (
    {
        type: COMPANY.RESET,
    }
)

const selectCompany = (slug) => (
    {
        type: COMPANY.SELECT,
        payload: {
            slug
        },
    }
)

const setPlatformArea = (platformArea) => (
    {
        type: COMPANY.SET_PLATFORM_AREA,
        payload: {
            platformArea
        },
    }
)

export {setQueryParams, loadCompany, selectCompany, resetCompany, setPlatformArea};
