import {Link} from "react-router-dom";
import styled from "styled-components";

const CloseButton = ({to = null, color, onClick}) => {
    if (to != null) {
        return (
            <LinkButton to={to} color={color}>
                <i className="fal fa-window-close"/>
            </LinkButton>
        )
    }
    return (
        <ClickButton color={color} onClick={onClick}>
            <i className="fal fa-window-close"/>
        </ClickButton>
    )
}



const ClickButton = styled.button`
  color: #796e65;
  font-weight: 700;
  font-size: 2rem;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: ${props => props.color}
  }
`;

const LinkButton = styled(Link)`
  color: #796e65;
  font-weight: 700;
  font-size: 2rem;

  &:hover {
    color: ${props => props.color}
  }
`;

export default CloseButton;
