import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const Markdown = styled(ReactMarkdown)`
/*  h1 {
    font-family: AlternateGotNo2D, sans-serif;
    color: #449979;
    font-size: 2.1818rem;
    font-weight: normal;
    margin-bottom: 1.5rem;
  }*/

  h1 {
    color: #fd8f1c;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

   
/*  h2 {
    font-family: Roboto, sans-serif;
    color: #449979;
    font-size: 1.2273rem;
    font-weight: normal;
    margin: 2.5rem 0 1.5rem 0;
  }*/

  h2 {
    color: #796e65;
    font-size: 2rem;
    margin: 2.5rem 0 1.5rem 0;
  }

/*  h3 {
    font-family: Roboto, sans-serif;
    color: #000000;
    font-size: 1rem;
    font-weight: bold;
    white-space: pre-line;
    vertical-align: bottom;
    margin: 1.5rem 0;
  }*/

  h3 {
    color: #fd8f1c;
    font-weight: bold;
    font-size: 1.5rem;
    white-space: pre-line;
    vertical-align: bottom;
    margin: 1.5rem 0;
    text-transform: uppercase;
  }

  h4 {
    font-family: Roboto, sans-serif;
    color: #fd8f1c;
    font-size: 1rem;
    font-weight: bold;
    white-space: pre-line;
    vertical-align: bottom;
    margin: 1.5rem 0;
  }  

  p {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    //color: #000000;
    color: #796e65;
    line-height: 1.5;
    white-space: pre-line;
    vertical-align: bottom;
    margin-bottom: 1.5rem;

    strong {
      font-weight: bold;
    }

    del {
      background-color: #f8d65b;
      text-decoration: none;
      font-style: normal;
      //border-radius: 0.3125rem;
      padding: 0.125rem 0.25rem;
      line-height: 1.5;
    }
  }

  ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0 0 1.5rem 2.5rem;
    //color: black;
    color: #796e65;
  }
  
  ol {
    padding: 0 0 1.5rem 1rem;
  }

  ul li::before, ol li::before {
    content: "";
    display: inline-block;
    //background-color: #449979;
    background-color: #fd8f1c;
    width: 0.3125rem;
    min-width: 0.3125rem;
    max-width: 0.3125rem;
    height: 0.3125rem;
    margin-top: 0.5rem;
    margin-right: 0.625rem;
  }

  ol {
    counter-reset: step-counter;
  }

  ol li::before {
    counter-increment: step-counter;
    content: counter(step-counter) ".";
    background-color: transparent;
    width: auto;
    height: auto;
    margin-right: 0.3125rem;
    margin-top: 0.12rem;
    //color: #449979;
    color: #fd8f1c;
    font-size: 1rem;
    padding-right: 0.625rem;
    font-weight: bold;
  }

  ul li, ol li {
    display: flex;
    align-items: flex-start;

    p {
      margin: 0;
      line-height: 1.5;
    }
  }

  ul li::before, ol li p::before {
    margin-left: -1.25rem;
  }  
  

  /*    h1 {
        color: #FDB415;
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
      }
    
      h2 {
        color: #796e65;
        font-size: 2rem;
        margin: 2.5rem 0 1.5rem 0;
      }
    
      h3 {
        color: #FDB415;
        font-weight: bold;
        font-size: 1.5rem;
        white-space: pre-line;
        vertical-align: bottom;
        margin: 1.5rem 0;
        text-transform: uppercase;
      }
    
      strong {
        color: #796e65;
      }
    
      p {
        white-space: pre-line;
        vertical-align: bottom;
        margin-bottom: 1.5rem;
      }  
    
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0 0 1.5rem 2rem;
  
        li::before {
          content: "\\2022"; !* Add content: \\2022 is the CSS Code/unicode for a bullet *!
          color: #FDB415; !* Change the color *!
          font-weight: bold; !* If you want it to be bold *!
          display: inline-block; !* Needed to add space between the bullet and the text *!
          width: 1em; !* Also needed for space (tweak if needed) *!
          margin-left: -1em; !* Also needed for space (tweak if needed) *!
        }      
        li {
            p {
              margin: 0;
              display: inline;
            }
        }
      }*/
`;

export default Markdown;
