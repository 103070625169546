import {useState} from "react";
import {API} from "../../utils/constants";
import ImageContainer from "../../components/media-list/image-container";
import Image from "../../components/media-list/image";
import IconOverlayed from "../../components/icon-overlayed";
import { ResultItem } from "./result-item/ResultItem";
import { Grid } from "./SearchResult.style";
import { Text } from "./result-item/ResultItem.style";
import { get } from "lodash";
import NonClickable from "./result-item/NonClickableItem";


const getPath = (item, companyName) => {
    const { categories } = item;
    if (categories) {
        const { id, parent, media_hub } =  get(categories, '[0]');
        if (id) {
            if (parent?.media_hub) {
                const { media_hub } = parent;
                return `/${companyName}/mediahub/${media_hub?.id}/${parent.id}/${id}/`;
            } else {
                return `/${companyName}/mediahub/${media_hub?.id}/${id}/`;
            }
        }
        
    }
}

const ResultGrid = ({items, path, primaryColor, borderRadius, isSearch, companyName}) => {
    const [selected, setSelected] = useState(null);
    if(!items) return;
    return (
        <Grid>
            {
                items.map((item, i) => {
                    const {image: { thumbnail }, title, id, locked, categories, index} = item;
                    return (
                        !locked ?
                            <ResultItem key={id + i.toString()} item={
                                {
                                    id,
                                    title,
                                    thumbnail,
                                    path: !isSearch ? path : getPath(item, companyName),
                                    primaryColor,
                                    borderRadius,
                                    setSelected,
                                    selected,
                                    index,
                                    categories
                                }
                            } /> :
                            <NonClickable key={id}>
                                <ImageContainer selected={selected === id} borderRadius={`${borderRadius}px`}>
                                    <Image key={thumbnail} src={`${API.URL}${thumbnail}`} dimmed />
                                    <IconOverlayed className="far fa-lock" color={primaryColor} />
                                </ImageContainer>
                                <Text>{title}</Text>
                            </NonClickable>
                    )
                })
            }
        </Grid>
    )
}

export default ResultGrid;
