import styled from "styled-components";
export const SearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 1rem;
  padding-top: 4rem;
  flex: 1;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  flex-wrap: wrap;
  gap: 15px 15px;
  margin: 0 -1rem;
`;