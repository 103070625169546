import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import Form from "../../components/form";
import FormAnnotation from "../../components/form-annotation";
import FormButton from "../../components/gls/form/FormButton";
import FormErrorMessage from "../../components/form-error-message";
import FormInput from "../../components/gls/form/FormInput";
import FormInputGroup from "../../components/gls/form/FormInputGroup";
import FormPanel from "../../container/form-panel";
import FormNotice from "../../components/form-notice/FormNotice";
import {recoverPassword, resetRecoverPassword} from "../../store/user/actions";
import {passwordRecoverySchema} from "../../utils/form-schemata/FormSchemata";
import { trackEvent } from "../../store/tracking/actions";
import { TrackingEvent, TrackingObject, TrackingPageName } from "../../store/tracking/TrackingObject";


const PasswordRecovery = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {success, error, loading} = useSelector(state => {
        const {user: {recovery: {success, error, loading}}} = state;
        return {success, error, loading};
    });

    useEffect(() => {
        dispatch(resetRecoverPassword);
        dispatch(trackEvent(TrackingObject(TrackingEvent.PAGE_VIEW, {
            pageName: TrackingPageName.PASSWORD_RESET,
          })))
    }, [dispatch]);

    const {
        register,
        handleSubmit,
        setError,
        formState: {
            errors
        }
    } = useForm({
        resolver: yupResolver(passwordRecoverySchema)
    });

    const annotationText = "";

    const onSubmit = (data) => {
        dispatch(recoverPassword(data));
    }

    const handleCancelClick = () => {
        navigate('..');
    }

    if (success) {
        return (
            <FormPanel>
                <Form>
                    <FormNotice>
                        <strong>HELP IS ON THE WAY</strong><br />
                        Please check your email inbox to recover your password.<br />
                        If the email hasn't arrived, please check your spam folder too.
                    </FormNotice>
                    <FormButton onClick={handleCancelClick} title="GO BACK TO LOGIN" type="reset" primary/>
                </Form>
            </FormPanel>
        )
    }

    return (
        <FormPanel>
            <FormWrapper>
            <FormNotice>
                <strong>RESET YOUR PASSWORD</strong><br />
                Please enter your email address and we’ll send you instructions on how to reset your password:
            </FormNotice>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormInputGroup>
                    <FormInput type="text"
                                    {...register("email", {required: true})}
                                    placeholder="Email*"
                                    error={errors.email}/>
                    <FormErrorMessage message={errors.email}/>
                    <FormErrorMessage message={error}/>
                    <FormAnnotation>* required field</FormAnnotation>
                </FormInputGroup>
                <FormButton loading={loading} type="submit" title="REQUEST NEW PASSWORD" primary/>
                <FormButton onClick={handleCancelClick} title="CANCEL" type="reset" />
            </Form>
            </FormWrapper>
        </FormPanel>
    )
}

export default PasswordRecovery;

const FormWrapper = styled.div`
  max-width: 35rem;
`;