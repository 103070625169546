import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const StyledLink = styled(({overColor, color, ...props}) => (
    <Link {...props} />
))`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  flex-direction: row;
  height: 30px;
  color: ${props => props.color ? props.color : '#796e65'};

  &:hover {
    color: ${props => props.overColor};
  }
  ${breakpoint('xs')` // 24
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  `}*/
`;

const Icon = styled.i`
  padding-right: 0.25rem;
`;


const BackButton = ({to, className, overColor, color, onButtonLoad, ...props}) => {

    useEffect(() => {
        // Measure the button's height and call the onButtonLoad callback
        const measureButtonHeight = () => {
            const button = document.getElementById("back-button");
            if (button) {
                return button.clientHeight;
            }
        };

        const buttonHeight = measureButtonHeight();
        if (onButtonLoad && buttonHeight) {
            onButtonLoad(buttonHeight);
        }

    }, []);

    return (
        <StyledLink id="back-button" to=".." overColor={overColor} color={color} {...props}>
            <Icon className="far fa-chevron-left"/><span>Back</span>
        </StyledLink>
    );
}

export default BackButton;
