import styled from "styled-components";

const Image = styled.div`
  background: url('${props => props.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: ${props => props.dimmed ? 0.25 : 1};
  width: 100%;
  height: 100%;
  transition: 0.25s ease-out;
`;

export default Image;
