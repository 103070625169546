import { forwardRef } from "react";
import styled from "styled-components";

export const FormCheckboxGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.layout === "horizontal" ? "row" : "column")};
  padding: 0.4rem 0.3rem;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

export const FormCheckbox = forwardRef(({ label, value, labelStyle, ...props }, ref) => {
  // Determine the type of input to render
  return (
    <InputContainer>
        <label key={value} for={value} style={labelStyle}>
          <input type="checkbox" value={value} ref={ref} {...props} />
          {label}
        </label>
    </InputContainer>
  );
});
