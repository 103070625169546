import { forwardRef } from "react";
import { useAreaSubcategory } from "../../utils/hooks/use-therapeutic/useTherapeuticArea";
import { FormCheckbox, FormCheckboxGroup } from "../gls/form/FormCheckbox";
import { SquareLoader } from "react-spinners";
import styled from "styled-components";

export const AreaSubcategoryFormGroup = forwardRef(
  ({ therapeuticId, layout, containerProps, ...props }, ref) => {
    const { data, isLoading, isError } = useAreaSubcategory(therapeuticId);

    if (isLoading) {
      return <div style={{margin:'5px'}}><SquareLoader loading={true} size={18} color="#796e65" speedMultiplier={2}/></div>;
    }

    if (isError) {
      return <FormLabel>Error fetching the area sub-categories.</FormLabel>;
    }

    if (data.length === 0) {
      return <FormLabel>No sub-categories available for the selected area.</FormLabel>
    }

    return (
      <FormCheckboxGroup layout={layout} {...containerProps} >
        {data.map((areaSubcategory) => (
          <FormCheckbox
            labelStyle={{color: '#727272'}}
            ref={ref}
            key={areaSubcategory.id}
            value={areaSubcategory.id}
            label={areaSubcategory.name}
            {...props}
          />
        ))}
      </FormCheckboxGroup>
    );
  }
);

const FormLabel = styled.p`
  color: #727272;
  text-align: center;
  width: 100%;
`;
