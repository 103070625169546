import axios from "axios";
import { API } from "../utils/constants";
import { transformData } from "../utils/mappings/therapeutic.util";

export const getAreaSubcategory = async (therapeuticAreaId) => {
    try {
      const response = await axios.get(`${API.URL}${API.AREA_SUBCATEGORY_PATH(therapeuticAreaId, false)}`);
      const res =  transformData(response.data);
      return res;
    } catch (error) {
      throw new Error("Failed to fetch app config");
    }
  };
  