import { useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { getAppConfig } from "../../services/app.service";

// Create the app config context
const AppConfigContext = createContext();

// Custom hook to access the app config context
export function useAppConfig() {
  return useContext(AppConfigContext);
}

// App config provider component
export function AppConfigProvider({ children }) {
  // Fetch the config data using React Query
  const { data: config, isLoading, isError, error } = useQuery({
    
    queryKey: ["appConfig"],
    queryFn: () => getAppConfig(),
    initialData: {
        auth_panel_header: [],
        auth_panel_footer: [],
        sidebar_footer: [],
        news_menu_label: 'IMMpulse',
        event_section_visibility: true,
        footer_text: [],
        accentColor: '#f64f64',
    },
    keepPreviousData: true,
    
  });

  // Render the app config provider with the fetched data
  return (
    <AppConfigContext.Provider value={{ config, isLoading, isError, error }}>
      {children}
    </AppConfigContext.Provider>
  );
}
