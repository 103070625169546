import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Form from "../../components/form";
import FormButton from "../../components/form-button";
import FormPanel from "../../container/form-panel";
import FormNotice from "../../components/form-notice/FormNotice";
import { useEffect } from "react";
import { TrackingEvent, TrackingObject, TrackingPageName } from "../../store/tracking/TrackingObject";
import { trackEvent } from "../../store/tracking/actions";

const EmailConfirmation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const error = searchParams.get('error');
    const isInvalidToken = error === 'invalid-token';

    const handleCancelClick = () => {
        navigate('..');
    }

    useEffect(() => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.PAGE_VIEW, {
            pageName: TrackingPageName.REGISTRATION_CONFIRMATION,
            platformArea: "system"
        })));
    }, [ dispatch])

    return (
        <FormPanel>
            <Form>
                <FormNotice>
                    {isInvalidToken ? (
                        <span>
                            The link is invalid or the email has already been verified.
                        </span>
                    ) : (
                        <span>
                            <strong>THANK YOU</strong><br />
                            We will contact you as soon as your account has been approved!
                        </span>
                    )}
                </FormNotice>
                <FormButton onClick={handleCancelClick} value="GO BACK TO LOGIN" type="reset" primary/>
            </Form>
        </FormPanel>
    );
}

export default EmailConfirmation;
