import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import BackButton from "../../components/back-button";
import Center from "../../components/center";
import CoverImage from "../../components/cover-image";
import TopLogo from "../../components/top-logo";
import {API,} from "../../utils/constants";
import {loadEventsPage} from "../../store/events/actions";
import {TrackingEvent, TrackingObject, TrackingPageName} from "../../store/tracking/TrackingObject";
import {trackEvent} from "../../store/tracking/actions";
import Button from "../../components/button";
import useCompanySelected from "../../utils/hooks/use-company";
import MediaPlayer from "../../components/media-hub/media-player";
import { getMediaId } from "../../utils/media/media";


const Events = () => {

    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const wrapperPadding = 1 * rem;

    const backButtonRef = useRef();
    const containerRef = useRef();
    const h1Ref = useRef(null);
    const h2Ref = useRef(null);
    const buttonWrapperRef = useRef();


    const [computedWindowHeight, setComputedWindowHeight] = useState(window.innerHeight);
    const [computedWindowWidth, setComputedWindowWidth] = useState(window.innerWidth);

    const [containerHeight, setContainerHeight] = useState(0);
    const [h1Height, setH1Height] = useState(0);
    const [h2Height, setH2Height] = useState(0);
    const [buttonWrapperHeight, setButtonWrapperHeight] = useState(0);
    const [backButtonHeight, setBackButtonHeight] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [topLogoWidth, setTopLogoWidth] = useState(0); // Initialize with 0

    // Handle the TopLogo image load event
    const handleTopLogoImageLoad = (height) => {
        setTopLogoWidth(height);
    };

    const handleBackButtonLoad = (height) => {
        setBackButtonHeight(height)
    }


    // Use useLayoutEffect to measure the container's height and update containerHeight
    useLayoutEffect(() => {
        const computeImageHeight = () => {
            let height = computedWindowHeight - 10.4 * rem - 2 * wrapperPadding - backButtonHeight - h1Height - h2Height - buttonWrapperHeight - 1 * rem

            return height;
        }

        const computedContainerHeight = containerRef?.current?.clientHeight; // Measure the container's height
        if (computedContainerHeight) {
            setContainerHeight(computedContainerHeight);
        }
        if (h1Ref.current) {
            setH1Height(h1Ref.current.clientHeight);
        }
        if (h2Ref.current) {
            setH2Height(h2Ref.current.clientHeight);
        }
        const computedButtonWrapperHeight = buttonWrapperRef?.current?.clientHeight;
        if (computedButtonWrapperHeight) {
            setButtonWrapperHeight(computedButtonWrapperHeight);
        }
        const computedBackButtonHeight = backButtonRef?.current?.clientHeight;
        if (computedBackButtonHeight) {
            setBackButtonHeight(computedBackButtonHeight);
        }

        setImageHeight(computeImageHeight())

    }, [computedWindowHeight, containerHeight, h1Height, h2Height, buttonWrapperHeight, backButtonHeight, wrapperPadding, topLogoWidth]);

    useEffect(() => {
        // Function to update the windowHeight state
        const updateWindowHeight = () => {
            setComputedWindowHeight(window.innerHeight);
        };

        // Function to update the windowWidth state
        const updateWindowWidth = () => {
            setComputedWindowWidth(window.innerWidth);
        };

        updateWindowHeight();

        // Add event listener for window resize
        window.addEventListener("resize", updateWindowHeight);
        window.addEventListener("resize", updateWindowWidth);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", updateWindowHeight);
            window.removeEventListener("resize", updateWindowWidth);
        };
    }, []);


    const dispatch = useDispatch();

    const {
        companyName,
        primaryColor,
        topLogo,
        title,
        subtitle,
        image,
        links,
        backgroundImage,
        backButtonColor,
        id
    } = useSelector(state => {
        const {
            company: {
                config: {
                    title: companyName,
                    primaryColor,
                    topLogo,
                },
            },
            events: {
                title,
                subtitle,
                image,
                links,
                backgroundImage,
                backButtonColor,
                id
            }
        } = state;
        return {
            primaryColor,
            topLogo,
            companyName,
            title,
            subtitle,
            image,
            links,
            backgroundImage,
            backButtonColor,
            id
        };
    });
    
    const threeQ = links?.find(item => item?.__component === "elements.3-q")
    const {isCompanyLoaded} = useCompanySelected();

    useEffect(() => {
            dispatch(trackEvent(TrackingObject(TrackingEvent.PAGE_VIEW, {
                pageName: TrackingPageName.EVENTS,
            })))
            dispatch(loadEventsPage());
    }, []);

    const defaultColor = (companyName === 'dupixent-rge' ? 'white' : primaryColor);

    const color = backButtonColor ? backButtonColor : defaultColor;
    const overColor = (companyName === 'dupixent-rge' ? primaryColor : 'white');

    let displayTitle;
    let displaySubtitle;

    if (id) {
        displayTitle = title;
        displaySubtitle = subtitle
    } else {
        displayTitle = companyName === 'dupixent-rge' ?
            'Our next event is coming soon' :
            'Our next event is coming soon';
        displaySubtitle = companyName === 'dupixent-rge' ?
            'Stay tuned for more details' :
            'Stay tuned for more details';
    }

    const renderContent = useCallback(() => {
        if (threeQ) {
            return <MediaPlayer mediaId={getMediaId(threeQ?.url)} />
        }
        if (image) {
            return  <StyledImg style={{maxHeight: imageHeight,}} src={`${API.URL}${image?.url}`}/>
        }
    }, [threeQ, image])


    return (
        <Container ref={containerRef}>
            <Background
                src={backgroundImage ? `${API.URL}${backgroundImage.url}` : `/images/events-${companyName}.jpg`}/>
            <TopLogo onImageLoad={handleTopLogoImageLoad} src={`${API.URL}${topLogo}`}/>
            <Inner color={color}>
                <Wrapper padding={wrapperPadding} hasImage={!!image} hasTitles={h1Height > 0 || h2Height > 0}
                         maxWidthXL={computedWindowWidth - 2 * topLogoWidth}>
                    <BackButton style={{paddingBottom: '1rem'}}
                                ref={backButtonRef}
                                onButtonLoad={handleBackButtonLoad}
                                overColor={overColor}
                                color={color}/>
                    {displayTitle && <h1 ref={h1Ref}>{displayTitle}</h1>}
                    {displaySubtitle && <h2 ref={h2Ref} style={{paddingBottom: '1rem'}}>{displaySubtitle}</h2>}
                    {renderContent()}
                    <ButtonWrapper ref={buttonWrapperRef}>
                        {links && links.map((link, index) => {
                            if (link.__component !== "elements.3-q") {
                                return (
                                    <Button
                                        key={index}
                                        expand
                                        style={{marginLeft: index > 0 ? "1rem" : "0"}}
                                        to={link.url}
                                        color={link.text_color}
                                        backgroundColor={link.background_color}
                                        overBackgroundColor={link.background_over_color}
                                        overColor={link.text_over_color}
                                        disabled={link.locked}
                                    >
                                        {link.title}
                                    </Button>
                                );
                            }
                            return null;
                        })}
                    </ButtonWrapper>
                </Wrapper>
            </Inner>
        </Container>
    )
}

const Container = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const Background = styled(CoverImage)`
  height: calc(100vh - 10.4rem);
  z-index: -1;

`;

const StyledImg = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;
  object-position: center;
`;

const Inner = styled(Center)`
  height: calc(100vh - 10.4rem);
  justify-content: flex-end;

  h1, h2 {
    color: ${props => props.color};
    text-transform: none;
    padding: 0;
    margin: 0;
  }

  h1 {
    text-transform: uppercase;
    line-height: 3.5rem;
  }

  h2 {
    font-weight: normal;
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

const Wrapper = styled.div`
  padding: ${props => props.wrapperPadding || '1rem'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${breakpoint('xl')`
    max-width: ${props => props.maxWidthXL}px;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


export default Events;
