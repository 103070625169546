import { useRef } from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { API } from "../../utils/constants";
import { setVisible } from "../../store/whatsnew/actions";
import useOnClickOutside from "../../utils/hooks/use-on-click-outside";
import Backdrop from "../backdrop";
import CloseButton from "../close-button";

const WhatsNew = ({ data, primaryColor }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { active, visible, title, media, textColor, open_in_new_tab, link_url } = data;
  const hide = () => {
    dispatch(setVisible(false));
  };

  const show = () => {
    dispatch(setVisible(true));
  };
  useOnClickOutside(ref, () => hide());

  if (!active) {
    return null;
  }

  if (!visible) {
    return (
      <OpenWhatsNew color={primaryColor} onClick={show}>
        <p style={{ color: textColor ? textColor : primaryColor }}>
          <i className="fal fa-info-square" style={{ marginRight: '7px' }} />
          {title}
        </p>
      </OpenWhatsNew>
    );
  }

  return (
    <Backdrop centered>
      <Window ref={ref}>
        <Header>
          <p>{title}</p>
          <CloseButton onClick={hide} color={primaryColor} preventScrollReset={true}>
            <i className="fal fa-window-close" />
          </CloseButton>
        </Header>
        <WhatNewMedia
          media={media}
          title={title}
          metadata={{ open_in_new_tab, link_url }}
          onClicked={hide}
        />
      </Window>
    </Backdrop>
  );
};

const WhatNewMedia = ({ media, title, metadata, onClicked }) => {
  const { open_in_new_tab, link_url } = metadata;
  const onClick = () => {
    if (link_url && link_url.trim()) {
      if (open_in_new_tab) {
        window.open(link_url, '_blank');
      } else {
        window.location.assign(link_url);
      }
      onClicked();
    }
  };
  const { type, cover, image, video, url } = media;
  switch (type) {
    case 'video':
      return (
        <Video
          src={`${API.URL}${video}`}
          controls
          preload="none"
          poster={`${API.URL}${cover}`}
        />
      );
    case 'image':
      return (
        <StyledImage onClick={onClick} src={`${API.URL}${image}`} alt={title} clickable={!!(link_url && link_url.trim())} />
      );
    case '3-q':
      return (
        <StyledIframe
          src={url}
          frameborder="0"
          scrolling="no"
          allow="autoplay; picture-in-picture; encrypted-media; fullscreen"
          allowfullscreen
        />
      );
    default:
      return null;
  }
};

const StyledIframe = styled.iframe`
  border: none;
  width: 100%;
  min-height: 450px;
  height: 100%;
`;

const Window = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  ${breakpoint('xs')`
    width: 90vw;
  `}
  ${breakpoint('md')`
    width: 60vw;
  `}
  ${breakpoint('lg')`
    width: 800px;
  `}
  background-color: white;
  box-shadow: 0 0 1rem #00000030;
  margin: 1em;
`;

const Header = styled.div`
  padding: .2rem 1rem 0.2rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #796e65;
`;

const OpenWhatsNew = styled.div`
  position: absolute;
  top: 3rem;
  right: 0;
  color: white;
  margin: 3rem 1rem;
  padding: 0.3em 0.7em;
  cursor: pointer;
  background-color: transparent;
  z-index: 2;
  p {
    font-size: 16px;
    color: white;
  }
  &:hover {
    background-color: hsla(0,0%,100%,.4);
    p {
      color: ${props => props.color}
    }
  }
`;

const Video = styled.video`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const StyledImage = styled.img`
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export default WhatsNew;
