import { STICKER } from "../../../utils/constants";

const STATE = {};

const mapStickerData = (payload) => {
  if (payload?.data?.length > 0) {
    const { hyperlink, publishedAt, id, sticker } = [...payload?.data].shift();
    return { id, hyperlink, publishedAt, sticker };
  }
  return {};
};

const sticker = (state = STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case STICKER.LOAD:
      return {};
    case STICKER.LOAD_ERROR:
      return {};
    case STICKER.LOAD_SUCCESS:
      return { ...mapStickerData(payload) };
    default:
      return state;
  }
};

export default sticker;
