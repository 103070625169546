import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Header from "../../components/header";
import HeaderButton, {
  HeaderButtonEmpty,
} from "../../components/header/header-button";
import Sticker from "../../components/sticker";
import TopLogo from "../../components/top-logo";
import WhatsNew from "../../components/whats-new";
import { API } from "../../utils/constants";
import { loadSticker } from "../../store/sticker/actions";
import { loadWhatsNew, setVisible } from "../../store/whatsnew/actions";
import { trackEvent } from "../../store/tracking/actions";
import {
  TrackingEvent,
  TrackingObject,
  TrackingPageName,
} from "../../store/tracking/TrackingObject";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppConfig } from "../../contexts/app-config/app-config-context";

const CompanyLanding = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);

  const params = useParams();
  const { companyName } = params;

  const [query] = useSearchParams(); // Use useSearchParams
  const popup = query.get("popup");

  const {
    config: {
      slug: companySlug,
      mediaHubHeroImage,
      newsHeroImage,
      networkingHeroImage,
      topLogo,
      primaryColor,
      event_section_visibility,
      journey_section_visibility,
      journey_containers
    },
    mediaHubs,
    whatsNew,
    sticker,
  } = useSelector((state) => {
    const {
      company: { config, mediaHubs },
      sticker,
      whatsNew,
    } = state;
    return { config, mediaHubs, whatsNew, sticker };
  });

  useEffect(() => {
    dispatch(loadSticker());
    dispatch(loadWhatsNew());
    dispatch(
      trackEvent(
        TrackingObject(TrackingEvent.PAGE_VIEW, {
          pageName: TrackingPageName.LANDING_PAGE,
        })
      )
    );
  }, []);

  useEffect(() => {
    // Only set visible to false if the 'popup' parameter exists and is not an empty string
    if (popup && popup.trim() !== "") {
      dispatch(setVisible(false));
    }
  }, [dispatch, popup]);

  const { config } = useAppConfig();

  const images = [
    `${API.URL}${mediaHubHeroImage}`,
    `${API.URL}${newsHeroImage}`,
    `${API.URL}${networkingHeroImage}`,
  ];

  return (
    <Container>
      <Header images={images} selected={selected}>
        <Sticker sticker={sticker} />
        <HeaderButton
          companyName={companySlug}
          mediaHubs={mediaHubs}
          color={primaryColor}
          onMouseOver={() => setSelected(0)}
          isSelected={selected === 0}
        >
          Media Hub
        </HeaderButton>
        <HeaderButton
          to={`/${companySlug}/news`}
          color={primaryColor}
          onMouseOver={() => setSelected(1)}
          isSelected={selected === 1}
        >
          {config?.news_menu_label}
        </HeaderButton>
        {journey_section_visibility ? (
          <HeaderButton
            companyName={companySlug}
            journeyContainers={journey_containers}
            color={primaryColor}
            onMouseOver={() => setSelected(2)}
            isSelected={selected === 2}
          >
            Learning Journey
          </HeaderButton>
        ) : event_section_visibility ? (
          <HeaderButton
            to={`/${companySlug}/events`}
            color={primaryColor}
            onMouseOver={() => setSelected(2)}
            isSelected={selected === 2}
          >
            Events
          </HeaderButton>
        ) : (
          <HeaderButtonEmpty />
        )}
      </Header>
      <TopLogo src={`${API.URL}${topLogo}`} marginTop="3rem" />
      <WhatsNew data={whatsNew} primaryColor={primaryColor} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

export default CompanyLanding;
