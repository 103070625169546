import styled from 'styled-components';

const SectionHeader = styled.div`
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 3rem;
  margin-top: 3rem;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export default SectionHeader;
