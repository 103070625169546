import { Link, useRouteError } from "react-router-dom";
import Center from "./components/center";
import styled from "styled-components";
import GenericBackground from "./components/generic-background/GenericBackground";

const ErrorPageWraper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const ButtonHome = styled(Link)`
  padding: 10px 20px;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 24px
`;

export default function ErrorPage() {
  return (
    <ErrorPageWraper>
      <GenericBackground>
      <h1>Page Not Found</h1>
      <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
      <ButtonHome className="btn" to="/">Return to Homepage</ButtonHome>
      </GenericBackground>
    </ErrorPageWraper>
  );
}
