import styled from "styled-components";
import GenericBackground from "../../components/generic-background";
import { useAppConfig } from "../../contexts/app-config/app-config-context";
import breakpoint from "styled-components-breakpoint";

const FormPanel = ({children}) => {
  const { config } = useAppConfig();
  return (
    <GenericBackground>
        <Logo src={config?.main_logo} alt="logo" style={{"marginBottom": "1rem"}}/>
        <FormInnerPanel>
            {children}
        </FormInnerPanel>
    </GenericBackground>
  )
}
    

const Logo = styled.img`
  display: flex;
  width: 100%;
  max-width: 18rem;
  margin: 2rem auto;
`;

const FormInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${breakpoint('md')`
    padding: 3rem;
    min-width: 40vw;
  `}
  ${breakpoint('xs')`
    padding: 2rem;
    min-width: 100vw;
  `}
`;



export default FormPanel;
