import HeroSection from "./hero-section";
import Section from "./section";
import Video from "./video";
import FlipCard from "./flip-card";
import Carousel from "./caroursel";
import KeyPiorities from "./key-piorities";
import News from "./news";
import Paragraph from "./paragraph";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useLearningJourney } from "./services/learning-journey.service";
import { getMediaPath } from "../../utils/media/media";
import { FlashCards } from "./flash-cards";

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const LearningJourney = () => {
  const [activeSection, setActiveSection] = useState("0");

  const containerRef = useRef(null); // Add this ref for the scroll container
  const { learningJourneySlug } = useParams();

  const { data, dataUpdatedAt } = useLearningJourney(learningJourneySlug);

  const scrollToSection = (index) => {
    // setActiveSection(index);

    const element = document.getElementById(index);

    if (element && containerRef?.current) {
      console.log(
        "🚀 ~ scrollToSection ~ containerRef?.current:",
        element?.offsetTop
      );
      containerRef?.current?.scrollTo({
        top: element?.offsetTop,
        behavior: "smooth",
      });
    }
  };

  // useEffect(() => {
  //   const sections = data?.sections;
  //   if (sections?.length > 0) {
  //     setActiveSection("0");
  //   }
  // }, [dataUpdatedAt, data]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const sections = data?.sections;
      const sectionElements = sections?.map((section, index) =>
        document.getElementById(index)
      );

      // Get the scroll container's viewport
      const containerTop = container.scrollTop;
      const containerMiddle = containerTop + container.clientHeight / 2;

      // Find which section is closest to the middle of the container viewport
      let closestSection = null;
      let closestDistance = Infinity;

      sectionElements?.forEach((element, index) => {
        if (element) {
          const sectionTop = element.offsetTop;
          const sectionMiddle = sectionTop + element.offsetHeight / 2;
          const distance = Math.abs(sectionMiddle - containerMiddle);

          if (distance < closestDistance) {
            closestDistance = distance;
            closestSection = index;
          }
        }
      });

      if (closestSection && closestSection !== activeSection) {
        console.log("🚀 ~ handleScroll ~ closestSection:", closestSection);
        setActiveSection(closestSection);
      }
    };

    container.addEventListener("scroll", handleScroll);

    // Initial check
    handleScroll();

    return () => container.removeEventListener("scroll", handleScroll);
  }, [activeSection]);

  const SectionItem = ({ section, id }) => {
    switch (section.__component) {
      case "elements.title-section":
        return (
          <HeroSection
            key={id}
            id={id}
            title={section.title}
            subHeading={section.sub_title}
            backgroundImage={getMediaPath(section?.background, "large")}
            className="snap-start h-screen"
          />
        );
      case "elements.video-embed":
        return (
          <Section
            key={id}
            id={id}
            className="bg-white flex items-center h-screen justify-center w-full snap-start"
          >
            <Video
              className={"md:max-w-screen-xl"}
              videoSrc={section?.videoLink}
            />
          </Section>
        );
      case "elements.paragraph":
        return (
          <Section
            key={id}
            id={id}
            className="bg-white flex items-center justify-center h-screen w-full snap-start"
          >
            <div className="flex flex-col gap-4 max-w-[600px]">
              <Paragraph className="text-black text-[22px] text-center">
                {section?.content[0]?.children[0]?.text}
              </Paragraph>
            </div>
          </Section>
        );
      case "elements.carousel":
        return (
          <Section
            id={id}
            key={id}
            className="bg-white flex items-center justify-center h-screen w-full snap-start"
          >
            <Carousel items={section?.images} />
          </Section>
        );
      case "elements.key-priorities":
        return (
          <Section
            key={id}
            id={id}
            className="bg-white flex items-center justify-center h-screen w-full snap-start"
          >
            <KeyPiorities
              className={" p-6"}
              title={section?.title}
              description={section?.sub_title}
              items={section?.key_priority_item}
            />
          </Section>
        );
      case "elements.flash-cards":
        return (
          <Section
            key={id}
            id={id}
            className="bg-white items-center justify-center flex flex-col min-h-screen p-4 snap-start"
          >
            <FlashCards
              title={section?.title}
              sub_title={section?.sub_title}
              items={section?.flashCardItems}
            />
          </Section>
        );
      case "elements.news":
        return (
          <Section
            key={id}
            id={id}
            className="bg-white flex items-center justify-center h-screen w-full snap-start"
          >
            <News
              items={section?.news_items}
              title={section?.title}
              sub_title={section?.sub_title}
            />
          </Section>
        );
    }
  };

  const renderSections = () => {
    return data?.sections?.map((section, index) => (
      <SectionItem key={index} id={index} section={section} />
    ));
  };

  return (
    <div
      ref={containerRef}
      className="mt-10 snap-y snap-mandatory h-screen w-screen mx:auto overflow-scroll scroll-smooth"
    >
      <nav className="fixed right-10 top-1/2 transform -translate-y-1/2 z-50  hidden md:block">
        <ul className="flex flex-col gap-2 justify-center items-center">
          <li className="cursor-pointer mb-3 justify-center items-center flex">
            <button
              onClick={() => scrollToSection(Number(activeSection) - 1)}
              disabled={activeSection <= 0}
              className={` rounded-full bg-black bg-opacity-50 p-1 ${
                activeSection <= 0
                  ? "hidden"
                  : ""
              }`}
              title="Previous section"
            >
              <IoIosArrowUp size={20} color="white" />
            </button>
          </li>

          {data?.sections?.map((section, index) => (
            <li key={index} className="cursor-pointer" data-id={index}>
              <button
                onClick={() => scrollToSection(index)}
                className={`w-3 h-3 rounded-full ${
                  activeSection?.toString() === index?.toString()
                    ? "bg-blue-500 w-4 h-4"
                    : "bg-gray-300 hover:bg-gray-400"
                }`}
                title={section?.label}
              />
            </li>
          ))}
          <li className="cursor-pointer mt-3">
            <button
              onClick={() => scrollToSection(Number(activeSection) + 1)}
              disabled={activeSection >= data?.sections?.length - 1}
              className={`rounded-full bg-black bg-opacity-50 p-1 ${
                activeSection >= data?.sections?.length - 1
                  ? "hidden"
                  : ""
              }`}
              title="Next section"
            >
              <IoIosArrowDown size={20} color="white" />
            </button>
          </li>
        </ul>
      </nav>
      {renderSections()}
    </div>
  );
};

export default LearningJourney;
