import { Link } from "react-router-dom";
import styled from "styled-components";

const LobbyButton = ({ slug, image }) => (
  <LobbyButtonWrapper >
    <Link to={slug}><LobbyButtonImage src={image} /></Link>
  </LobbyButtonWrapper>
);

export default LobbyButton;

const LobbyButtonWrapper = styled.div`
  flex: 1;
`;

const LobbyButtonImage = styled.img`
  box-shadow: 4px 3px 10.4px 2.6px rgba(0, 0, 0, 0.2);
  border: 2px solid white;
  width: 100%;
  height: auto;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  z-index: 0;
  &:hover {
    z-index: 1;
    box-shadow: 4px 3px 20.4px 2.6px rgba(0, 0, 0, 0.4);
    scale: 1.03;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;
