import React, { useState } from "react";
import "./flip-card.css";
import { PiArrowUDownRight } from "react-icons/pi";

const FlipCard = ({
  title,
  icon = <PiArrowUDownRight size={30} />,
  description,
  bgColor = "bg-blue-500",
  bgColorBack = "bg-[#FEFFF9]",
  textColor = "#ffffff",
  textColorBack = "#000000",
  children,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      className={`flip-card block h-[300px] cursor-pointer ${isFlipped ? "flipped" : ""}`}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="flip-card-inner">
        <div className={`flip-card-front p-3 ${bgColor}`}>
          <div className=" text-left p-3 ">
            <h3 className={`text-2xl title ${textColor}`}>{title}</h3>
            <div className={`absolute right-3 bottom-3 ${textColor}`}>{icon}</div>
          </div>
        </div>
        <div className={`flip-card-back ${bgColorBack}`}>
          <p className={`p-3 text-left items-start ${textColorBack}`}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
