import axios from "axios";
import {call, put, select, takeEvery} from 'redux-saga/effects';
import {API, GENERAL, STICKER} from "../../../utils/constants";
import {startLoading, stopLoading} from "../../loading/actions";
import {logout} from "../../user/actions";

axios.defaults.headers.common['accept'] = `application/json`;


function* loadSticker() {
    try {
        yield put(startLoading());
        const { config: {id}} = yield select(state => state.company);
        const {jwt} = yield select(state => state.user);
        const header = {headers: {Authorization: `Bearer ${jwt}`}};
        const sticker = yield call(axios, `${API.URL}${API.STICKER_ITEM_PATH(id, GENERAL.IS_PREVIEW)}`, header);
        const {data} = sticker;
        yield put({type: STICKER.LOAD_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        const {response: {status}} = e;
        // Bearer failed to authenticate
        if(status === 403) {
            yield put(logout());
        }
        yield put({type: STICKER.LOAD_ERROR});
        yield put(stopLoading(e));
    }
}


function* initWatchers() {
    yield takeEvery(STICKER.LOAD, loadSticker);
}

export default initWatchers;
