import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useNavigate, useSearchParams, useLocation, useParams, useRouteError} from "react-router-dom";
import {SquareLoader} from "react-spinners";
import styled from "styled-components";
import Center from "../../components/center";
import GenericBackground from "../../components/generic-background";
import NavBar from "../../components/navbar";
import {TrackingEvent, TrackingObject, TrackingPageName} from "../../store/tracking/TrackingObject";
import {trackEvent} from "../../store/tracking/actions";
import {checkUserExists, loadUserProfile, logout} from "../../store/user/actions";
import useCompany from "../../utils/hooks/use-company";
import Login from "../../views/login";
import ProfilePanel from "../../views/profile-panel";
import {getMediaPath} from "../../utils/media/media";
import { PLATFORM_AREAS } from "../../utils/constants";


const HeaderLayout = () => {
    const [profileVisible, setProfileVisible] = useState(false);
    const [query] = useSearchParams();
    const { companyName } = useParams();
    const location = useLocation();
    const platformArea = PLATFORM_AREAS.find(area => {
        if (area.exact) {
            return location.pathname === area.path
        } else {
            return location.pathname.startsWith(area?.path)
        }
    });

    const source = query.get('source');
    const popup = query.get('popup');
    const navigate = useNavigate();
    const error = useRouteError();

    const {
        userLoggedIn,
        firstname,
        lastname,
        image,
        menu,
    } = useSelector(state => {
        const {
            user: {
                login: {
                    success: userLoggedIn
                },
                profile: {
                    firstname,
                    lastname,
                    image
                } = {
                    firstname: '',
                    lastname: ''
                }
            },
            company: {
                menu,
            }
        } = state;
        return {
            userLoggedIn,
            firstname,
            lastname,
            image,
            menu,            
        };
    });

    const dispatch = useDispatch();

    const {
        isCompanyReady,
        notFound,
    } = useCompany(userLoggedIn)

    useEffect(() => {
        if(notFound === true && !platformArea) {
            navigate('/not-found', { replace: true })
        }
    }, [notFound, navigate, dispatch, platformArea])

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(checkUserExists());
        }
        if(userLoggedIn) {
            dispatch(loadUserProfile());
        }
    }, [userLoggedIn, dispatch]);

    useEffect(() => {
        dispatch(trackEvent(
                TrackingObject(TrackingEvent.APP_LOADED, {
                    source: source ? source : 'direct',
                    pageName: TrackingPageName.APP,
                })
            )
        );
    }, []);

    const onUsernameClick = () => {
        setProfileVisible(true);
        //dispatch(logout());
    }

    const onProfileCloseClick = () => {
        setProfileVisible(false);
    }

    const onLogoutClick = () => {
        setProfileVisible(false);
        dispatch(logout());
    }

    const avatar = getMediaPath(image, 'thumbnail');

    useEffect(() => {
        if (popup && popup === 'profile' && userLoggedIn) {
            setProfileVisible(true);
        }
    }, [popup, userLoggedIn])


    return (
        <Center>
            {
                userLoggedIn ?
                    (
                        isCompanyReady ?
                            (
                                <Fragment>
                                    <NavBar
                                        menu={menu}
                                        username={`${firstname} ${lastname}`}
                                        avatar={avatar}
                                        onUsernameClick={() => onUsernameClick()}
                                    />
                                    <Outlet/>
                                    {
                                        profileVisible &&
                                        <ProfilePanel
                                            onCloseClick={onProfileCloseClick}
                                            onLogoutClick={onLogoutClick}
                                        />
                                    }
                                </Fragment>
                            ) :
                            (
                                <GenericBackground noNavBar>
                                    <Spinner />
                                </GenericBackground>
                            )
                    ) :
                    <Login/>
            }
        </Center>
    )
}

const BounceSpinner = ({className}) => {
    return (
        <div className={className}>
            <SquareLoader loading={true} size={32} color="#796e65" speedMultiplier={2}/>
        </div>
    )
}

const Spinner = styled(BounceSpinner)`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export default HeaderLayout;
