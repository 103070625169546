import axios from "axios";
import {call, put, takeEvery, select} from 'redux-saga/effects';
import {API, COMPANY, GENERAL} from "../../../utils/constants";
import {startLoading, stopLoading} from "../../loading/actions";
import {initSentry} from "../../sentry/actions";
import {disableTracking, initTracking} from "../../tracking/actions";
import {logout} from "../../user/actions";

axios.defaults.headers.common['accept'] = `application/json`;

function* loadCompany(action) {
    try {
        yield put(startLoading());
        const {jwt} = yield select(state => state.user);
        const header = {headers: {Authorization: `Bearer ${jwt}`}};
        const response = yield call(axios, `${API.URL}${API.COMPANY_PATH(GENERAL.IS_PREVIEW)}`, header);
        const {data} = response;
        yield put({type: COMPANY.LOAD_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch (e) {
        yield put(logout());
        yield put({type: COMPANY.LOAD_ERROR});
        yield put(stopLoading(e));
    }
}


function* track(action) {
    // Comment cause of unused remote variables
    // const {payload: {data}} = action;
    // if (data && data.length > 0) {

    //     const {
    //         attributes: {
    //             enableMixpanel,
    //             mixpanelToken,
    //             enableSentry,
    //             sentryDsn,
    //         }
    //     } = [...data].shift();

    //     const debug = !GENERAL.IS_PRODUCTION;

    //     if (enableMixpanel && mixpanelToken) {
    //         yield put(initTracking(mixpanelToken, debug));
    //     } else {
    //         yield put(disableTracking());
    //     }
    //     if (enableSentry && sentryDsn) {
    //         yield put(initSentry(sentryDsn));
    //     }
    // }
}

function* initWatchers() {
    yield takeEvery(COMPANY.LOAD_SUCCESS, track);
    yield takeEvery(COMPANY.LOAD, loadCompany);
}

export default initWatchers;
