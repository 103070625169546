import { Link } from "react-router-dom";

const Events = () => {
  return (
    <div class="page-content-outter">
      <div class="spacer item-4-by-1"></div>
      <div class="page-content-inner">
        <div class="lobby-item item-1-by-1 lobby-bg-green">
          <div class="lobby-nav-title">EVENTS</div>
        </div>
        <div class="page-close-wrapper">
          <Link role="link" to="/gls/lobby" class="page-close-link">
            <i class="fal fa-times"></i>
          </Link>
        </div>
        <div class="content-wrapper">
          <div class="mt-3">
            <div class="row">
              <div class="col-12">
                <img src="/images/GLS23_PF_Events.jpg" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events