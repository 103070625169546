import styled from "styled-components";

export const StickerWrapper = styled.div`
  position: absolute;
  top: 5rem;
  right: 5rem;
`;

export const StickerImage = styled.img`
  width: 500px;
  max-width: 40vw;
  min-width: 240px;
`;
