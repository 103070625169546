import { enqueueSnackbar } from 'notistack'

const {USER} = require('../../../utils/constants')

const checkUserExists = () => (
    {
        type: USER.CHECK_USER_EXISTS,
    }
)

const resetLoginUser = (
    {
        type: USER.RESET_LOGIN_USER,
    }
)

const loginUser = (params) => (
    {
        type: USER.LOGIN,
        payload: params
    }
)

const loginSuccess = (params) => (
    {
        type: USER.LOGIN_SUCCESS,
        payload: params
    }
)

const loginError = (params) => (
    {
        type: USER.LOGIN_ERROR,
        payload: params
    }
)

const resetRegistration = (
    {
        type: USER.RESET_REGISTRATION,
    }
)

const registerUser = (params) => (
    {
        type: USER.REGISTER,
        payload: params
    }
)

const registerSuccess = (
    {
        type: USER.REGISTER_SUCCESS,
    }
)

const registerError = (params) => (
    {
        type: USER.REGISTER_ERROR,
        payload: params
    }
)

const resetRecoverPassword= (
    {
        type: USER.RESET_RECOVER_PASSWORD,
    }
)

const recoverPassword = (params) => (
    {
        type: USER.RECOVER_PASSWORD,
        payload: params
    }
)

const recoverPasswordSuccess = (
    {
        type: USER.RECOVER_PASSWORD_SUCCESS,
    }
)

const recoverPasswordError = (params) => (
    {
        type: USER.RECOVER_PASSWORD_ERROR,
        payload: params
    }
)

const resetPassword = (params) => (
    {
        type: USER.RESET_PASSWORD,
        payload: params
    }
)

const resetPasswordSuccess = (
    {
        type: USER.RESET_PASSWORD_SUCCESS,
    }
)

const resetPasswordError = (params) => (
    {
        type: USER.RESET_PASSWORD_ERROR,
        payload: params
    }
)

const logout = () => (
    {
        type: USER.LOGOUT,
    }
)

const loadUserCompanies = (
    {
        type: USER.LOAD_USER_COMPANIES,
    }
)

const loadUserCompaniesSuccess = (params) => (
    {
        type: USER.LOAD_USER_COMPANIES_SUCCESS,
        payload: params
    }
)

const loadUserCompaniesError = (params) => (
    {
        type: USER.LOAD_USER_COMPANIES_ERROR,
        payload: params
    }
)

const loadUserFunctions = (
    {
        type: USER.LOAD_USER_FUNCTIONS,
    }
)

const loadUserFunctionsSuccess = (params) => (
    {
        type: USER.LOAD_USER_FUNCTIONS_SUCCESS,
        payload: params
    }
)

const loadUserFunctionsError = (params) => (
    {
        type: USER.LOAD_USER_FUNCTIONS_ERROR,
        payload: params
    }
)

const loadUserLanguages = (
    {
        type: USER.LOAD_USER_LANGUAGES,
    }
)

const loadUserLanguagesSuccess = (params) => (
    {
        type: USER.LOAD_USER_LANGUAGES_SUCCESS,
        payload: params
    }
)

const loadUserLanguagesError = (params) => (
    {
        type: USER.LOAD_USER_LANGUAGES_ERROR,
        payload: params
    }
)

const loadTherapeuticAreas = (
    {
        type: USER.LOAD_USER_THERAPEUTIC_AREAS,
    }
)

const loadTherapeuticAreasSuccess = (params) => (
    {
        type: USER.LOAD_USER_THERAPEUTIC_AREAS_SUCCESS,
        payload: params
    }
)

const loadTherapeuticAreasError = (params) => (
    {
        type: USER.LOAD_USER_THERAPEUTIC_AREAS_ERROR,
        payload: params
    }
)

const updateUserProfile = (params) => (
    {
        type: USER.UPDATE_USER_PROFILE,
        payload: params
    }
)

const updateUserProfileSuccess = (params) => {
    enqueueSnackbar('Update user profile success', { variant: 'success' });
    return function (dispatch) {
        dispatch({
            type: USER.UPDATE_USER_PROFILE_SUCCESS,
            payload: params
        })
    }
}

const updateUserProfileError = (params) => {
    enqueueSnackbar('Update user profile error', { variant: 'error' });
    return function (dispatch) {
        dispatch({
            type: USER.UPDATE_USER_PROFILE_ERROR,
            payload: params
        })
    }
}


const updateUserPassword = (params) => (
    {
        type: USER.UPDATE_USER_PASSWORD,
        payload: params
    }
)

const updatePasswordSuccess = (params) => (
    {
        type: USER.UPDATE_USER_PASSWORD_SUCCESS,
        payload: params
    }
)
const updatePasswordError = (params) => (
    {
        type: USER.UPDATE_USER_PASSWORD_ERROR,
        payload: params
    }
)

const uploadUserAvatar = (params) => (
    {
        type: USER.UPLOAD_USER_AVATAR,
        payload: params
    }
)

const uploadUserAvatarSuccess = (params) => (
    {
        type: USER.UPLOAD_USER_AVATAR_SUCCESS,
        payload: params
    }
)

const uploadUserAvatarError = (params) => (
    {
        type: USER.UPLOAD_USER_AVATAR_ERROR,
        payload: params
    }
)


const loadUserProfile = () => (
    {
        type: USER.LOAD_USER_PROFILE,
    }
)

const loadUserProfileSuccess = (params) => (
    {
        type: USER.LOAD_USER_PROFILE_SUCCESS,
        payload: params
    }
)

const loadUserProfileError = (params) => (
    {
        type: USER.LOAD_USER_PROFILE_ERROR,
        payload: params
    }
)



export {
    checkUserExists,
    resetLoginUser,
    loginUser,
    loginSuccess,
    loginError,
    logout,
    resetRegistration,
    registerUser,
    registerSuccess,
    registerError,
    resetRecoverPassword,
    recoverPassword,
    recoverPasswordSuccess,
    recoverPasswordError,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordError,
    loadUserCompanies,
    loadUserCompaniesSuccess,
    loadUserCompaniesError,
    loadUserFunctions,
    loadUserFunctionsSuccess,
    loadUserFunctionsError,
    loadUserLanguages,
    loadUserLanguagesSuccess,
    loadUserLanguagesError,
    loadTherapeuticAreas,
    loadTherapeuticAreasSuccess,
    loadTherapeuticAreasError,
    updateUserProfile,
    updateUserProfileSuccess,
    updateUserProfileError,
    updateUserPassword,
    updatePasswordSuccess,
    updatePasswordError,
    uploadUserAvatar,
    uploadUserAvatarSuccess,
    uploadUserAvatarError,
    loadUserProfile,
    loadUserProfileSuccess,
    loadUserProfileError,
};
