import { StickerImage, StickerWrapper } from "./Sticker.style";
import { API } from "../../utils/constants";
import { Link } from "react-router-dom";
const Sticker = ({ sticker }) => {
  const imageSrc = sticker?.sticker?.url;
  const imageLink = sticker?.hyperlink;
  if (!imageSrc) return null;
  return (
    <StickerWrapper>
      {imageLink ? (
        <Link to={imageLink}>
         <StickerImage src={`${API?.URL}${imageSrc}`} alt="sticker" />
        </Link>
      ) : (
        <StickerImage src={`${API?.URL}${imageSrc}`} alt="sticker" />
      )}
    </StickerWrapper>
  );
};

export default Sticker;
