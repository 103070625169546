import * as Sentry from "@sentry/react";
import {takeEvery} from "redux-saga/effects";
import {GENERAL, SENTRY} from "../../../utils/constants";

function warnIfDevelopmentEnvironment() {
    if (process.env.NODE_ENV === 'development') {
        console.warn('Sentry Saga: Not initializing Sentry in development mode');
        return true;
    }
    return false;
}

function initializeSentry(dsn) {
    // TODO: Adjust sample rates for production
    Sentry.init({
        dsn,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        console: false,
        release: `${GENERAL.PLATFORM}-${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
        environment: GENERAL.ENVIRONMENT,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

function init(action) {
    const {payload: {dsn}} = action;

    if (warnIfDevelopmentEnvironment()) return;

    initializeSentry(dsn);
}

function* initWatchers() {
    yield takeEvery(SENTRY.INIT, init);
}

export default initWatchers;

