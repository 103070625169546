import { useState } from 'react';
import { getMediaPath } from '../../utils/media/media';

const sampleItems = [
  {
    image: 'https://images.unsplash.com/photo-1578496479914-7ef3b0193be3?q=80&w=5070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: '2024 is going to be a pivotal year for Asthma where we will elevate from simply outplaying the competition, into outright market leadership in 6 out of top 10 global markets.',
    title: 'Mountain Vista'
  },
  {
    image: 'https://plus.unsplash.com/premium_photo-1710846906747-7ee7199956ed?q=80&w=5070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: 'Sunset over the ocean with sailing boats',
    title: 'Ocean Sunset'
  },
  {
    image: 'https://images.unsplash.com/photo-1576670263020-7842552c87d0?q=80&w=5070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: 'Dense forest with morning fog',
    title: 'Misty Forest'
  }
];

const transformItems = (items) => {
  return items?.map((item) => ({
    ...item,
    image: getMediaPath(item, "large"),
    description: item?.caption,
    title: item?.caption,
  }));
};

const Carousel = ({ items }) => {
  const transformedItems = transformItems(items);
  console.log("🚀 ~ Carousel ~ transformedItems:", transformedItems)
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Carousel container */}
      <div className="relative h-[300px] sm:h-[400px] md:h-[500px] lg:h-[46rem] overflow-hidden rounded-lg">
        {/* Images */}
        {transformedItems?.map((item, index) => (
          <div
            key={index}
            className={`absolute w-full h-full transition-transform duration-500 ease-in-out ${
              index === currentIndex ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-full object-cover"
            />
            {/* Updated gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
              {/* Responsive text content */}
              <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6">
                <p className="text-xs sm:text-sm md:text-base text-gray-200 text-center p-2 sm:p-3">
                  {item.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation buttons - Hidden on mobile, visible on larger screens */}
      <button
        onClick={prevSlide}
        className="hidden sm:block absolute left-[-16px] top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-1 sm:p-2 rounded-full hover:bg-opacity-75 transition-all"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <button
        onClick={nextSlide}
        className="hidden sm:block absolute right-[-16px] top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-1 sm:p-2 rounded-full hover:bg-opacity-75 transition-all"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>

      {/* Dots indicator - Made larger and more spaced on mobile */}
      <div className="absolute bottom-2 sm:bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-3 sm:space-x-2">
        {transformedItems?.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 sm:w-2 sm:h-2 rounded-full transition-all ${
              index === currentIndex ? 'bg-white' : 'bg-white/50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
