import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HamburgerButton,
  HamburgerTitle,
  MenuItem,
  MenuList,
  NavBarFooter,
  NavBarHeader,
  NavBarLogoBrand,
  NavBarMenu,
  NavBarMenuWrapper,
  NavBarUserProfile,
  NavBarWrapper,
} from "./NavBar.style";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { setPlatformArea } from "../../store/company/actions/CompanyActions";
import { useAppConfig } from "../../contexts/app-config/app-config-context";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { applyMenuAppConfig } from "../../utils/mappings/app-config.util";

const Avatar = styled.div`
  box-sizing: border-box;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  border: 1px solid #aaa;
  overflow: hidden;
  object-fit: cover;
  margin-left: 0.8rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const addMailtoLinks = (blocks) => {
  return blocks.map((block) => {
    if (block.type === "paragraph" && block.children) {
      block.children = block.children.map((child) => {
        if (child.type === "text" && validateEmail(child.text)) {
          return {
            ...child,
            type: "link",
            url: `mailto:${child.text}`,
            children: [{ text: child.text, type: "text" }],
          };
        }
        return child;
      });
    }
    return block;
  });
};

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const NavBar = (props) => {
  const dispatch = useDispatch();
  const { username, avatar, onUsernameClick } = props;
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const handleClickMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };
  const location = useLocation();
  const currentCompany = getCurrentCompany(location);

  const {
    config: {
        event_section_visibility
    },
  } = useSelector(state => {
      const {company: {config,},} = state;
      return {config,};
  });

  useEffect(() => {
    dispatch(setPlatformArea(currentCompany));
  }, [dispatch]);

  const { config } = useAppConfig();

  let menu = applyMenuAppConfig(props.menu, {
    news_menu_label: config?.news_menu_label,
    event_section_visibility,
  });

  const transformedFooter = addMailtoLinks(config?.sidebar_footer || []);

  return (
    <NavBarHeader>
      <NavBarWrapper>
        <HamburgerButton
          onClick={handleClickMenu}
          className={`${isOpenMenu ? `is-open` : ""}`}
        >
          <span className="lines">
            <span></span>
          </span>
          <HamburgerTitle>Menu</HamburgerTitle>
        </HamburgerButton>
        <NavBarLogoBrand />
        <NavBarUserProfile>
          <span
            onClick={onUsernameClick}
            //to="/gls/profile"
            style={{ cursor: "pointer" }}
            className="d-flex align-items-center profile-link"
          >
            {username}
            {avatar ? (
              <Avatar>
                <img src={avatar} alt="Avatar" />
              </Avatar>
            ) : (
              <i className="pl-3 fa-2x fas fa-user-circle"></i>
            )}
          </span>
        </NavBarUserProfile>
      </NavBarWrapper>
      <NavBarMenuWrapper
        className={`nav-overlay ${isOpenMenu ? `is-visible` : ""}`}
      >
        <NavBarMenu>
          <MenuList>
            {menu.map((item) => {
              if (item.key === "gls" && !config?.gls_visibility) {
                return null; // Skip rendering this menu item
              }
              const isActive = location?.pathname === item?.link;
              return (
                <MenuItem key={item.key} isActive={isActive}>
                  <Link to={item?.link} onClick={handleClickMenu}>
                    {item?.name}
                  </Link>
                  {item?.subMenu?.length && currentCompany === item?.key && (
                    <MenuList className="sub-menu">
                      {item?.subMenu?.map((subItem) => {
                        const isActiveSub = location?.pathname.startsWith(
                          subItem?.link
                        );
                        return (
                          <MenuItem
                            isActive={isActiveSub}
                            isSubmenu
                            key={subItem.key}
                          >
                            {subItem?.locked && subItem?.locked === true ? (
                              <div>{subItem?.name}</div>
                            ) : (
                              <Link
                                to={subItem?.link}
                                onClick={handleClickMenu}
                              >
                                {subItem?.name}
                              </Link>
                            )}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  )}
                </MenuItem>
              );
            })}
          </MenuList>
        </NavBarMenu>
        {/*        <div className="counter-wrapper">
          <div className="counter">
            <div className="co-title">LIVE COUNTER</div>{" "}
            <div className="co-numbers">
              <div className="flip-number-wrapper d-inline-block">
                <div className="flip-number">
                  <div className="flip-number-item loop-item">
                    <div className="flip">
                      <div className="digital front number0"></div>{" "}
                      <div className="digital back number6"></div>
                    </div>
                  </div>
                  <div className="flip-number-item loop-item">
                    <div className="flip">
                      <div className="digital front number0"></div>{" "}
                      <div className="digital back number9"></div>
                    </div>
                  </div>
                  <div className="flip-number-item loop-item">
                    <div className="flip">
                      <div className="digital front number0"></div>{" "}
                      <div className="digital back number9"></div>
                    </div>
                  </div>
                  <div className="flip-number-item loop-item">
                    <div className="flip">
                      <div className="digital front number0"></div>{" "}
                      <div className="digital back number0"></div>
                    </div>
                  </div>
                  <div className="flip-number-item loop-item">
                    <div className="flip">
                      <div className="digital front number0"></div>{" "}
                      <div className="digital back number6"></div>
                    </div>
                  </div>
                  <div className="flip-number-item loop-item">
                    <div className="flip">
                      <div className="digital front number0"></div>{" "}
                      <div className="digital back number5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="co-description">Patients on Therapy</div>
          </div>
        </div>
        */}
        <NavBarFooter>
          <BlocksRenderer content={transformedFooter} />
        </NavBarFooter>
      </NavBarMenuWrapper>
    </NavBarHeader>
  );
};

const getCurrentCompany = (location) => {
  const { pathname } = location;
  const path = pathname.split("/");
  return path[1];
};
export default NavBar;
