import styled from "styled-components";
import {Link} from "react-router-dom";

const StyledLink = styled(({overColor, color, backgroundColor, overBackgroundColor, expand, isExternal, disabled, ...props}) => (
    disabled ? (
      <div {...props}></div>
    )
    : (
    isExternal ? (
      <Link {...props} target="_blank" rel="noopener noreferrer" />
    ) : (
      <Link {...props} />
    ))
))`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "roboto", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  width: ${props => props.expand? '100%' : '200px'};
  
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.8rem 0 0.5rem 0;
  margin: 1rem 0;
  text-align: center;
  

  /* Apply a smooth transition to all properties */
  transition: all 0.3s ease-in-out;

  /* Conditional styles for disabled state */
  background-color: ${(props) => (props.disabled ? '#F8F8F8' : (props.backgroundColor ? props.backgroundColor : '#000000'))};
  color: ${(props) => (props.disabled ? '#aaaaaa' : (props.color ? props.color : '#ffffff'))};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  
  

  &:hover {
    background-color: ${(props) => props.disabled ? 'auto' : (props.overBackgroundColor ? props.overBackgroundColor : "#A09DA9")}; /* Darker background color */
    color: ${(props) => props.disabled ? 'auto' : (props.overColor ? props.overColor : "#ffffff")}; /* Lighter text color */
  }

  ${(props) => props.isExternal && `target="_blank" rel="noopener noreferrer"`}; /* If the link is external, open in a new tab */
`;

const Icon = styled.i`
  padding-right: 0.25rem;
`;

const Button = ({ to, className, overColor, disabled, ...props }) => {
  const isExternal = /^https?:\/\//.test(to) && !to.includes(window.location.host);

  return (
    <StyledLink to={to} overColor={overColor} disabled={disabled} isExternal={isExternal} {...props}>
      {props.children}
    </StyledLink>
  );
};

export default Button;