import axios from "axios";
import {API} from "../utils/constants";
import { transformData } from "../utils/mappings/app-config.util";

export const getAppConfig = async () => {
  try {
    const response = await axios.get(`${API.URL}${API.SITE_CONFIG_PATH}`);
    return transformData(response.data);
  } catch (error) {
    throw new Error("Failed to fetch app config");
  }
};
