import { HiOutlineLightBulb } from "react-icons/hi";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaChessKnight } from "react-icons/fa";
import { Link } from "react-router-dom";

const KeyPioritiesItem = ({ item, index }) => {
  const { accentColor, backgroundColor, icon, text } = item;
  return (
    <div
      key={index}
      className={` bg-[${backgroundColor}] border-b-4 border-b-[${accentColor}] p-6`}
    >
      <div className="py-2">
        <i
          className={`fa fa-solid fa-${icon} text-[${accentColor}] text-[22px]`}
        />
      </div>
      <div className="pt-4">
        <Link to={item.link} className="text-white text-xl">
          {text}
        </Link>
      </div>
    </div>
  );
};

const KeyPiorities = ({
  className,
  title,
  description,
  items,
}) => {
  return (
    <div className={`max-w-screen-2xl flex flex-col ${className}`}>
      <h3 className="title text-3xl md:text-5xl my-2 md:my-8 py-4 max-w-screen-2xl text-black">
        {title}
      </h3>
      <h4 className="text-black my-8 py-4 text-3xl">{description}</h4>
      <div className=" justify-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {items?.map((item, index) => (
          <KeyPioritiesItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

const data = [
  {
    icon: <HiOutlineLightBulb size={30} color="#FB76FE" />,
    description: "Know the key global priorities for 2024",
    link: "https://www.google.com",
    color: "#FB76FE",
  },
  {
    icon: <BsGraphUpArrow size={30} color="#B164FF" />,
    description: "Know the key priorities for Respiratory for 2024",
    link: "https://www.google.com",
    color: "#B164FF",
  },
  {
    icon: <FaChessKnight size={30} color="#786CFF" />,
    description:
      "Have an action plan about how to sustain your work over the next year",
    link: "https://www.google.com",
    color: "#786CFF",
  },
];

export default KeyPiorities;
