const HeroSection = ({
  id,
  title,
  subHeading,
  backgroundImage,
  contentPosition = "left",
  className,
}) => {
  return (
    <div
      id={id}
      className={`relative min-h-[500px] h-screen w-full ${className}`}
    >
      {/* Background Image */}
      <img
        src={backgroundImage}
        alt="Hero background"
        className="w-full h-full object-cover"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black/50" />

      {/* Content Container */}
      <div className="absolute inset-0">
        <div className="h-full w-full max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
          <div
            className={`flex h-full items-center ${
              contentPosition === "right" ? "justify-end" : "justify-start"
            }`}
          >
            {/* Text Content */}
            <div
              className={`w-full sm:max-w-lg ${
                contentPosition === "right" ? "text-right" : "text-left"
              } p-4 sm:p-0`}
            >
              <h1 className="title text-3xl sm:text-4xl md:text-5xl lg:text-6xl tracking-tight text-white">
                {title}
              </h1>
              <p className="mt-2 sm:mt-3 text-sm sm:text-base md:text-lg lg:text-xl text-gray-300">
                {subHeading}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
