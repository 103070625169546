import {MEDIA_HUB} from "../../../utils/constants";

const resetNavigation = () => (
    {
        type: MEDIA_HUB.RESET_NAV,
    }
)

const loadNavigation = (mediaHubId) => (
    {
        type: MEDIA_HUB.LOAD_NAV,
        payload: mediaHubId,
    }
)

const loadContents = (categoryId) => (
    {
        type: MEDIA_HUB.LOAD_CONTENT,
        payload: categoryId,
    }
)

const loadItem = (itemId) => (
    {
        type: MEDIA_HUB.LOAD_ITEM,
        payload: itemId,
    }
)

export {resetNavigation, loadNavigation, loadContents, loadItem};
