import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import styled from "styled-components";
import Button from "../../components/button";
import CloseButton from "../../components/close-button";
import Panel from "../../components/panel";
import FormSelect from "../../components/form-select";
import { COUNTRIES } from "../../utils/constants";
import FormOption from "../../components/form-option/FormOption";
import { UpdatePassword } from "./UpdatePasswordForm";
import {
  loadTherapeuticAreas,
  loadUserCompanies,
  loadUserFunctions,
  loadUserLanguages,
  updateUserProfile,
} from "../../store/user/actions/UserActions";
import FormInputField from "../../components/form-input-field";
import { ProfileAvatar } from "./ProfileAvatar";
import {
  getDefaultTimezone,
  transformTimezones,
  getTimeZoneValue,
  getCurrentTimeZone,
} from "../../utils/common";
import { InlineEditButtons } from "./InlineEditButtons";
import Select from "react-select";
import { useSnackbar } from "notistack";
import { SlArrowDown } from "react-icons/sl";
import { trackEvent } from "../../store/tracking/actions";
import {
  TrackingEvent,
  TrackingObject,
  TrackingPageName,
} from "../../store/tracking/TrackingObject";
import SwitchButton from "../../components/switch-button";
import { useAppConfig } from "../../contexts/app-config/app-config-context";
import { AreaSubcategoryFormGroup } from "../../components/area-subcategory/area-subcategory-form-input";
import { isArray, isNumber } from "lodash";
import Tour from "reactour";
import "./ProfilePanel.css";
import { useSearchParams } from "react-router-dom";

const TIMEZONES = transformTimezones();
const currentTz = getCurrentTimeZone();
const defaultTimezone = getDefaultTimezone(TIMEZONES, currentTz);

const tourConfig = [
  {
    selector: '[data-tut="reactour__unscribte"]',
    content: `Toggle this switch to enable or disable email notifications.`,
    stepInteraction: false,
    disableActions: true,
  },
];
const accentColor = "#5cb7b7";

const ProfilePanel = ({ onCloseClick, onLogoutClick }) => {
  const emailToggleRef = useRef(null);
  const [query] = useSearchParams();
  const action = query.get("action");
  const [openTutorial, setOpenTutorial] = useState(action === "unsubscribe");

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const dispatch = useDispatch();
  const {
    primaryColor,
    profile,
    user,
    functions,
    therapeuticAreas,
    languages,
    area_subcategories,
  } = useSelector((state) => {
    const {
      company: {
        config: { primaryColor },
      },
      user: {
        user,
        functions,
        languages,
        companies,
        area_subcategories,
        therapeuticAreas,
      },
    } = state;
    return {
      primaryColor,
      profile: user?.user_profile,
      user,
      functions,
      languages,
      companies,
      therapeuticAreas,
      area_subcategories,
    };
  });
  const defaultAreaSubcategories = area_subcategories
    ? area_subcategories?.map((item) => item.id)
    : [];
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      area_subcategories: defaultAreaSubcategories,
    },
  });

  const [editingField, setEditingField] = useState(null);

  const Header = () => (
    <CloseButton
      onClick={onCloseClick}
      color={primaryColor}
      preventScrollReset={true}
    />
  );

  const onCancelEdit = () => {
    setEditingField(null);
  };

  useEffect(() => {
    dispatch(loadUserFunctions);
    dispatch(loadTherapeuticAreas);
    dispatch(loadUserLanguages);
    dispatch(loadUserCompanies);
    dispatch(
      trackEvent(
        TrackingObject(TrackingEvent.PAGE_VIEW, {
          pageName: TrackingPageName.USER_PROFILE,
        })
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (action === "unsubscribe" && openTutorial) {
      emailToggleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [openTutorial, action]);

  const onSaveEdit = (payload) => {
    let data = payload;
    const { area_subcategories } = payload;
    if (area_subcategories) {
      let area_subcategories_ids = [];
      if (isArray(area_subcategories)) {
        area_subcategories_ids = payload?.area_subcategories?.map((item) =>
          parseInt(item)
        );
      }
      if (isNumber(area_subcategories)) {
        area_subcategories_ids = [area_subcategories];
      }

      data = {
        ...data,
        area_subcategories: area_subcategories_ids,
      };
    }
    dispatch(updateUserProfile({ data }));
    setEditingField(null);
  };

  const onChangeNotification = (e) => {
    const data = {
      enable_notification: e.target.checked,
    };
    dispatch(updateUserProfile({ data }));
  };

  const { config } = useAppConfig();

  return (
    <Background>
      <Panel
        header={<Header />}
        footer={
          <Footer>
            <Button onClick={onLogoutClick}>Logout</Button>
          </Footer>
        }
      >
        <ProfileWrapper>
          <ProfileAvatar image={profile?.image} />
          <ProfileItem key={"firstname"}>
            <ProfileLabel>First Name</ProfileLabel>
            {editingField === "firstname" ? (
              <FormInputField
                {...register("firstname")}
                defaultValue={profile?.firstname}
              />
            ) : (
              <ProfileValue>{profile?.firstname}</ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("firstname")}
              isEditing={editingField === "firstname"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>
          <ProfileItem key={"lastname"}>
            <ProfileLabel>Last Name</ProfileLabel>
            {editingField === "lastname" ? (
              <FormInputField
                {...register("lastname")}
                defaultValue={profile?.lastname}
              />
            ) : (
              <ProfileValue>{profile?.lastname}</ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("lastname")}
              isEditing={editingField === "lastname"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>

          <ProfileItem key={"email"}>
            <ProfileLabel>Email</ProfileLabel>
            <ProfileValue>{user?.email}</ProfileValue>
          </ProfileItem>
          <ProfileItem key={"job_title"}>
            <ProfileLabel>Job title</ProfileLabel>
            {editingField === "job_title" ? (
              <FormInputField
                {...register("job_title")}
                defaultValue={profile?.job_title}
              />
            ) : (
              <ProfileValue>{profile?.job_title}</ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("job_title")}
              isEditing={editingField === "job_title"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>
          <ProfileItem key={"therapeutic_areas"}>
            <ProfileLabel>
              {config.therapeutic_area_category_label}
            </ProfileLabel>
            {editingField === "therapeutic_areas" ? (
              <ProfileValue>
                <FormSelect
                  {...register("therapeutic_area", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  error={errors.therapeutic_area}
                  defaultValue={profile?.therapeutic_area?.id}
                >
                  <FormOption value="" disabled>
                    {config.therapeutic_area_category_label}*
                  </FormOption>
                  {therapeuticAreas.map((therapeuticArea) => {
                    const { title, id } = therapeuticArea;
                    return (
                      <FormOption value={id} key={id}>
                        {title}
                      </FormOption>
                    );
                  })}
                </FormSelect>
              </ProfileValue>
            ) : (
              <ProfileValue>{profile?.therapeutic_area?.title}</ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("therapeutic_areas")}
              isEditing={editingField === "therapeutic_areas"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>

          <ProfileItem key={"area_subcategories"}>
            <ProfileLabel>Area Sub Category</ProfileLabel>
            {editingField === "area_subcategories" ? (
              <ProfileValue>
                <AreaSubcategoryFormGroup
                  layout="vertical"
                  {...register("area_subcategories", {
                    required: "Please select at least one area subcategory.",
                    valueAsNumber: true,
                    value: profile?.area_subcategories?.map((item) => item.id),
                  })}
                  therapeuticId={profile?.therapeutic_area?.id}
                />
              </ProfileValue>
            ) : (
              <ProfileValue>
                {profile?.area_subcategories
                  ?.map((item) => item.name)
                  .join(", ")}
              </ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("area_subcategories")}
              isEditing={editingField === "area_subcategories"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>
          <p>{errors?.area_subcategories?.message}</p>

          <ProfileItem key={"user_function"}>
            <ProfileLabel>Function</ProfileLabel>
            {editingField === "user_function" ? (
              <ProfileValue>
                <FormSelect
                  {...register("user_function", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  defaultValue={profile?.user_function}
                  error={errors.user_function}
                >
                  <FormOption value="" disabled>
                    Function
                  </FormOption>
                  {functions.map((userFunction) => {
                    const { id, title } = userFunction;
                    return (
                      <FormOption value={id} key={id}>
                        {title}
                      </FormOption>
                    );
                  })}
                </FormSelect>
              </ProfileValue>
            ) : (
              <ProfileValue>{profile?.user_function?.title}</ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("user_function")}
              isEditing={editingField === "user_function"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>

          <ProfileItem key={"country_code"}>
            <ProfileLabel>Country</ProfileLabel>
            {editingField === "country_code" ? (
              <ProfileValue>
                <FormSelect
                  {...register("country_code", { required: true })}
                  defaultValue={profile?.country_code}
                  error={errors.country_code}
                >
                  <FormOption value="" disabled>
                    Country*
                  </FormOption>
                  {COUNTRIES.map((country) => {
                    const { code, name } = country;
                    return (
                      <FormOption value={code} key={code}>
                        {name}
                      </FormOption>
                    );
                  })}
                </FormSelect>
              </ProfileValue>
            ) : (
              <ProfileValue>{profile?.country_code}</ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("country_code")}
              isEditing={editingField === "country_code"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>

          <ProfileItem key={"timezone"}>
            <ProfileLabel>Timezone</ProfileLabel>
            {editingField === "timezone" ? (
              <ProfileValue>
                <Controller
                  control={control}
                  render={() => (
                    <Select
                      required
                      components={{
                        DropdownIndicator: () => (
                          <SlArrowDown
                            fontSize={9}
                            style={{
                              marginRight: 4,
                              color: "#727272",
                              fontWeight: "bold",
                            }}
                          />
                        ),
                      }}
                      styles={{
                        container: (baseStyles) => ({
                          ...baseStyles,
                          width: "100%",
                          alignSelf: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          alignSelf: "center",
                        }),
                        indicatorsContainer: (baseStyles) => ({
                          ...baseStyles,
                          alignSelf: "center",
                        }),
                        indicatorSeparator: () => null,
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: "1px solid #727272",
                          boxShadow: state.isFocused
                            ? "0 0 2px 2px rgba(0,155,119,0.5)"
                            : "none",
                          borderRadius: 0,
                          background: "transparent",
                          textAlign: "center",
                          verticalAlign: "center",
                          minWidth: "100%",
                          alignSelf: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }),
                      }}
                      defaultValue={defaultTimezone}
                      options={TIMEZONES}
                    />
                  )}
                  name="timezone_offset"
                />
              </ProfileValue>
            ) : (
              <ProfileValue>
                {getTimeZoneValue(profile?.timezone_offset)}
              </ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("timezone")}
              isEditing={editingField === "timezone"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>

          <ProfileItem key={"language"}>
            <ProfileLabel>Language</ProfileLabel>
            {editingField === "language" ? (
              <ProfileValue>
                <FormSelect
                  {...register("language", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  defaultValue={profile?.language?.id}
                  error={errors.language}
                >
                  <FormOption value="" disabled>
                    Language Preference*
                  </FormOption>
                  {languages.map((language) => {
                    const { id, title } = language;
                    return (
                      <FormOption value={id} key={id}>
                        {title}
                      </FormOption>
                    );
                  })}
                </FormSelect>
              </ProfileValue>
            ) : (
              <ProfileValue>{profile?.language?.title}</ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("language")}
              isEditing={editingField === "language"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>

          <ProfileItem key={"region"}>
            <ProfileLabel>Region</ProfileLabel>
            {editingField === "region" ? (
              <FormInputField
                {...register("region")}
                defaultValue={profile?.region}
              />
            ) : (
              <ProfileValue>{profile?.region}</ProfileValue>
            )}
            <InlineEditButtons
              onEdit={() => setEditingField("region")}
              isEditing={editingField === "region"}
              onSave={handleSubmit(onSaveEdit)}
              onCancel={onCancelEdit}
            />
          </ProfileItem>
          <div data-tut="reactour__unscribte" ref={emailToggleRef}>
            <ProfileItem
              key={"enable_notification"}
              data-tut="reactour__unscribte"
            >
              <ProfileLabel>Email notification</ProfileLabel>
              <ProfileValue flex="end">
                <SwitchButton
                  checked={profile?.enable_notification}
                  onChange={onChangeNotification}
                />
              </ProfileValue>
            </ProfileItem>
          </div>
          <hr />
          <UpdatePassword />
        </ProfileWrapper>
      </Panel>
      <Tour
        onRequestClose={() => setOpenTutorial(false)}
        steps={tourConfig}
        isOpen={openTutorial}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
    </Background>
  );
};

const ProfileWrapper = styled.div`
  max-height: 65vh;
  padding: 1rem 0;
`;

const Background = styled.div`
  position: fixed;
  display: flex;
  padding-top: 3em;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  //width: 100vw;
  //height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  div {
    align-items: unset !important;
  }
`;
const ProfileItem = styled.form`
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ProfileLabel = styled.div`
  width: 8rem;
  padding-right: 0.25rem;
  font-size: 0.9rem;
`;

const ProfileValue = styled.div`
  display: flex;
  flex: 1;
  font-weight: bold;
  ${(props) => (props.flex === "end" ? "justify-content: flex-end;" : "")}
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export default ProfilePanel;
