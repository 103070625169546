import styled from "styled-components";
import Modal from "react-modal";
Modal.setAppElement("#root"); // Use your root element here

const StyledModal = styled(Modal)`
  background: white;
  padding: 20px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  max-width: 600px; // Or your desired width
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1000; // Ensure it's above the overlay
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6; // Bootstrap-like styling
  margin-bottom: 20px;
`;

const ModalTitle = styled.h5`
  margin: 0;
`;

const ModalBody = styled.div`
  padding-bottom: 20px; // Adjust as needed
`;

const ModalFooter = styled.div`
  text-align: right;
  border-top: 1px solid #dee2e6; // Bootstrap-like styling
  padding-top: 10px;
`;
const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ) !important; // Adjust the color and opacity for the dim effect
  z-index: 999; // Ensure it's above other content but below the modal
`;

const CloseButton = styled.button`
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;
const LPModal = (props) => {
  const { title, children, footer, closeModal, ...rest } = props;
  return (
    <StyledModal
      {...rest}
      overlayElement={(props, contentElement) => (
        <StyledOverlay {...props}>{contentElement}</StyledOverlay>
      )}
    >
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <CloseButton onClick={closeModal}>×</CloseButton>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </StyledModal>
  );
};

export default LPModal;
