import styled from "styled-components";

const IconOverlayed = styled.i`
  color: ${props => props.color};
  background-color: #ffffff;
  position: absolute;
  padding: 1rem;
`;

export default IconOverlayed;
