import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Center from '../../components/center';
import Layout from "../../components/content-area";
import MediaList from "../../components/media-list";
import SectionHeader from "../../components/section-header";
import SideBar from "../../components/side-bar";
import TopLogo from "../../components/top-logo";
import {API, GENERAL} from "../../utils/constants";
import {loadNavigation, resetNavigation} from "../../store/learning-journey/actions";
import {TrackingEvent, TrackingObject, TrackingPageName} from "../../store/tracking/TrackingObject";
import {trackEvent} from "../../store/tracking/actions";
import {getLearningJourneyList} from "../../store/learning-journey/service";
import {useQuery} from "@tanstack/react-query";
// import { SearchForm } from "../../components/search-form";


const LearningJourney = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [item, setItem] = useState(null);
    // const {video = null, cover = null, caption = null} = item || {};
    const [itemContent, setItemContent] = useState(null);
    // const sharePath = GENERAL.LIVE_DEEPLINK + pathname;

    const {
        companyName,
        learningJourneyContainerId,
        sectionId,
        itemId,
        mediaId,
    } = useParams();

    const {
        items,
        initialSectionId,
        initialItemId,
        primaryColor,
        mediaHubs,
        topLogo,
        cornerRadius,
    } = useSelector(state => {
        const {
            learningJourney: {
              items,
              initialSectionId,
              initialItemId,
            },
            mediaHub: {
                content
            },
            company: {
                config: {primaryColor, topLogo, cornerRadius},
                mediaHubs,
            }
        } = state;

        return {items, initialSectionId, initialItemId, content, primaryColor, mediaHubs, topLogo, cornerRadius};
    });

    const {data: content} = useQuery({
        queryKey: ['learning-journey', learningJourneyContainerId, sectionId, itemId],
        queryFn: () => getLearningJourneyList(learningJourneyContainerId),
    });

    const setPath = (selected) => {
        navigate(`/${companyName}/learning-journey/${learningJourneyContainerId}/${selected[0]}/${selected[1]}#content`);
    }

    const mediaHub = mediaHubs.find(item => item.id === learningJourneyContainerId) || mediaHubs[0];
    const {bannerImage = "", title = ""} = mediaHub || {};

    const onSelectMediaHub = (id) => {
        dispatch(resetNavigation());
        navigate(`/${companyName}/learning-journey/${learningJourneyContainerId}/${id}`,);
    }

    useEffect(() => {
        dispatch(loadNavigation(learningJourneyContainerId));
    }, [learningJourneyContainerId, dispatch]);

    useEffect(() => {
        if (items?.length && learningJourneyContainerId && sectionId && itemId) {
            if(mediaId && itemContent) {
                dispatch(trackEvent(TrackingObject(TrackingEvent.CONTENT_VIEW, {
                    pageName: TrackingPageName.MEDIA_HUB,
                    indication: mediaHub.title,
                    mainCategory: items.find(item => item.id === sectionId)?.title,
                    subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
                    contentTitle: itemContent.title,
                })));
            } else {
                dispatch(trackEvent(TrackingObject(TrackingEvent.PAGE_VIEW, {
                    pageName: TrackingPageName.MEDIA_HUB,
                    indication: mediaHub.title,
                    mainCategory: items.find(item => item.id === sectionId)?.title,
                    subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
                })));
            }
        }
    }, [items, learningJourneyContainerId, sectionId, itemId, mediaId, itemContent, dispatch, title]);


    useEffect(() => {
        if (!sectionId && !itemId && initialSectionId && initialItemId) {
            setPath([initialSectionId, initialItemId])
        }
        
        if (items?.length && initialSectionId && initialItemId) {
            if (sectionId && itemId) {
                // dispatch(loadContents(itemId));
            } else if (sectionId && !itemId) {
                // dispatch(loadContents(sectionId));
                return;
            } else {
                // dispatch(loadContents(initialItemId));
            }
        }
        if (items?.length > 0 && (sectionId || initialSectionId) && (itemId || initialItemId)) {
            const itemToSet = items.find(item => item.id === (sectionId || initialSectionId))
            if (itemToSet) {
                setItem(itemToSet?.children.find(child => child.id === (itemId || initialItemId)));
            }
        }
    }, [items, itemId, initialSectionId, initialItemId, dispatch, sectionId])


    return (
        <Center hidden={false}>
            <SectionHeader>
                <img src={`${API.URL}${bannerImage}`} alt="Media Hub Header"/>
                <TopLogo src={`${API.URL}${topLogo}`}/>
            </SectionHeader>
            {
                items && initialSectionId && initialItemId ?
                    <Layout hidden={!items} sideBar={
                        <SideBar items={items}
                                 pageName={TrackingPageName.MEDIA_HUB}
                                 primaryColor={primaryColor}
                                 selected={[sectionId || initialSectionId, itemId || initialItemId]}
                                 setSelected={setPath}
                                 divisionId={learningJourneyContainerId}
                                 divisions={mediaHubs}
                                 onDivisionSelect={onSelectMediaHub}
                        />
                    }>
                        {/* <SearchForm primaryColor={primaryColor}/> */}
                        {
                            content ?
                                <MediaList items={content}
                                           primaryColor={primaryColor}
                                           borderRadius={cornerRadius}
                                           path={`/${companyName}/learning-journey/${learningJourneyContainerId}/${sectionId || initialSectionId}/${itemId || initialItemId}/`}/>
                                : null
                        }
                    </Layout>
                    : null
            }
        </Center>
    )
}

export default LearningJourney;
