import styled from "styled-components";

const Dutube = () => {
  return (
    <DutubeIframe
      allowfullscreen="allowfullscreen"
      width="100%"
      height="100%"
      src="https://dutube.gls-live.com/?id=test"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  );
};

const DutubeIframe = styled.iframe`
  width: 100%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 3rem;
  padding-bottom: 60px;
`;
export default Dutube;
