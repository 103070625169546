import styled from "styled-components";
import { useAppConfig } from "../../contexts/app-config/app-config-context";

const FormInputFieldStyle = styled.input`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex: 1;
  padding: 0.3rem;
  margin: 0;
  color: ${props => props.error ? 'red' : '#727272'};
  border: ${props => props.error ? '1px solid red' : '1px solid #727272'};
  outline: none;
  box-shadow: none;
  :focus {
    box-shadow: 0 0 2px 2px ${props => props.accentColor ? props.accentColor : 'rgba(0, 155, 200, 0.5)' };
    //border: 1px solid #727272;
    //color: #727272
  }
`;

export const FormInputField = ({ type, error, accentColor, ...props }) => {
  const { config } = useAppConfig()
  return (
    <FormInputFieldStyle
      type={type}
      error={error}
      accentColor={config?.accent_color}
      {...props}
    />
  );
};

export default FormInputField;
