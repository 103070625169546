import {WHATS_NEW} from "../../../utils/constants";

const loadWhatsNew = (queryParams) => ({
    type: WHATS_NEW.LOAD,
    queryParams // Add queryParams to the action
});

const setVisible = (isVisible) => (
    {
        type: WHATS_NEW.SET_VISIBLE,
        payload: isVisible,
    }
)

export {loadWhatsNew, setVisible};
