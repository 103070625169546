import {getLatestContentVersion, mapImageFormats} from "../../../utils/mappings";
import {LEARNING_JOURNEY} from '../../../utils/constants';
import { get } from "lodash";

const STATE = {
    items: null,
    initialSectionId: null,
    initialItemId: null,
    content: null,
    item: null,
}

const mapLearningJourney = (payload) => {
    // const {data: {media_hub_categories}} = payload;
    const journey_categories = get(payload, 'data.journey_categories', []);
    console.log("🚀 ~ mapLearningJourney ~ journey_categories:", journey_categories)
    return mapNavChildren(journey_categories, false);
}

const mapNavChildren = (payload, secondary = false) => {
    console.log("🚀 ~ mapNavChildren ~ payload:", payload)
    return payload?.map(item => {
        const {id, title, video, children, documentId} = item;
        if (video) {
            const {
                videoFile: {data:  {url: videoUrl, caption: videoCaption}},
                coverImage: {data: {formats: {large: {url: coverUrl}}}}
            } = video;
            return secondary ? {
                id: `${id}`,
                title,
                video: videoUrl,
                cover: coverUrl,
                caption: videoCaption
            } : {id: `${id}`,documentId, title, children: mapNavChildren(children, true)};
        }
        return secondary ? {id: `${id}`, title, video, documentId } : {id: `${id}`, title, documentId, children: mapNavChildren(children, true)};
    })
}



function learningJourney(state = STATE, action) {
    const {type, payload} = action;
    switch (type) {
        case LEARNING_JOURNEY.RESET_NAV:
            return {...state, items: null, initialSectionId: null, initialItemId: null, content: null};
        case LEARNING_JOURNEY.LOAD_NAV:
            return {...state, items: null, initialSectionId: null, initialItemId: null, content: null};
        case LEARNING_JOURNEY.LOAD_NAV_SUCCESS:
            const categories = mapLearningJourney(payload);
            console.log("🚀 ~ learningJourney ~ categories:", categories)
            if (categories?.length) {
                for (const category of categories) {
                    const {id: initialSectionId, children} = category;
                    if (children?.length) {
                        const {id: initialItemId} = children[0];
                        return {...state, items: categories, initialSectionId, initialItemId};
                    } else {
                        return {...state, items: categories, initialSectionId, initialItemId: categories[0]?.id};
                    }
                }
                return {...state};
            }
            return {...state};
        default:
            return state;
    }
}

export default learningJourney;
