import {TRACKING} from "../../../utils/constants";

const initTracking = (token, debug = false) => (
    {
        type: TRACKING.INIT,
        payload: {
            token,
            debug,
        },
    }
)

const disableTracking = () => (
    {
        type: TRACKING.DISABLE,
    }
)

const trackingInitialized = () => (
    {
        type: TRACKING.INITIALIZED,
    }
)

const trackIdentity = (userId, userEmail) => (
    {
        type: TRACKING.IDENTITY,
        payload: {
            userId,
            userEmail,
        }
    }
)

const trackEvent = (trackingObject) => (
    {
        type: TRACKING.EVENT,
        payload: trackingObject,
    }
)

export {initTracking, disableTracking, trackingInitialized, trackIdentity, trackEvent};
