import {useState} from "react";
import {API} from "../../utils/constants";
import IconOverlayed from "../icon-overlayed";
import Grid from "./grid";
import Image from "./image";
import ImageContainer from "./image-container";
import Item from "./item";
import NonClickable from "./nonclickable";
import Text from './text';

export const MediaItem = ({ item }) => {
    const {id, title, thumbnail, path, primaryColor, borderRadius, setSelected, selected} = item;
    return (
        <Item key={id}
            color={primaryColor}
            preventScrollReset={true}
            to={`${path}${id}`}
            onMouseOver={() => setSelected(id)}
            onMouseOut={() => setSelected(null)}
        >
            <ImageContainer selected={selected === id} borderRadius={`${borderRadius}px`}>
                <Image key={id} src={`${API.URL}${thumbnail}`}/>
            </ImageContainer>
            <Text>{title}</Text>
        </Item>
    )
}

const MediaList = ({items, path, primaryColor, borderRadius}) => {
    const [selected, setSelected] = useState(null);
    if(!items) return;
    return (
        <Grid>
            {
                items.map(item => {
                    const {image: { thumbnail }, title, id, locked} = item;
                    return (
                        !locked ?
                            <MediaItem key={id} item={
                                {
                                    id,
                                    title,
                                    thumbnail,
                                    path,
                                    primaryColor,
                                    borderRadius,
                                    setSelected,
                                    selected
                                }
                            } /> :
                            <NonClickable key={id}>
                                <ImageContainer selected={selected === id} borderRadius={`${borderRadius}px`}>
                                    <Image key={thumbnail} src={`${API.URL}${thumbnail}`} dimmed />
                                    <IconOverlayed className="far fa-lock" color={primaryColor} />
                                </ImageContainer>
                                <Text>{title}</Text>
                            </NonClickable>
                    )
                })
            }
        </Grid>
    )
}

export default MediaList;
