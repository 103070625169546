import {Fragment} from "react";
import remarkGfm from "remark-gfm";
import styled from "styled-components";
import {API} from "../../../utils/constants";
import ExternalLink from "../../external-link";
import ImageModal from "../../image-modal";
import LoadableImage from "../../loadable-image";
import Markdown from "../../markdown";
import LinkRenderer from "../../markdown/LinkRenderer";
import MediaList from "../../media-list";
import PDF from "../../pdf";
import Video from "../../video";

const NewsContent = ({content, path, primaryColor, onPlayVideo, onDownloadPDF, onExternalLinkClick, borderRadius}) => {
    const {elements} = content;
    return (
        <Content>
            {
                elements.map((element, i) => <RenderElement element={element}
                                                            path={path}
                                                            key={i}
                                                            borderRadius={borderRadius}
                                                            primaryColor={primaryColor}
                                                            onVideoPlay={onPlayVideo}
                                                            onDownloadPDF={onDownloadPDF}
                                                            onExternalLinkClick={onExternalLinkClick}
                />)
            }
        </Content>
    )
}

const Content = styled.div`
  flex-direction: column;
  display: flex;
`;

const RenderElement = ({element, path, primaryColor, onVideoPlay, onDownloadPDF, onExternalLinkClick, borderRadius}) => {
    const {type, url, title, text, items, cover, size, formats, caption, aspectRatio, doublePage} = element;
    switch (type) {
        case "text":
            return (
                <Markdown components={{a: LinkRenderer}} remarkPlugins={[remarkGfm]}>{text}</Markdown>
            )
        case "mediahub-items":
            return <MediaList items={items} path={path} primaryColor={primaryColor} key={element} borderRadius={borderRadius}/>
        case "image":
            return <ImageModal image={element}
                               baseUrl={API.URL}
                               key={url}
                               color={primaryColor}
                               maxHeight={500}
                               style={{marginBottom: '2em', cursor: 'pointer'}}
            />
        case "video":
            return (
                <Video src={`${API.URL}${url}`}
                       controls
                       onPlay={() => onVideoPlay({url, caption})}
                       poster={`${API.URL}${cover}`}
                       key={url}>
                    Sorry, your browser doesn't support embedded videos,
                    but don't worry, you can <a href={url}>download it</a>
                    and watch it with your favorite video player!
                </Video>
            )
        case "link":
            return <ExternalLink href={url}
                                 color={primaryColor}
                                 iconClassName="far fa-link"
                                 onClick={() => onExternalLinkClick({title, url})}
            >
                {title}
            </ExternalLink>
        case "pdf":
            return (
                <Fragment key={url}>
                    <PDF doublePage={doublePage} url={`${API.URL}${url}`} primaryColor={primaryColor}/>
                    <ExternalLink href={`${API.URL}${url}`}
                                  color={primaryColor}
                                  iconClassName="far fa-download"
                                  onClick={() => onDownloadPDF({title, url})}
                    >
                        {title} ({size} MB)
                    </ExternalLink>
                </Fragment>
            )
        default:
            return null;
    }
}

export default NewsContent;
