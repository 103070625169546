import FlipCard from "./flip-card";
import Paragraph from "./paragraph";

export const FlashCards = ({ title, sub_title, items }) => {
  console.log("🚀 ~ FlashCards ~ items:", items)
  return (
    <>
      <div className="max-w-[600px]">
        <Paragraph className="text-black text-[24px] text-center">
          {title}
        </Paragraph>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full max-w-7xl">
        {items?.map((item, index) => (
          <FlipCard
            key={index}
            title={item?.title}
            bgColor={`bg-[${item?.frontBackgroundColor}]`}
            bgColorBack={`bg-[${item?.backBackgroundColor}]`}
            textColor={`text-[${item?.frontTextColor}]`}
            textColorBack={`text-[${item?.backTextColor}]`}
            description={item?.description}
          />
        ))}
      </div>
      <div className="flex flex-col gap-4 max-w-[600px] justify-center items-center mt-8 px-4">
        <Paragraph className="text-black text-lg sm:text-[22px] text-center">
          {sub_title}
        </Paragraph>
      </div>
    </>
  );
};
