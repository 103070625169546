import {LOADING} from '../../../utils/constants';

const STATE = {
    loading: false,
    error: false,
    count: 0,
    messages: [

    ],
}

function news(state = STATE, action) {
    const {type, payload} = action;
    const {count, messages} = state;
    switch (type) {
        case LOADING.START:
            return {...state, loading: true, error: false, count: count + 1};
        case LOADING.ERROR:
            return {...state, loading: false, error: true, count: count - 1, messages: [...messages, payload]};
        case LOADING.SUCCESS:
            return {...state, loading: false, error: true, count: count - 1};
        default:
            return state;
    }
}

export default news;
