import { useQuery } from "@tanstack/react-query";
import { getAreaSubcategory } from "../../../services/company.service";

export const useTherapeuticArea = () => {};

export const useAreaSubcategory = (therapeuticId) => {
  return useQuery({
    queryKey: ["areaSubcategory", therapeuticId],
    queryFn: async () => {
      return getAreaSubcategory(therapeuticId);
    },
  });
};
