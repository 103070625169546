import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {loadCompany, selectCompany} from "../../../store/company/actions";
import {loadUserProfile} from "../../../store/user/actions";

export default function useCompanySelected(initialize = false) {

    const dispatch = useDispatch();

    const [isCompanyLoaded, setIsCompanyLoaded] = useState(false);

    const {companyName: urlSlug} = useParams()
    const {
        companyId,
        storedSlug,
        loading,
        loaded,
        error,
        allCompanies,
    } = useSelector(state => {
        const {
            company: {
                loading,
                loaded,
                error,
                config: {
                    id: companyId,
                    slug: storedSlug,
                },
                all: allCompanies
            }
        } = state;
        return {
            companyId,
            storedSlug,
            loading,
            loaded,
            error,
            allCompanies,
        };
    });

    const shouldLoadCompany = initialize && !isCompanyLoaded;
    const isCompanyReady = (isCompanyLoaded && !urlSlug) || (isCompanyLoaded && urlSlug === storedSlug);
    // Introducing an exception for the /gls path
    const isGLSPath = window.location.pathname.includes('/gls'); // Directly checking the pathname

    // Adjusting the notFound logic to exclude the /gls path
    const notFound = !isGLSPath && allCompanies.length > 0 && allCompanies.filter(company => company.config.slug === urlSlug).length === 0;
    useEffect(() => {
        if (shouldLoadCompany) {
            dispatch(loadCompany())
        }
    }, [shouldLoadCompany]);

    useEffect(() => {
        if(loaded && !loading && !error) {
            setIsCompanyLoaded(true);
        }
    }, [loaded, loading, error]);

    useEffect(() => {
        if (urlSlug && urlSlug !== storedSlug) {
            dispatch(selectCompany(urlSlug));
        }
    }, [urlSlug, storedSlug]);

    return {
        isCompanyReady,
        companySlug: urlSlug,
        companyId,
        notFound
    };
}
