import styled from "styled-components";



export const Badge = styled.div`
  border-radius: 5px;
  background-color: #EEEEEE;
  font-size: 0.7rem;
  display: inline-block;
  padding: 0.2rem 0.5rem;
  color: #796E65;
  text-transform: uppercase;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;
