import {useEffect, useState} from "react";

const useImageSelector = (formats,
                          dimensions,
                          aspectRatio,
                          maxHeight = null) => {
    const [format, setFormat] =
        useState({width: 0, height: 0, url: ''});

    const calculateScaledDimensions = (maxWidth, maxHeight, aspectRatio) =>
        aspectRatio > maxWidth / maxHeight
            ? { width: maxWidth, height: maxWidth / aspectRatio }
            : { width: maxHeight * aspectRatio, height: maxHeight };

    useEffect(() => {
        // If the dimensions haven't been set yet, return
        if (dimensions.width === 0) return;

        const {imgWidth, imgHeight} = calculateScaledDimensions(dimensions.width, maxHeight, aspectRatio);

        for (const format of formats) {
            // Destructure the format object
            const {width, height, url} = format;
            // Check if the format is larger than the dimensions to ensure we don't upscale the image
            const h = maxHeight != null && maxHeight < height ? maxHeight : height;
            if (width >= imgWidth && height >= imgHeight) {
                setFormat({width: h * aspectRatio, height: h, url})
                break;
            }
            // If we've reached the end of the formats array, use the last format
            setFormat({width: h * aspectRatio, height: h, url})
        }
    }, [formats, dimensions, aspectRatio, maxHeight])
    return format;
}

export default useImageSelector;
