import {Link} from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const Item = styled(({color, ...props}) => (
    <Link {...props} />
))`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  &:hover,
  &:focus {
    color: ${props => props.color};
  }
  ${breakpoint('sm')` // 24
    width: calc(50% - 10px);
  `}  
  ${breakpoint('lg')` // 24
    width: calc(33.3333333% - 10px);
  `}
`;

export default Item;
