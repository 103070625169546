import styled from "styled-components";

const FormErrorMessage = ({ message }) => {
    return message ? (
        <Message>{message.message}</Message>
    ) : null
};

const Message = styled.p`
  width: 100%;
  color: red;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  margin: 0;
`;

export default FormErrorMessage;
