import styled from "styled-components";

const CoverImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: ${props => props.hidden ? 0 : 1};
  transition: 0.1s linear;
  z-index: 0;
`;

export default CoverImage;
