import { LOADING } from "../../../utils/constants";

const startLoading = () => ({
    type: LOADING.START
});

const stopLoading = (error = false) => {
    if (!error) {
        return {
            type: LOADING.SUCCESS
        };
    } else {
        // Use optional chaining and default to undefined if the values are not present
        const url = error?.request?.responseURL;
        const message = error?.message;
        const method = error?.config?.method;

        return {
            type: LOADING.ERROR,
            payload: {
                url,
                method,
                message,
            }
        };
    }
};

export { startLoading, stopLoading };
