import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import { GENERAL, MIXPANEL_TOKEN, SENTRY_DSN } from "./constants";

export function initializeTracking() {
  return mixpanel.init(MIXPANEL_TOKEN, { debug: !GENERAL.IS_PRODUCTION, ignore_dnt: true });
}

export function initializeSentry() {
    // TODO: Adjust sample rates for production
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        console: false,
        release: `${GENERAL.PLATFORM}-${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
        environment: GENERAL.ENVIRONMENT,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

