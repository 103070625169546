import styled from "styled-components";

const NonClickable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  color: #796e65;
`;

export default NonClickable;
