import {
    areaSubCategoryQuery,
    companyQuery,
    eventsPagesQuery, loginQuery,
    mediaHubItemQuery, mediaHubItemQueryPreview,
    learningJourneyNavQuery,
    mediaHubItemsByCategoryQuery,
    mediaHubItemsQuery,
    mediaHubNavQuery, mediaHubsQuery,
    newsItemQuery,
    newsNavQuery,
    stickerQuery,
    whatsNewQuery
} from "../queries";

const USE_LOCAL_STRAPI = false;
const REMOTE_HOSTNAME = 'dupixent-derm.apps.dev.launchpharm.cloud';

const IS_LOCAL = window.location.hostname.indexOf("localhost") > -1;
const IS_STAGING = window.location.hostname.indexOf("apps.stage") > -1;
const IS_DEV = window.location.hostname.indexOf("apps.dev") > -1;
const IS_PREVIEW = window.location.hostname.indexOf("preview.gls-live.com") > -1;
const SHOW_PREVIEW = IS_LOCAL || IS_DEV || IS_STAGING || IS_PREVIEW;
const HOSTNAME = IS_PREVIEW ? 'gls-live.com' : window.location.hostname;
const LOCAL_API_URL = USE_LOCAL_STRAPI ? `http://localhost:1337` : `https://api.${REMOTE_HOSTNAME}`;
const API_URL = IS_LOCAL ? LOCAL_API_URL : `https://api.${HOSTNAME}`;

const PLATFORM = 'dupixent-derm'; // TODO: Fix for tracking later
//const PLATFORM_SHORT = PLATFORM.split('-')[1];
const LIVE_DEEPLINK = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
const ENVIRONMENT = HOSTNAME.split('.', 3)[2];

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const GENERAL = {
    IS_PREVIEW: SHOW_PREVIEW,
    PLATFORM,
    LIVE_DEEPLINK,
    ENVIRONMENT,
}

const API = {
    URL: API_URL,
    LOGIN_PATH_LOCAL: `/api/auth/local?${loginQuery}`,
    REGISTER_PATH_LOCAL: `/api/auth/local/register`,
    USER_PROFILE:  `/api/user-profiles`,
    GET_USER_PROFILE_ME:  `/api/users/me?populate[user_profile][populate]=*`,
    UPDATE_USER_PROFILE: (userId) => `/api/user-profiles/${userId}`,
    FORGOT_PASSWORD_PATH: `/api/auth/forgot-password`,
    UPDATE_PASSWORD_PATH: `/api/auth/change-password`,
    RESET_PASSWORD_PATH: `/api/auth/reset-password`,
    USER_COMPANY_PATH: `/api/user-companies`,
    UPLOAD_PATH: `/api/upload`,
    USER_FUNCTION_PATH: `/api/user-functions`,
    USER_LANGUAGES_PATH: `/api/user-languages`,
    USER_THERAPEUTIC_AREAS_PATH: `/api/therapeutic-areas`,
    SITE_CONFIG_PATH: `/api/configuration?populate=*`,
    COMPANY_PATH: (preview = false) => `/api/companies/?${companyQuery}&status=${preview ? 'draft' : 'published'}`,
    WHATS_NEW_PATH: (companyId, preview = false) => `/api/whats-news?${whatsNewQuery(companyId)}&status=${preview ? 'draft' : 'published'}`, // #
    EVENTS_PAGE_PATH: (companyId, preview = false) => `/api/events-pages?${eventsPagesQuery(companyId)}&status=${preview ? 'draft' : 'published'}`, // #
    MEDIA_HUBS_PATH: (companyId, preview = false) => `/api/media-hubs?${mediaHubsQuery(companyId)}&status=${preview ? 'draft' : 'published'}`, // #
    MEDIA_HUB_NAV_PATH: (mediaHubId, preview = false) => `/api/media-hubs?${mediaHubNavQuery(preview ? 'draft' : 'published', mediaHubId)}&status=${preview ? 'draft' : 'published'}`, // #
    LEARNING_JOURNEY_NAV_PATH: (learningJourneyId, preview = false) => `/api/journey-containers/${learningJourneyId}?${learningJourneyNavQuery(preview ? 'draft' : 'published', learningJourneyId)}&status=${preview ? 'draft' : 'published'}`, // #
    MEDIA_HUB_ITEMS_PATH: (categoryId, preview = false) => `/api/media-hub-items?${mediaHubItemsQuery(categoryId)}&status=${preview ? 'draft' : 'published'}`, // #
    MEDIA_HUB_ITEMS_BY_CATEGORY_PATH: (categoryId, preview = false) => `/api/media-hub-categories?${mediaHubItemsByCategoryQuery(preview ? 'draft' : 'published', categoryId)}&status=${preview ? 'draft' : 'published'}`, // #
    // MEDIA_HUB_ITEMS_BY_CATEGORY_PATH: (categoryId, preview = false) => `http://localhost:1337/api/content-visibility-restriction/content/10/1/media-hub-items`, // #
    MEDIA_HUB_ITEM_PATH: (mediaHubItemId, preview = false) => `/api/media-hub-items/${mediaHubItemId}?${preview ? mediaHubItemQueryPreview : mediaHubItemQuery}`, // #
    NEWS_NAV_PATH: (companyId, preview = false) => `/api/news-categories/?${newsNavQuery(companyId)}&status=${preview ? 'draft' : 'published'}`, // #
    NEWS_ITEM_PATH: (newsItemId, preview = false) => `/api/news-items?${newsItemQuery(newsItemId)}&status=${preview ? 'draft' : 'published'}`, // #
    STICKER_ITEM_PATH: (companyId, preview = false) => `/api/stickers?${stickerQuery(companyId)}&status=${preview ? 'draft' : 'published'}`, // #
    AREA_SUBCATEGORY_PATH: (therapeuticAreaId, preview = false) => `/api/area-subcategories?${areaSubCategoryQuery(therapeuticAreaId)}&status=${preview ? 'draft' : 'published'}`, // #
}

const USER = {
    CHECK_USER_EXISTS: 'USER_CHECK_USER_EXISTS',
    RESET_LOGIN_USER: 'USER_RESET_LOGIN_USER',
    LOGIN: 'USER_LOGIN',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_ERROR: 'USER_LOGIN_ERROR',
    LOGOUT: 'USER_LOGOUT',
    RESET_REGISTRATION: 'USER_RESET_REGISTRATION',
    REGISTER: 'USER_REGISTER',
    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_ERROR: 'USER_REGISTER_ERROR',
    RESET_RECOVER_PASSWORD: 'USER_RESET_RECOVER_PASSWORD',
    RECOVER_PASSWORD: 'USER_RECOVER_PASSWORD',
    RECOVER_PASSWORD_SUCCESS: 'USER_RECOVER_PASSWORD_SUCCESS',
    RECOVER_PASSWORD_ERROR: 'USER_RECOVER_PASSWORD_ERROR',
    RESET_PASSWORD: 'USER_RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'USER_RESET_PASSWORD_ERROR',
    ERROR: 'USER_ERROR',
    LOAD_USER_PROFILE: 'LOAD_USER_PROFILE',
    LOAD_USER_PROFILE_SUCCESS: 'LOAD_USER_PROFILE_SUCCESS',
    LOAD_USER_PROFILE_ERROR: 'LOAD_USER_PROFILE_ERROR',
    LOAD_USER_COMPANIES: 'LOAD_USER_COMPANIES',
    LOAD_USER_COMPANIES_SUCCESS: 'LOAD_USER_COMPANIES_SUCCESS',
    LOAD_USER_COMPANIES_ERROR: 'LOAD_USER_COMPANIES_ERROR',
    LOAD_USER_FUNCTIONS: 'LOAD_USER_FUNCTIONS',
    LOAD_USER_FUNCTIONS_SUCCESS: 'LOAD_USER_FUNCTIONS_SUCCESS',
    LOAD_USER_FUNCTIONS_ERROR: 'LOAD_USER_FUNCTIONS_ERROR',
    LOAD_USER_LANGUAGES: 'LOAD_USER_LANGUAGES',
    LOAD_USER_LANGUAGES_SUCCESS: 'LOAD_USER_LANGUAGES_SUCCESS',
    LOAD_USER_LANGUAGES_ERROR: 'LOAD_USER_LANGUAGES_ERROR',
    LOAD_USER_THERAPEUTIC_AREAS: 'LOAD_USER_THERAPEUTIC_AREAS',
    LOAD_USER_THERAPEUTIC_AREAS_SUCCESS: 'LOAD_USER_THERAPEUTIC_AREAS_SUCCESS',
    LOAD_USER_THERAPEUTIC_AREAS_ERROR: 'LOAD_USER_THERAPEUTIC_AREAS_ERROR',
    UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
    UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
    UPDATE_USER_PROFILE_ERROR: 'UPDATE_USER_PROFILE_ERROR',
    UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
    UPDATE_USER_PASSWORD_SUCCESS: 'UUPDATE_USER_PASSWORD_SUCCESS',
    UPDATE_USER_PASSWORD_ERROR: 'UPDATE_USER_PASSWORD_ERROR',
    UPLOAD_USER_AVATAR: 'UPLOAD_USER_AVATAR',
    UPLOAD_USER_AVATAR_SUCCESS: 'UPLOAD_USER_AVATAR_SUCCESS',
    UPLOAD_USER_AVATAR_ERROR: 'UPLOAD_USER_AVATAR_ERROR',
}

const COMPANY = {
    SET_PLATFORM_AREA: 'COMPANY_SET_PLATFORM_AREA',
    SET_QUERY_PARAMS: 'COMPANY_SET_QUERY_PARAMS',
    LOAD: 'COMPANY_LOAD',
    LOAD_SUCCESS: 'COMPANY_LOAD_SUCCESS',
    LOAD_ERROR: 'COMPANY_LOAD_ERROR',
    SELECT: 'COMPANY_SELECT',
    RESET: 'COMPANY_RESET',
}

const WHATS_NEW = {
    LOAD: 'WHATS_NEW_LOAD',
    LOAD_SUCCESS: 'WHATS_NEW_LOAD_SUCCESS',
    LOAD_ERROR: 'WHATS_NEW_LOAD_ERROR',
    SET_VISIBLE: 'WHATS_NEW_SET_VISIBLE',
}

const NEWS = {
    LOAD_NAV: 'NEWS_LOAD_NAV',
    LOAD_NAV_SUCCESS: 'NEWS_LOAD_NAV_SUCCESS',
    LOAD_NAV_ERROR: 'NEWS_LOAD_NAV_ERROR',
    LOAD_CONTENT: 'NEWS_LOAD_CONTENT',
    LOAD_CONTENT_SUCCESS: 'NEWS_LOAD_CONTENT_SUCCESS',
    LOAD_CONTENT_ERROR: 'NEWS_LOAD_CONTENT_ERROR',
}

const MEDIA_HUB = {
    RESET_NAV: 'MEDIA_HUB_RESET_NAV',
    LOAD_NAV: 'MEDIA_HUB_LOAD_NAV',
    LOAD_NAV_SUCCESS: 'MEDIA_HUB_LOAD_NAV_SUCCESS',
    LOAD_NAV_ERROR: 'MEDIA_HUB_LOAD_NAV_ERROR',
    LOAD_CONTENT: 'MEDIA_HUB_LOAD_CONTENT',
    LOAD_CONTENT_SUCCESS: 'MEDIA_HUB_LOAD_CONTENT_SUCCESS',
    LOAD_CONTENT_ERROR: 'MEDIA_HUB_LOAD_CONTENT_ERROR',
    LOAD_ITEM: 'MEDIA_HUB_LOAD_ITEM',
    LOAD_ITEM_SUCCESS: 'MEDIA_HUB_LOAD_ITEM_SUCCESS',
    LOAD_ITEM_ERROR: 'MEDIA_HUB_LOAD_ITEM_ERROR',
}

const LEARNING_JOURNEY = {
    RESET_NAV: 'LEARNING_JOURNEY_RESET_NAV',
    LOAD_NAV: 'LEARNING_JOURNEY_LOAD_NAV',
    LOAD_NAV_SUCCESS: 'LEARNING_JOURNEY_LOAD_NAV_SUCCESS',
    LOAD_NAV_ERROR: 'LEARNING_JOURNEY_LOAD_NAV_ERROR',
    LOAD_CONTENT: 'LEARNING_JOURNEY_LOAD_CONTENT',
    LOAD_CONTENT_SUCCESS: 'LEARNING_JOURNEY_LOAD_CONTENT_SUCCESS',
    LOAD_CONTENT_ERROR: 'LEARNING_JOURNEY_LOAD_CONTENT_ERROR',
    LOAD_ITEM: 'LEARNING_JOURNEY_LOAD_ITEM',
    LOAD_ITEM_SUCCESS: 'LEARNING_JOURNEY_LOAD_ITEM_SUCCESS',
    LOAD_ITEM_ERROR: 'LEARNING_JOURNEY_LOAD_ITEM_ERROR',
}

const EVENTS_PAGE = {
    LOAD: 'EVENTS_PAGE_LOAD',
    LOAD_SUCCESS: 'EVENTS_PAGE_LOAD_SUCCESS',
    LOAD_ERROR: 'EVENTS_PAGE_LOAD_ERROR',
}

const TRACKING = {
    INIT: 'TRACKING_INIT',
    DISABLE: 'TRACKING_DISABLE',
    IDENTITY: 'TRACKING_IDENTITY',
    INITIALIZED: 'TRACKING_INITIALIZED',
    EVENT: 'TRACKING_EVENT',
}

const SENTRY = {
    INIT: 'SENTRY_INIT',
}

const LOADING = {
    START: 'LOADING_START',
    SUCCESS: 'LOADING_SUCCESS',
    ERROR: 'LOADING_ERROR',
}

const STICKER = {
    LOAD: 'LOAD_STICKER',
    LOAD_SUCCESS: 'LOAD_STICKER_SUCCESS',
    LOAD_ERROR: 'LOAD_STICKER_ERROR',
}

const IMAGE_TYPES = {
    FULL: 'full',
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
    THUMBNAIL: 'thumbnail',
}

export const PLATFORM_AREAS = [
    {
        path: "/connect",
        exact: false,
        name: "system"
    },
    {
        path: "/gls",
        exact: false,
        name: "gls"
    },
    {
        path: "/forgot-password",
        exact: false,
        name: "system"
    },
    {
        path: "/reset-password",
        exact: false,
        name: "system"
    },
    {
        path: "/email-confirmation",
        exact: false,
        name: "system"
    },
    {
        path: "/register",
        exact: false,
        name: "system"
    },
    {
        path: "/",
        exact: true,
        name: "lobby"
    },
]

const COUNTRIES = [
    {
        "code": "AF",
        "name": "Afghanistan"
    },
    {
        "code": "AL",
        "name": "Albania"
    },
    {
        "code": "DZ",
        "name": "Algeria"
    },
    {
        "code": "AS",
        "name": "American Samoa"
    },
    {
        "code": "AD",
        "name": "Andorra"
    },
    {
        "code": "AO",
        "name": "Angola"
    },
    {
        "code": "AI",
        "name": "Anguilla"
    },
    {
        "code": "AQ",
        "name": "Antarctica"
    },
    {
        "code": "AG",
        "name": "Antigua and Barbuda"
    },
    {
        "code": "AR",
        "name": "Argentina"
    },
    {
        "code": "AM",
        "name": "Armenia"
    },
    {
        "code": "AW",
        "name": "Aruba"
    },
    {
        "code": "AU",
        "name": "Australia"
    },
    {
        "code": "AT",
        "name": "Austria"
    },
    {
        "code": "AZ",
        "name": "Azerbaijan"
    },
    {
        "code": "BS",
        "name": "Bahamas"
    },
    {
        "code": "BH",
        "name": "Bahrain"
    },
    {
        "code": "BD",
        "name": "Bangladesh"
    },
    {
        "code": "BB",
        "name": "Barbados"
    },
    {
        "code": "BY",
        "name": "Belarus"
    },
    {
        "code": "BE",
        "name": "Belgium"
    },
    {
        "code": "BZ",
        "name": "Belize"
    },
    {
        "code": "BJ",
        "name": "Benin"
    },
    {
        "code": "BM",
        "name": "Bermuda"
    },
    {
        "code": "BT",
        "name": "Bhutan"
    },
    {
        "code": "BO",
        "name": "Bolivia"
    },
    {
        "code": "BA",
        "name": "Bosnia and Herzegovina"
    },
    {
        "code": "BW",
        "name": "Botswana"
    },
    {
        "code": "BV",
        "name": "Bouvet Island"
    },
    {
        "code": "BR",
        "name": "Brazil"
    },
    {
        "code": "IO",
        "name": "British Indian Ocean Territory"
    },
    {
        "code": "VG",
        "name": "British Virgin Islands"
    },
    {
        "code": "BN",
        "name": "Brunei"
    },
    {
        "code": "BG",
        "name": "Bulgaria"
    },
    {
        "code": "BF",
        "name": "Burkina Faso"
    },
    {
        "code": "BI",
        "name": "Burundi"
    },
    {
        "code": "KH",
        "name": "Cambodia"
    },
    {
        "code": "CM",
        "name": "Cameroon"
    },
    {
        "code": "CA",
        "name": "Canada"
    },
    {
        "code": "CV",
        "name": "Cape Verde"
    },
    {
        "code": "BQ",
        "name": "Caribbean Netherlands"
    },
    {
        "code": "KY",
        "name": "Cayman Islands"
    },
    {
        "code": "CF",
        "name": "Central African Republic"
    },
    {
        "code": "TD",
        "name": "Chad"
    },
    {
        "code": "CL",
        "name": "Chile"
    },
    {
        "code": "CN",
        "name": "China"
    },
    {
        "code": "CX",
        "name": "Christmas Island"
    },
    {
        "code": "CC",
        "name": "Cocos (Keeling) Islands"
    },
    {
        "code": "CO",
        "name": "Colombia"
    },
    {
        "code": "KM",
        "name": "Comoros"
    },
    {
        "code": "CK",
        "name": "Cook Islands"
    },
    {
        "code": "CR",
        "name": "Costa Rica"
    },
    {
        "code": "HR",
        "name": "Croatia"
    },
    {
        "code": "CU",
        "name": "Cuba"
    },
    {
        "code": "CW",
        "name": "Curaçao"
    },
    {
        "code": "CY",
        "name": "Cyprus"
    },
    {
        "code": "CZ",
        "name": "Czechia"
    },
    {
        "code": "CD",
        "name": "DR Congo"
    },
    {
        "code": "DK",
        "name": "Denmark"
    },
    {
        "code": "DJ",
        "name": "Djibouti"
    },
    {
        "code": "DM",
        "name": "Dominica"
    },
    {
        "code": "DO",
        "name": "Dominican Republic"
    },
    {
        "code": "EC",
        "name": "Ecuador"
    },
    {
        "code": "EG",
        "name": "Egypt"
    },
    {
        "code": "SV",
        "name": "El Salvador"
    },
    {
        "code": "GQ",
        "name": "Equatorial Guinea"
    },
    {
        "code": "ER",
        "name": "Eritrea"
    },
    {
        "code": "EE",
        "name": "Estonia"
    },
    {
        "code": "SZ",
        "name": "Eswatini"
    },
    {
        "code": "ET",
        "name": "Ethiopia"
    },
    {
        "code": "FK",
        "name": "Falkland Islands"
    },
    {
        "code": "FO",
        "name": "Faroe Islands"
    },
    {
        "code": "FJ",
        "name": "Fiji"
    },
    {
        "code": "FI",
        "name": "Finland"
    },
    {
        "code": "FR",
        "name": "France"
    },
    {
        "code": "GF",
        "name": "French Guiana"
    },
    {
        "code": "PF",
        "name": "French Polynesia"
    },
    {
        "code": "TF",
        "name": "French Southern and Antarctic Lands"
    },
    {
        "code": "GA",
        "name": "Gabon"
    },
    {
        "code": "GM",
        "name": "Gambia"
    },
    {
        "code": "GE",
        "name": "Georgia"
    },
    {
        "code": "DE",
        "name": "Germany"
    },
    {
        "code": "GH",
        "name": "Ghana"
    },
    {
        "code": "GI",
        "name": "Gibraltar"
    },
    {
        "code": "GR",
        "name": "Greece"
    },
    {
        "code": "GL",
        "name": "Greenland"
    },
    {
        "code": "GD",
        "name": "Grenada"
    },
    {
        "code": "GP",
        "name": "Guadeloupe"
    },
    {
        "code": "GU",
        "name": "Guam"
    },
    {
        "code": "GT",
        "name": "Guatemala"
    },
    {
        "code": "GG",
        "name": "Guernsey"
    },
    {
        "code": "GN",
        "name": "Guinea"
    },
    {
        "code": "GW",
        "name": "Guinea-Bissau"
    },
    {
        "code": "GY",
        "name": "Guyana"
    },
    {
        "code": "HT",
        "name": "Haiti"
    },
    {
        "code": "HM",
        "name": "Heard Island and McDonald Islands"
    },
    {
        "code": "HN",
        "name": "Honduras"
    },
    {
        "code": "HK",
        "name": "Hong Kong"
    },
    {
        "code": "HU",
        "name": "Hungary"
    },
    {
        "code": "IS",
        "name": "Iceland"
    },
    {
        "code": "IN",
        "name": "India"
    },
    {
        "code": "ID",
        "name": "Indonesia"
    },
    {
        "code": "IR",
        "name": "Iran"
    },
    {
        "code": "IQ",
        "name": "Iraq"
    },
    {
        "code": "IE",
        "name": "Ireland"
    },
    {
        "code": "IM",
        "name": "Isle of Man"
    },
    {
        "code": "IL",
        "name": "Israel"
    },
    {
        "code": "IT",
        "name": "Italy"
    },
    {
        "code": "CI",
        "name": "Ivory Coast"
    },
    {
        "code": "JM",
        "name": "Jamaica"
    },
    {
        "code": "JP",
        "name": "Japan"
    },
    {
        "code": "JE",
        "name": "Jersey"
    },
    {
        "code": "JO",
        "name": "Jordan"
    },
    {
        "code": "KZ",
        "name": "Kazakhstan"
    },
    {
        "code": "KE",
        "name": "Kenya"
    },
    {
        "code": "KI",
        "name": "Kiribati"
    },
    {
        "code": "XK",
        "name": "Kosovo"
    },
    {
        "code": "KW",
        "name": "Kuwait"
    },
    {
        "code": "KG",
        "name": "Kyrgyzstan"
    },
    {
        "code": "LA",
        "name": "Laos"
    },
    {
        "code": "LV",
        "name": "Latvia"
    },
    {
        "code": "LB",
        "name": "Lebanon"
    },
    {
        "code": "LS",
        "name": "Lesotho"
    },
    {
        "code": "LR",
        "name": "Liberia"
    },
    {
        "code": "LY",
        "name": "Libya"
    },
    {
        "code": "LI",
        "name": "Liechtenstein"
    },
    {
        "code": "LT",
        "name": "Lithuania"
    },
    {
        "code": "LU",
        "name": "Luxembourg"
    },
    {
        "code": "MO",
        "name": "Macau"
    },
    {
        "code": "MG",
        "name": "Madagascar"
    },
    {
        "code": "MW",
        "name": "Malawi"
    },
    {
        "code": "MY",
        "name": "Malaysia"
    },
    {
        "code": "MV",
        "name": "Maldives"
    },
    {
        "code": "ML",
        "name": "Mali"
    },
    {
        "code": "MT",
        "name": "Malta"
    },
    {
        "code": "MH",
        "name": "Marshall Islands"
    },
    {
        "code": "MQ",
        "name": "Martinique"
    },
    {
        "code": "MR",
        "name": "Mauritania"
    },
    {
        "code": "MU",
        "name": "Mauritius"
    },
    {
        "code": "YT",
        "name": "Mayotte"
    },
    {
        "code": "MX",
        "name": "Mexico"
    },
    {
        "code": "FM",
        "name": "Micronesia"
    },
    {
        "code": "MD",
        "name": "Moldova"
    },
    {
        "code": "MC",
        "name": "Monaco"
    },
    {
        "code": "MN",
        "name": "Mongolia"
    },
    {
        "code": "ME",
        "name": "Montenegro"
    },
    {
        "code": "MS",
        "name": "Montserrat"
    },
    {
        "code": "MA",
        "name": "Morocco"
    },
    {
        "code": "MZ",
        "name": "Mozambique"
    },
    {
        "code": "MM",
        "name": "Myanmar"
    },
    {
        "code": "NA",
        "name": "Namibia"
    },
    {
        "code": "NR",
        "name": "Nauru"
    },
    {
        "code": "NP",
        "name": "Nepal"
    },
    {
        "code": "NL",
        "name": "Netherlands"
    },
    {
        "code": "NC",
        "name": "New Caledonia"
    },
    {
        "code": "NZ",
        "name": "New Zealand"
    },
    {
        "code": "NI",
        "name": "Nicaragua"
    },
    {
        "code": "NE",
        "name": "Niger"
    },
    {
        "code": "NG",
        "name": "Nigeria"
    },
    {
        "code": "NU",
        "name": "Niue"
    },
    {
        "code": "NF",
        "name": "Norfolk Island"
    },
    {
        "code": "KP",
        "name": "North Korea"
    },
    {
        "code": "MK",
        "name": "North Macedonia"
    },
    {
        "code": "MP",
        "name": "Northern Mariana Islands"
    },
    {
        "code": "NO",
        "name": "Norway"
    },
    {
        "code": "OM",
        "name": "Oman"
    },
    {
        "code": "PK",
        "name": "Pakistan"
    },
    {
        "code": "PW",
        "name": "Palau"
    },
    {
        "code": "PS",
        "name": "Palestine"
    },
    {
        "code": "PA",
        "name": "Panama"
    },
    {
        "code": "PG",
        "name": "Papua New Guinea"
    },
    {
        "code": "PY",
        "name": "Paraguay"
    },
    {
        "code": "PE",
        "name": "Peru"
    },
    {
        "code": "PH",
        "name": "Philippines"
    },
    {
        "code": "PN",
        "name": "Pitcairn Islands"
    },
    {
        "code": "PL",
        "name": "Poland"
    },
    {
        "code": "PT",
        "name": "Portugal"
    },
    {
        "code": "PR",
        "name": "Puerto Rico"
    },
    {
        "code": "QA",
        "name": "Qatar"
    },
    {
        "code": "CG",
        "name": "Republic of the Congo"
    },
    {
        "code": "RO",
        "name": "Romania"
    },
    {
        "code": "RU",
        "name": "Russia"
    },
    {
        "code": "RW",
        "name": "Rwanda"
    },
    {
        "code": "RE",
        "name": "Réunion"
    },
    {
        "code": "BL",
        "name": "Saint Barthélemy"
    },
    {
        "code": "SH",
        "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
        "code": "KN",
        "name": "Saint Kitts and Nevis"
    },
    {
        "code": "LC",
        "name": "Saint Lucia"
    },
    {
        "code": "MF",
        "name": "Saint Martin"
    },
    {
        "code": "PM",
        "name": "Saint Pierre and Miquelon"
    },
    {
        "code": "VC",
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "code": "WS",
        "name": "Samoa"
    },
    {
        "code": "SM",
        "name": "San Marino"
    },
    {
        "code": "SA",
        "name": "Saudi Arabia"
    },
    {
        "code": "SN",
        "name": "Senegal"
    },
    {
        "code": "RS",
        "name": "Serbia"
    },
    {
        "code": "SC",
        "name": "Seychelles"
    },
    {
        "code": "SL",
        "name": "Sierra Leone"
    },
    {
        "code": "SG",
        "name": "Singapore"
    },
    {
        "code": "SX",
        "name": "Sint Maarten"
    },
    {
        "code": "SK",
        "name": "Slovakia"
    },
    {
        "code": "SI",
        "name": "Slovenia"
    },
    {
        "code": "SB",
        "name": "Solomon Islands"
    },
    {
        "code": "SO",
        "name": "Somalia"
    },
    {
        "code": "ZA",
        "name": "South Africa"
    },
    {
        "code": "GS",
        "name": "South Georgia"
    },
    {
        "code": "KR",
        "name": "South Korea"
    },
    {
        "code": "SS",
        "name": "South Sudan"
    },
    {
        "code": "ES",
        "name": "Spain"
    },
    {
        "code": "LK",
        "name": "Sri Lanka"
    },
    {
        "code": "SD",
        "name": "Sudan"
    },
    {
        "code": "SR",
        "name": "Suriname"
    },
    {
        "code": "SJ",
        "name": "Svalbard and Jan Mayen"
    },
    {
        "code": "SE",
        "name": "Sweden"
    },
    {
        "code": "CH",
        "name": "Switzerland"
    },
    {
        "code": "SY",
        "name": "Syria"
    },
    {
        "code": "ST",
        "name": "São Tomé and Príncipe"
    },
    {
        "code": "TW",
        "name": "Taiwan"
    },
    {
        "code": "TJ",
        "name": "Tajikistan"
    },
    {
        "code": "TZ",
        "name": "Tanzania"
    },
    {
        "code": "TH",
        "name": "Thailand"
    },
    {
        "code": "TL",
        "name": "Timor-Leste"
    },
    {
        "code": "TG",
        "name": "Togo"
    },
    {
        "code": "TK",
        "name": "Tokelau"
    },
    {
        "code": "TO",
        "name": "Tonga"
    },
    {
        "code": "TT",
        "name": "Trinidad and Tobago"
    },
    {
        "code": "TN",
        "name": "Tunisia"
    },
    {
        "code": "TR",
        "name": "Turkey"
    },
    {
        "code": "TM",
        "name": "Turkmenistan"
    },
    {
        "code": "TC",
        "name": "Turks and Caicos Islands"
    },
    {
        "code": "TV",
        "name": "Tuvalu"
    },
    {
        "code": "UG",
        "name": "Uganda"
    },
    {
        "code": "UA",
        "name": "Ukraine"
    },
    {
        "code": "AE",
        "name": "United Arab Emirates"
    },
    {
        "code": "GB",
        "name": "United Kingdom"
    },
    {
        "code": "US",
        "name": "United States"
    },
    {
        "code": "UM",
        "name": "United States Minor Outlying Islands"
    },
    {
        "code": "VI",
        "name": "United States Virgin Islands"
    },
    {
        "code": "UY",
        "name": "Uruguay"
    },
    {
        "code": "UZ",
        "name": "Uzbekistan"
    },
    {
        "code": "VU",
        "name": "Vanuatu"
    },
    {
        "code": "VA",
        "name": "Vatican City"
    },
    {
        "code": "VE",
        "name": "Venezuela"
    },
    {
        "code": "VN",
        "name": "Vietnam"
    },
    {
        "code": "WF",
        "name": "Wallis and Futuna"
    },
    {
        "code": "EH",
        "name": "Western Sahara"
    },
    {
        "code": "YE",
        "name": "Yemen"
    },
    {
        "code": "ZM",
        "name": "Zambia"
    },
    {
        "code": "ZW",
        "name": "Zimbabwe"
    },
    {
        "code": "AX",
        "name": "Åland Islands"
    }
]

const LANGUAGES = [
    {
        "code": "en",
        "name": "English"
    },
    {
        "code": "fr",
        "name": "French"
    },
    {
        "code": "de",
        "name": "German"
    },
    {
        "code": "it",
        "name": "Italian"
    },
    {
        "code": "zh",
        "name": "Mandarin"
    },
    {
        "code": "ru",
        "name": "Russian"
    },
    {
        "code": "es",
        "name": "Spanish"
    }
]

const TIMEZONES = [
    {
        "abbr": "DST",
        "isdst": false,
        "offset": -12,
        "text": "(UTC-12:00) International Date Line West"
    },
    {
        "abbr": "U",
        "isdst": false,
        "offset": -11,
        "text": "(UTC-11:00) Coordinated Universal Time-11"
    },
    {
        "abbr": "HST",
        "isdst": false,
        "offset": -10,
        "text": "(UTC-10:00) Hawaii"
    },
    {
        "abbr": "AKDT",
        "isdst": true,
        "offset": -8,
        "text": "(UTC-09:00) Alaska"
    },
    {
        "abbr": "PST",
        "isdst": false,
        "offset": -8,
        "text": "(UTC-08:00) Pacific Standard Time (US & Canada)"
    },
    {
        "abbr": "PDT",
        "isdst": true,
        "offset": -7,
        "text": "(UTC-07:00) Pacific Daylight Time (US & Canada)"
    },
    {
        "abbr": "PDT",
        "isdst": true,
        "offset": -7,
        "text": "(UTC-08:00) Baja California"
    },
    {
        "abbr": "UMST",
        "isdst": false,
        "offset": -7,
        "text": "(UTC-07:00) Arizona"
    },
    {
        "abbr": "CCST",
        "isdst": false,
        "offset": -6,
        "text": "(UTC-06:00) Saskatchewan"
    },
    {
        "abbr": "CAST",
        "isdst": false,
        "offset": -6,
        "text": "(UTC-06:00) Central America"
    },
    {
        "abbr": "MDT",
        "isdst": true,
        "offset": -6,
        "text": "(UTC-07:00) Mountain Time (US & Canada)"
    },
    {
        "abbr": "MDT",
        "isdst": true,
        "offset": -6,
        "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan"
    },
    {
        "abbr": "CDT",
        "isdst": true,
        "offset": -5,
        "text": "(UTC-06:00) Central Time (US & Canada)"
    },
    {
        "abbr": "CDT",
        "isdst": true,
        "offset": -5,
        "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey"
    },
    {
        "abbr": "EST",
        "isdst": false,
        "offset": -5,
        "text": "(UTC-05:00) Eastern Time (US & Canada)"
    },
    {
        "abbr": "SPST",
        "isdst": false,
        "offset": -5,
        "text": "(UTC-05:00) Bogota, Lima, Quito"
    },
    {
        "abbr": "UEDT",
        "isdst": false,
        "offset": -5,
        "text": "(UTC-05:00) Indiana (East)"
    },
    {
        "abbr": "VST",
        "isdst": false,
        "offset": -4.5,
        "text": "(UTC-04:30) Caracas"
    },
    {
        "abbr": "CBST",
        "isdst": false,
        "offset": -4,
        "text": "(UTC-04:00) Cuiaba"
    },
    {
        "abbr": "EDT",
        "isdst": true,
        "offset": -4,
        "text": "(UTC-04:00) Eastern Daylight Time (US & Canada)"
    },
    {
        "abbr": "PSST",
        "isdst": false,
        "offset": -4,
        "text": "(UTC-04:00) Santiago"
    },
    {
        "abbr": "PYT",
        "isdst": false,
        "offset": -4,
        "text": "(UTC-04:00) Asuncion"
    },
    {
        "abbr": "SWST",
        "isdst": false,
        "offset": -4,
        "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
    },
    {
        "abbr": "AST",
        "isdst": false,
        "offset": -3,
        "text": "(UTC-03:00) Buenos Aires"
    },
    {
        "abbr": "ADT",
        "isdst": true,
        "offset": -3,
        "text": "(UTC-04:00) Atlantic Time (Canada)"
    },
    {
        "abbr": "BST",
        "isdst": false,
        "offset": -3,
        "text": "(UTC-03:00) Salvador"
    },
    {
        "abbr": "ESAST",
        "isdst": false,
        "offset": -3,
        "text": "(UTC-03:00) Brasilia"
    },
    {
        "abbr": "GDT",
        "isdst": true,
        "offset": -3,
        "text": "(UTC-03:00) Greenland"
    },
    {
        "abbr": "MST",
        "isdst": false,
        "offset": -3,
        "text": "(UTC-03:00) Montevideo"
    },
    {
        "abbr": "SEST",
        "isdst": false,
        "offset": -3,
        "text": "(UTC-03:00) Cayenne, Fortaleza"
    },
    {
        "abbr": "NDT",
        "isdst": true,
        "offset": -2.5,
        "text": "(UTC-03:30) Newfoundland"
    },
    {
        "abbr": "U",
        "isdst": false,
        "offset": -2,
        "text": "(UTC-02:00) Coordinated Universal Time-02"
    },
    {
        "abbr": "CVST",
        "isdst": false,
        "offset": -1,
        "text": "(UTC-01:00) Cape Verde Is."
    },
    {
        "abbr": "MDT",
        "isdst": true,
        "offset": -1,
        "text": "(UTC-02:00) Mid-Atlantic - Old"
    },
    {
        "abbr": "ADT",
        "isdst": true,
        "offset": 0,
        "text": "(UTC-01:00) Azores"
    },
    {
        "abbr": "GMT",
        "isdst": false,
        "offset": 0,
        "text": "(UTC) Edinburgh, London"
    },
    {
        "abbr": "GST",
        "isdst": false,
        "offset": 0,
        "text": "(UTC) Monrovia, Reykjavik"
    },
    {
        "abbr": "UTC",
        "isdst": false,
        "offset": 0,
        "text": "(UTC) Coordinated Universal Time"
    },
    {
        "abbr": "BST",
        "isdst": true,
        "offset": 1,
        "text": "(UTC+01:00) Edinburgh, London"
    },
    {
        "abbr": "GDT",
        "isdst": true,
        "offset": 1,
        "text": "(UTC) Dublin, Lisbon"
    },
    {
        "abbr": "MDT",
        "isdst": true,
        "offset": 1,
        "text": "(UTC) Casablanca"
    },
    {
        "abbr": "NST",
        "isdst": false,
        "offset": 1,
        "text": "(UTC+01:00) Windhoek"
    },
    {
        "abbr": "WCAST",
        "isdst": false,
        "offset": 1,
        "text": "(UTC+01:00) West Central Africa"
    },
    {
        "abbr": "CEDT",
        "isdst": true,
        "offset": 2,
        "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    },
    {
        "abbr": "CEDT",
        "isdst": true,
        "offset": 2,
        "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
        "abbr": "EST",
        "isdst": false,
        "offset": 2,
        "text": "(UTC+02:00) Cairo"
    },
    {
        "abbr": "LST",
        "isdst": false,
        "offset": 2,
        "text": "(UTC+02:00) Tripoli"
    },
    {
        "abbr": "RDT",
        "isdst": true,
        "offset": 2,
        "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
        "abbr": "SAST",
        "isdst": false,
        "offset": 2,
        "text": "(UTC+02:00) Harare, Pretoria"
    },
    {
        "abbr": "WEDT",
        "isdst": true,
        "offset": 2,
        "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
        "abbr": "AST",
        "isdst": false,
        "offset": 3,
        "text": "(UTC+03:00) Kuwait, Riyadh"
    },
    {
        "abbr": "AST",
        "isdst": false,
        "offset": 3,
        "text": "(UTC+03:00) Baghdad"
    },
    {
        "abbr": "EAST",
        "isdst": false,
        "offset": 3,
        "text": "(UTC+03:00) Nairobi"
    },
    {
        "abbr": "EEDT",
        "isdst": true,
        "offset": 3,
        "text": "(UTC+02:00) E. Europe"
    },
    {
        "abbr": "FDT",
        "isdst": true,
        "offset": 3,
        "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    },
    {
        "abbr": "GDT",
        "isdst": true,
        "offset": 3,
        "text": "(UTC+02:00) Athens, Bucharest"
    },
    {
        "abbr": "JDT",
        "isdst": true,
        "offset": 3,
        "text": "(UTC+02:00) Jerusalem"
    },
    {
        "abbr": "JST",
        "isdst": false,
        "offset": 3,
        "text": "(UTC+03:00) Amman"
    },
    {
        "abbr": "KST",
        "isdst": false,
        "offset": 3,
        "text": "(UTC+02:00) Kaliningrad"
    },
    {
        "abbr": "MEDT",
        "isdst": true,
        "offset": 3,
        "text": "(UTC+02:00) Beirut"
    },
    {
        "abbr": "MSK",
        "isdst": false,
        "offset": 3,
        "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk"
    },
    {
        "abbr": "SDT",
        "isdst": true,
        "offset": 3,
        "text": "(UTC+02:00) Damascus"
    },
    {
        "abbr": "TDT",
        "isdst": false,
        "offset": 3,
        "text": "(UTC+03:00) Istanbul"
    },
    {
        "abbr": "AST",
        "isdst": false,
        "offset": 4,
        "text": "(UTC+04:00) Abu Dhabi, Muscat"
    },
    {
        "abbr": "CST",
        "isdst": false,
        "offset": 4,
        "text": "(UTC+04:00) Yerevan"
    },
    {
        "abbr": "GET",
        "isdst": false,
        "offset": 4,
        "text": "(UTC+04:00) Tbilisi"
    },
    {
        "abbr": "MST",
        "isdst": false,
        "offset": 4,
        "text": "(UTC+04:00) Port Louis"
    },
    {
        "abbr": "SAMT",
        "isdst": false,
        "offset": 4,
        "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov"
    },
    {
        "abbr": "AST",
        "isdst": false,
        "offset": 4.5,
        "text": "(UTC+04:30) Kabul"
    },
    {
        "abbr": "IDT",
        "isdst": true,
        "offset": 4.5,
        "text": "(UTC+03:30) Tehran"
    },
    {
        "abbr": "ADT",
        "isdst": true,
        "offset": 5,
        "text": "(UTC+04:00) Baku"
    },
    {
        "abbr": "PKT",
        "isdst": false,
        "offset": 5,
        "text": "(UTC+05:00) Islamabad, Karachi"
    },
    {
        "abbr": "WAST",
        "isdst": false,
        "offset": 5,
        "text": "(UTC+05:00) Ashgabat, Tashkent"
    },
    {
        "abbr": "YEKT",
        "isdst": false,
        "offset": 5,
        "text": "(UTC+05:00) Yekaterinburg"
    },
    {
        "abbr": "IST",
        "isdst": false,
        "offset": 5.5,
        "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
    },
    {
        "abbr": "SLST",
        "isdst": false,
        "offset": 5.5,
        "text": "(UTC+05:30) Sri Jayawardenepura"
    },
    {
        "abbr": "NST",
        "isdst": false,
        "offset": 5.75,
        "text": "(UTC+05:45) Kathmandu"
    },
    {
        "abbr": "BST",
        "isdst": false,
        "offset": 6,
        "text": "(UTC+06:00) Dhaka"
    },
    {
        "abbr": "CAST",
        "isdst": false,
        "offset": 6,
        "text": "(UTC+06:00) Nur-Sultan (Astana)"
    },
    {
        "abbr": "MST",
        "isdst": false,
        "offset": 6.5,
        "text": "(UTC+06:30) Yangon (Rangoon)"
    },
    {
        "abbr": "NCAST",
        "isdst": false,
        "offset": 7,
        "text": "(UTC+07:00) Novosibirsk"
    },
    {
        "abbr": "SAST",
        "isdst": false,
        "offset": 7,
        "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta"
    },
    {
        "abbr": "CST",
        "isdst": false,
        "offset": 8,
        "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
    },
    {
        "abbr": "NAEST",
        "isdst": false,
        "offset": 8,
        "text": "(UTC+08:00) Irkutsk"
    },
    {
        "abbr": "NAST",
        "isdst": false,
        "offset": 8,
        "text": "(UTC+08:00) Krasnoyarsk"
    },
    {
        "abbr": "MPST",
        "isdst": false,
        "offset": 8,
        "text": "(UTC+08:00) Kuala Lumpur, Singapore"
    },
    {
        "abbr": "TST",
        "isdst": false,
        "offset": 8,
        "text": "(UTC+08:00) Taipei"
    },
    {
        "abbr": "UST",
        "isdst": false,
        "offset": 8,
        "text": "(UTC+08:00) Ulaanbaatar"
    },
    {
        "abbr": "WAST",
        "isdst": false,
        "offset": 8,
        "text": "(UTC+08:00) Perth"
    },
    {
        "abbr": "JST",
        "isdst": false,
        "offset": 9,
        "text": "(UTC+09:00) Osaka, Sapporo, Tokyo"
    },
    {
        "abbr": "KST",
        "isdst": false,
        "offset": 9,
        "text": "(UTC+09:00) Seoul"
    },
    {
        "abbr": "YST",
        "isdst": false,
        "offset": 9,
        "text": "(UTC+09:00) Yakutsk"
    },
    {
        "abbr": "ACST",
        "isdst": false,
        "offset": 9.5,
        "text": "(UTC+09:30) Darwin"
    },
    {
        "abbr": "CAST",
        "isdst": false,
        "offset": 9.5,
        "text": "(UTC+09:30) Adelaide"
    },
    {
        "abbr": "AEST",
        "isdst": false,
        "offset": 10,
        "text": "(UTC+10:00) Canberra, Melbourne, Sydney"
    },
    {
        "abbr": "EAST",
        "isdst": false,
        "offset": 10,
        "text": "(UTC+10:00) Brisbane"
    },
    {
        "abbr": "TST",
        "isdst": false,
        "offset": 10,
        "text": "(UTC+10:00) Hobart"
    },
    {
        "abbr": "WPST",
        "isdst": false,
        "offset": 10,
        "text": "(UTC+10:00) Guam, Port Moresby"
    },
    {
        "abbr": "CPST",
        "isdst": false,
        "offset": 11,
        "text": "(UTC+11:00) Solomon Is., New Caledonia"
    },
    {
        "abbr": "VST",
        "isdst": false,
        "offset": 11,
        "text": "(UTC+11:00) Vladivostok"
    },
    {
        "abbr": "FST",
        "isdst": false,
        "offset": 12,
        "text": "(UTC+12:00) Fiji"
    },
    {
        "abbr": "MST",
        "isdst": false,
        "offset": 12,
        "text": "(UTC+12:00) Magadan"
    },
    {
        "abbr": "NZST",
        "isdst": false,
        "offset": 12,
        "text": "(UTC+12:00) Auckland, Wellington"
    },
    {
        "abbr": "U",
        "isdst": false,
        "offset": 12,
        "text": "(UTC+12:00) Coordinated Universal Time+12"
    },
    {
        "abbr": "KDT",
        "isdst": true,
        "offset": 13,
        "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"
    },
    {
        "abbr": "SST",
        "isdst": false,
        "offset": 13,
        "text": "(UTC+13:00) Samoa"
    },
    {
        "abbr": "TST",
        "isdst": false,
        "offset": 13,
        "text": "(UTC+13:00) Nuku'alofa"
    }
]

export {
    API,
    GENERAL,
    COMPANY,
    LOADING,
    MEDIA_HUB,
    NEWS,
    WHATS_NEW,
    TRACKING,
    SENTRY,
    EVENTS_PAGE,
    USER,
    STICKER,
    IMAGE_TYPES,
    COUNTRIES,
    TIMEZONES,
    LANGUAGES,
    LEARNING_JOURNEY
};

