import {GENERAL} from "../../utils/constants";

const TrackingEvent = {
    APP_LOADED: 'app_loaded',
    PAGE_VIEW: 'page_view',
    CONTENT_VIEW: 'content_view',
    VIDEO_PLAY: 'video_play',
    EXTERNAL_LINK_CLICK: 'external_link_click',
    PDF_DOWNLOAD: 'pdf_download',
    SIDEBAR_CLICK: 'sidebar_click',
    SHARE: 'SHARE',
    SHARE_COPY_LINK: 'SHARE_COPY_LINK',
    SHARE_EMAIL: 'SHARE_EMAIL',
}

const TrackingPageName = {
    APP: 'app',
    LOGIN: 'login',
    REGISTRATION: 'registration',
    REGISTRATION_CONFIRMATION: 'registration_confirmation',
    EMAIL_CONFIRMATION: 'email_confirmation',
    PASSWORD_RESET: 'password_reset',
    GLS_LOBBY: 'gls_lobby',
    LANDING_PAGE: 'landing_page',
    MEDIA_HUB: 'media_hub',
    NEWS: 'news',
    EVENTS: 'events',
    USER_PROFILE: 'user_profile',
    LOBBY: 'lobby'
}

const availableParamsProperties = [
    'environment',
    'source',
    'platform',
    'pageName',
    'indication',
    'mainCategory',
    'subCategory',
    'contentTitle',
    'videoCaption',
    'videoFileName',
    'externalContentTitle',
    'externalContentUrl',
    'sectionId',
    'title',
    'id',
    'path',
    'platformArea'
];

const TrackingObject = (event = null, params = null) => {
    const {
        environment = GENERAL.ENVIRONMENT,
        platform = GENERAL.PLATFORM,
        pageName = null,
        indication = null,
        mainCategory = null,
        subCategory = null,
        contentTitle = null,
        videoCaption = null,
        videoFileName = null,
        externalContentTitle = null,
        externalContentUrl = null,
        sectionId = null,
    } = params;

    const values = { ...params, environment, platform };

    if (event === null || pageName === null) {
        console.warn('TrackingObject: environment, event, platform, pageName are required');
        return null;
    }

    if (event === TrackingEvent.PAGE_VIEW) {
        if (pageName === TrackingPageName.MEDIA_HUB) {
            if (indication === null || mainCategory === null || subCategory === null) {
                console.warn('TrackingObject: indication, mainCategory, subCategory are required to track media hub page view');
                return null;
            }
        }
        if (pageName === TrackingPageName.NEWS) {
            if (mainCategory === null) {
                console.warn('TrackingObject: contentTitle is required to track news page view');
                return null;
            }
        }
    }

    if (event === TrackingEvent.CONTENT_VIEW || event === TrackingEvent.PDF_DOWNLOAD) {
        if (contentTitle === null) {
            console.warn('TrackingObject: contentTitle is required to track content view');
            return null;
        }
    }

    if (event === TrackingEvent.VIDEO_PLAY) {
        if (videoFileName === null && videoCaption === null) {
            console.warn('TrackingObject: videoFileName or videoCaption are required to track video play');
            return null;
        }
    }

    if (event === TrackingEvent.LINK_OPEN) {
        if (externalContentTitle === null || externalContentUrl === null) {
            console.warn('TrackingObject: externalContentTitle, externalContentUrl are required to track link open');
            return null;
        }
    }

    if (event === TrackingEvent.SIDEBAR_CLICK) {
        if (sectionId === null) {
            console.warn('TrackingObject: sectionId are required to track sidebar click');
            return null;
        }
    }

    return {
        event,
        params:
            Object.keys(values)
                .filter(key => availableParamsProperties.includes(key))
                .reduce((obj, key) => {
                    obj[key] = values[key];
                    return obj;
                }, {}),
    }
}

export {TrackingEvent, TrackingPageName, TrackingObject};
