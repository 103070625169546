import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Example using Font Awesome
import { useAppConfig } from '../../../contexts/app-config/app-config-context';

const StyledInput = styled.input`
  background: none;
  width: 100%;
  border: 1px solid #2f2d30;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: #1a181b;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 0;
  padding: 6px 12px;
  line-height: 1.5;
  color: ${(props) => (props.error ? "red" : "#727272")};
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #727272")};
  outline: none;
  box-shadow: none;
  :focus {
    box-shadow: 0 0 2px 2px ${props => props.accentColor ? props.accentColor : 'rgba(0, 155, 200, 0.5)' };
    //border: 1px solid #727272;
    //color: #727272
  }
`;

// Style for the icon
const Icon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

// Updated FormInput component with forwardRef
const FormInput = forwardRef(({ type, error, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const { config } = useAppConfig();
  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Determine the type of input to render
  const inputType = type === 'password' && showPassword ? 'text' : type;

  return (
    <InputContainer>
      <StyledInput
        ref={ref}
        type={inputType}
        error={error}
        accentColor={config?.accent_color}
        {...props}
      />
      {type === 'password' && (
        <Icon onClick={togglePasswordVisibility}>
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </Icon>
      )}
    </InputContainer>
  );
});

export default FormInput;