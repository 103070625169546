import styled from "styled-components";

export const NavBarWrapper = styled.div`
  z-index: 10002;
  padding: 1rem;
  position: fixed;
  display: flex;
  width: 100%;
  height: 3rem;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 0 4px 4px rgba(0,0,0,.1);  
  align-items: center;
`;

export const NavBarHeader = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10003;
`;

export const HamburgerTitle = styled.span`
  font-size: 1rem;
  text-transform: uppercase;
  color: #000;
`;

export const HamburgerButton = styled.button`
  background: none;
  border: none;
  outline: none;
  display: flex;
  flex-flow: row nowrap;
  height: 1rem;
  align-items: center;
  padding: 0;
  cursor: pointer;

  .lines {
    //display: block;
    width: 26px;
    height: 40px;
    margin-left: 0;
    margin-right: 1rem;
    display: inline-block;
    position: relative;
  }

  .lines:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 7px);
    transition: all 0.2s;
  }

  &.is-open .lines:before {
    transform: translateY(7px) scaleX(0);
    transition: all 0.2s;
    opacity: 0;
  }

  .lines:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -9px);
    transition: all 0.2s;
  }
  .lines:before,
  .lines:after {
    content: "";
  }
  .lines:after,
  .lines:before,
  .lines span {
    display: block;
    background-color: #000;
    width: 26px;
    height: 2px;
    position: absolute;
  }
  .lines span {
    width: 20px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: all 0.2s;
  }
  &.is-open .lines span {
    width: 26px;
    right: 50%;
    transform: translate(50%, -50%) rotate(45deg);
    transition: all 0.2s;
  }
  .lines:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -9px);
    transition: all 0.2s;
  }
  &.is-open .lines:after {
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: all 0.2s;
  }
`;

export const NavBarLogoBrand = styled.div`
  height: 100%;
  margin-right: 0;
  margin-left: auto;
`;

export const NavBarUserProfile = styled.div`
  span.profile-link {
    margin-left: 2rem;
    color: #000;
    transition: color 0.3s;
    text-decoration: none;
    align-items: center;
    display: flex;

    i {
      padding-left: 0.75rem;
    }
  }
`;

export const NavBarMenuWrapper = styled.div`
  z-index: 10001;
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  max-width: 30rem;
  left: 0;
  top: 0;
  transform: translateX(-110%);
  transition: transform 0.2s ease-out;
  background-color: hsla(0, 0%, 100%, 0.95);
  border-right: 2px solid #dee2e6;
  box-shadow: 0 0 5px 0 #dee2e6;
  padding: 4rem 1rem 1rem 1rem;
  display: flex;
  flex-flow: column nowrap;
  //justify-content: space-around;
  align-items: center;

  &.is-visible {
    transition: transform 0.2s ease-out;
    transform: translateX(0);
  }
`;

export const NavBarMenu = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  list-style: none;
`;

export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
`;

export const MenuItem = styled.li`
  font-weight: ${props => props?.isSubmenu ? '500' : '300' };
  color: #000;
  font-size: 1.6rem;
  margin: 0 auto ${props => props?.isSubmenu ? '0' : '1.6rem' }  1rem;
  transition: transform 0.3s ease-in;
  transform: translateX(0);
  font-family: Ubuntu,sans-serif;
  text-transform: uppercase;

  > ul > li {
    margin-left: 0rem;
  }

  &:first-child {
    transition: transform 0.3s ease-out 0.05s;
  }

  a:hover {
    text-decoration: underline;
  }

  > a {
    color: #000;
    text-decoration: ${props => props?.isActive ? 'underline' : 'none' };
  }

  div {
    font-weight: 300;
    font-size: 1.6rem;
    color: #999;

    &:after {
      font-family: "Font Awesome Regular", sans-serif;
      content: "\f023";
      color: #999;
      font-size: 1rem;
      position: absolute;
      margin-left: 1rem;
      top: 0.5rem;
    }
  }


`;

export const NavBarFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  margin-top: auto;
  align-items: center;
  margin-bottom: 1rem;
  text-align: center;
`;

