import styled from 'styled-components';

const ColoredDivider = styled.div`
  position: relative;  
  width: 100%;
  height: 14px;
  background: url('${props => props.image}');
  background-size: 100% 100%;
`

export default ColoredDivider
