import React from "react";
import { getMediaPath } from "../../utils/media/media";

const NewsItem = ({ item }) => {
  const { image, title, alt } = item;
  const imageUrl = getMediaPath(item?.cover?.image, "large") || "https://via.placeholder.com/150";
  return (
    <div className="group w-full overflow-hidden">
      {/* Image container with gray background and zoom effect */}
      <div className="relative overflow-hidden rounded-lg bg-gray-100 p-4">
        <img
          src={imageUrl}
          alt={alt || title}
          className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
        />
      </div>

      {/* Title below image */}
      <h3 className="title mt-3 text-lg ">{title}</h3>
    </div>
  );
};

export default NewsItem;
