import {useEffect,} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import GenericBackground from "../../components/generic-background";
import LobbyButton from "../../components/lobby-button";
import {API} from "../../utils/constants";
import {TrackingEvent, TrackingObject, TrackingPageName} from "../../store/tracking/TrackingObject";
import {trackEvent} from "../../store/tracking/actions";
import { useAppConfig } from "../../contexts/app-config/app-config-context";

const Lobby = () => {
    const dispatch = useDispatch();

    const { companies } = useSelector(state => {
        const { company: { all: companies }} = state;
        return { companies };
    })

    useEffect(() => {
        dispatch(trackEvent(
                TrackingObject(TrackingEvent.PAGE_VIEW, {
                    pageName: TrackingPageName.LOBBY,
                })
            )
        );
    }, [dispatch]);

    const { config } = useAppConfig();

    return (
        <GenericBackground>
            <FloatingWindow>
                <Row>
                    <h1>Lobby</h1>
                </Row>
                <Row>
                    { config?.gls_visibility && <LobbyButton slug="/gls/lobby" image="/images/gls/tile.jpg"/> }
                    {
                        companies.map((company, index) => {
                            const { config: { slug, lobbyImage } } = company;
                            return (
                                <LobbyButton key={index} slug={slug} image={`${API.URL}${lobbyImage}`}/>
                            )
                        })
                    }
                </Row>
                <Row>
                    <p></p>
                    <Logo src={config?.main_logo} alt="logo"/>
                </Row>
            </FloatingWindow>
        </GenericBackground>
    )
}

const Logo = styled.img`
    display: flex;
    width: 100%;
    max-width: 12.5rem;
`;

const FloatingWindow = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem 1rem;
  margin: 2rem;
  box-shadow: 0 0 25.55px 9.45px rgba(0,0,0,.29);
  ${breakpoint('md')` // 24
    max-width: 64rem;
    margin: 1rem;
    width: 90%;
  `}  
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  margin: 0.5rem;
  h1 {
    font-size: 3rem;
    line-height: 3rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #333;
    margin: 1rem 0;
  }
  ${breakpoint('sm')` // 24
      gap: 1rem;
      margin: 0.5rem 1rem;
      flex-direction: row;
  `}
  ${breakpoint('md')` // 24
      margin: 0.5rem 1rem;
      flex-direction: row;
  `}
`

export default Lobby;
