import {Link} from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import React from "react";

const TopLogo = ({src, onImageLoad, marginTop}) => {

  // Handle the image load event
  const handleImageLoad = (event) => {
    if (typeof onImageLoad === "function") {
      onImageLoad(event.target.clientWidth);
    }
  };

  return (
      <Container to=".." margintop={marginTop}>
          <Image src={src} alt="logo" onLoad={handleImageLoad}/>
      </Container>
  )
}

const Image = styled.img`
    width: 100%;
`;

const Container = styled(Link)`
  position: absolute;
  display: flex;
  width: 160px;
  left: 0;
  margin-top: ${props => props.margintop || 0};
  top: 2rem;
  ${breakpoint('sm')`
    top: 2rem;
  `}
/*
  ${breakpoint('lg')`
    width: 160px;
    top: 2rem;
  `}
*/
`;

export default TopLogo;
