import { API } from "../utils/constants";
import axios from "axios";
export const getJwtToken = () => {
    try {
      const glsLive = JSON.parse(localStorage.getItem("gls-live"));
      return glsLive?.jwt;
    } catch (e) {
      return null;
    }
  };

  
export const baseService = (options) => {
    const token = getJwtToken();
    const {url, query} = options;
    const headers = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return axios.get(`${API.URL}${url}?${query}`, headers);
}

