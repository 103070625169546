// import "instantsearch.css/themes/algolia-min.css";
import React from "react";
import useSearchEngine from "../../utils/hooks/use-search/use-search";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { SearchResultWrapper } from "./SearchResult.style";
import Layout from "../../components/content-area";
import { IMAGE_TYPES } from "../../utils/constants";
import { get } from "lodash";
import ResultGrid from "./result-grid";
import BackButton from "../../components/back-button";
import { SearchForm } from "../../components/search-form";

const serializeResults = (results) => {
  const items = [];
  results.map((index) => {
    const { hits } = index;
    const newHits = hits.map((hit) => {
      return {
        ...hit,
        index: index.indexUid,
        image: {
          thumbnail: get(hit, `image.formats.${IMAGE_TYPES.THUMBNAIL}.url`),
        },
      };
    });
    items.push(...newHits);
  });
  return items;
};

const SearchResult = () => {
  const [params] = useSearchParams();
  const { companyName } = useParams();
  const query = params.get("q");
  const navigation = useNavigate();
  const { results } = useSearchEngine(query, companyName);
  return (
    <Layout>
      <BackButton onClick={() => navigation(-1)} />
      <SearchForm />
      <SearchResultWrapper>
        <ResultGrid
          companyName={companyName}
          items={serializeResults(results)}
          isSearch
          primaryColor="#f0f0f0"
          borderRadius={10}
        />
      </SearchResultWrapper>
    </Layout>
  );
};

export default SearchResult;
