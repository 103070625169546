import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import breakpoint, {map} from 'styled-components-breakpoint';

import { BlocksRenderer } from "@strapi/blocks-react-renderer";

import Form from "../../components/form";
import FormAnnotation from "../../components/form-annotation";
import FormNotice from "../../components/form-notice/FormNotice";
import FormButton from "../../components/gls/form/FormButton";
import FormErrorMessage from "../../components/form-error-message";
import FormInput from "../../components/gls/form/FormInput";

import FormPanel from "../../container/form-panel";
import { loginUser, resetLoginUser } from "../../store/user/actions";

import { loginSchema } from "../../utils/form-schemata";
import FormInputGroup from "../../components/gls/form/FormInputGroup";
import LoginRemember from "../../components/gls/form/LoginRemember";
import { ClipLoader } from "react-spinners";
import { trackEvent } from "../../store/tracking/actions";
import {
  TrackingEvent,
  TrackingObject,
  TrackingPageName,
} from "../../store/tracking/TrackingObject";
import { setPlatformArea } from "../../store/company/actions/CompanyActions";
import { useAppConfig } from "../../contexts/app-config/app-config-context";

const addMailtoLinks = (blocks) => {
  return blocks.map((block) => {
    if (block.type === "paragraph" && block.children) {
      block.children = block.children.map((child) => {
        if (child.type === "text" && validateEmail(child.text)) {
          return {
            ...child,
            type: "link",
            url: `mailto:${child.text}`,
            children: [{ text: child.text, type: 'text' }],
          };
        }
        return child;
      });
    }
    return block;
  });
};

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { error, loading } = useSelector((state) => {
    const {
      user: {
        login: { error, loading },
      },
    } = state;
    return { error, loading };
  });

  useEffect(() => {
    dispatch(setPlatformArea("login"));
  }, []);

  useEffect(() => {
    dispatch(resetLoginUser);
    dispatch(
      trackEvent(
        TrackingObject(TrackingEvent.PAGE_VIEW, {
          pageName: TrackingPageName.LOGIN,
        })
      )
    );
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data) => {
    if (data.rememberMe) {
      localStorage.setItem("username", data.username);
    } else {
      localStorage.removeItem("username");
    }
    dispatch(loginUser(data));
  };

  const savedUsername = localStorage.getItem("username") || "";

  const handleRegisterClick = () => {
    navigate("/register");
  };

  const handleForgotPasswordClick = () => {
    navigate("/forgot-password");
  };

  const { config } = useAppConfig();

  const transformedFooter = addMailtoLinks(config?.auth_panel_footer || []);
  const transformedHeader = addMailtoLinks(config?.auth_panel_header || []);

  return (
    <FormPanel>
      <FormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TemporaryNotice>
            <BlocksRenderer content={transformedHeader} />
          </TemporaryNotice>
          <FormInputGroup>
            <FormInput
              type="text"
              defaultValue={savedUsername}
              {...register("email", { required: true })}
              placeholder="EMAIL"
              error={errors.email}
            />
          </FormInputGroup>
          <FormErrorMessage message={errors.email} />
          <FormInputGroup>
            <FormInput
              type="password"
              {...register("password", { required: true })}
              placeholder="PASSWORD"
              error={errors.password}
            />
            <FormErrorMessage message={errors.password} />
            <FormErrorMessage message={error} />
          </FormInputGroup>
          {error ? (
            <p style={{ width: "100%" }}>
              Forgot your password?{" "}
              <a href="#" onClick={handleForgotPasswordClick}>
                Click here
              </a>
            </p>
          ) : null}
          <FormButton type="submit" primary loading={loading} title="Sign In" />
          <RegisterButton>
            <FormButton
              type="reset"
              onClick={handleRegisterClick}
              title="REGISTER"
            />
          </RegisterButton>
          <FormNotice>
            <BlocksRenderer content={transformedFooter} />
          </FormNotice>
        </Form>
      </FormWrapper>
    </FormPanel>
  );
};

const RegisterButton = styled.div`
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 3rem;
`;

const TemporaryNotice = styled.div`
  margin-bottom: 1rem;
`;

const FormWrapper = styled.div`
  ${breakpoint('md')`
    max-width: 30em;
  `}
  ${breakpoint('xs')`
    width: 100%
  `}
`;

export default Login;
