import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { uploadUserAvatar as uploadUserAvatarAction } from "../../store/user/actions/UserActions";
import { MoonLoader } from "react-spinners";
import { getMediaPath } from "../../utils/media/media";

export const ProfileAvatar = ({ image }) => {
  const [previewImage, setPreviewImage] = useState(
    getMediaPath(image, 'thumbnail')
  );
  const { uploadUserAvatar } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // Handle file selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        dispatch(uploadUserAvatarAction(file));
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <ProfileAvatarWrapper>
      <Avatar>
        {uploadUserAvatar?.isUploading && (
          <AvatarUploading>
            <MoonLoader loading={true} size={32} color="#fff" />
          </AvatarUploading>
        )}
        <img src={previewImage} className="img-cir" />
      </Avatar>
      <input
        type="file"
        id="upload"
        hidden
        disabled={uploadUserAvatar?.isUploading}
        accept="image/png, image/jpeg"
        onChange={handleImageChange}
      />
      <UploadButton disabled={uploadUserAvatar?.isUploading} htmlFor="upload">Upload Avatar</UploadButton>
    </ProfileAvatarWrapper>
  );
};

const ProfileAvatarWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const AvatarUploading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  z-index: 1;
`;

const Avatar = styled.div`
  position: relative;
  display: flex;
  z-index: 0;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #008478;
  margin-bottom: 1rem;
  .img-cir {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UploadButton = styled.label`
  background-color: #000;
  border: none;
  cursor: pointer;
  color: #fff;
  align-items: center;
  padding: 0.5rem 1rem;
  justify-content: center;
  margin-top: 1rem;
`;
