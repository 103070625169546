import axios from "axios";
import {takeEvery} from 'redux-saga/effects';
import {API, GENERAL, LEARNING_JOURNEY} from "../../../utils/constants";
import {call, put, select} from 'redux-saga/effects';
import {startLoading, stopLoading} from "../../loading/actions";
import {logout} from "../../user/actions";

axios.defaults.headers.common['accept'] = `application/json`;

/**
 * Loads data from an API endpoint and dispatches success or error actions.
 * @param {Object} action - The action object containing payload data.
 * @param {Function} pathFunction - The function that generates the API endpoint path based on the payload.
 * @param {string} successActionType - The type of action to dispatch upon successful data loading.
 * @return {Generator} - A generator object for handling asynchronous flow.
 */
function* loadData(action, pathFunction, successActionType) {
    const {payload} = action;
    console.log("🚀 ~ function*loadData ~ payload:", payload)
    try {
        yield put(startLoading());
        const {jwt} = yield select(state => state.user);
        const header = {headers: {Authorization: `Bearer ${jwt}`}, baseURL: API.URL};
        const response = yield call(axios, `${pathFunction(payload, GENERAL.IS_PREVIEW)}`, header);
        const {data} = response;
        yield put({type: successActionType, payload: data});
        yield put(stopLoading());
    } catch (e) {
        console.log("🚀 ~ function*loadData ~ e:", e)
        const {response: {status}} = e;
        // Bearer failed to authenticate
        if (status === 403) {
            yield put(logout());
        }
        yield put({type: LEARNING_JOURNEY.LOAD_NAV_ERROR});
        yield put(stopLoading(e));
    }
}
/**
 * Loads navigation data for media hub.
 *
 * @param {Object} action - The action object containing necessary information.
 * @returns {Object} - The result object after loading navigation data.
 */
function* loadNav(action) {
    console.log("🚀 ~ function*loadNav ~ loadNav:", action)
    yield* loadData(action, API.LEARNING_JOURNEY_NAV_PATH, LEARNING_JOURNEY.LOAD_NAV_SUCCESS);
}

/**
 * Initializes watchers for media hub actions.
 *
 * @returns {IterableIterator<any>} A generator object that yields the newly created takeEvery watchers.
 */
function* initWatchers() {
    yield takeEvery(LEARNING_JOURNEY.LOAD_NAV, loadNav);
}

export default initWatchers;
