import * as Yup from "yup";

const emailRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

const errorMessages = {
    enterFirstname: "Please enter your firstname",
    enterLastname: "Please enter your lastname",
    enterCountry: "Please select your country",
    enterCompany: "Please select your company",
    enterJobTitle: "Please select your job title",
    enterTherapeuticArea: "Please select your therapeutic area",
    enterAreaSubCategories: "Please select your therapeutic area subcategory",
    enterFunction: "Please select your function",
    enterLanguage: "Please select your language preference",
    enterTimezone: "Please select your timezone",
    enterEmail: "Please enter your email address",
    enterValidEmail: "Please enter a valid email address",
    passwordResetNotAllowed: "You are not allowed to reset your password",
    enterCurrentPassword: "Please enter your current password",
    enterPassword: "Please enter your password",
    passwordComplexity:
        "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character",
    confirmPassword: "Please confirm your password",
    passwordsMismatch: "Passwords must match",
};

/**
 * Creates a schema for data validation using Yup.
 *
 * @param {Object} fields - The fields to be included in the schema.
 * @returns {Object} - The created schema object.
 */
const createSchema = (fields) => {
    let shape = {};
    for (let field in fields) {
        shape[field] = Yup.string().required(errorMessages[fields[field]]);
        if (field === "area_subcategories") shape[field] = Yup.array().of(Yup.number()).min(1, errorMessages[fields[field]]);
        if (field === "email") shape[field] = shape[field].email(errorMessages.enterValidEmail);
        if (field === "password") shape[field] = shape[field].matches(emailRegex, errorMessages.passwordComplexity)
        if (field === "confirm_password" || field === "passwordConfirmation") shape[field] = shape[field].test("passwords-match", errorMessages.passwordsMismatch, function (value) { return this.parent.password === value; })
    }
    return Yup.object().shape(shape).required();
}

/**
 * Represents the login schema for validating user login input.
 * @typedef {Object} loginSchema
 * @property {string} email - The email field for user's email address.
 * @property {string} password - The password field for user's password.
 */
const loginSchema = createSchema({
    email: "enterEmail",
    password: "enterPassword",
});

/**
 * Creates a schema for registering a user with the following properties:
 *
 * @param {Object} properties - The properties required for user registration.
 * @param {string} properties.firstname - The label or placeholder for the firstname input field.
 * @param {string} properties.lastname - The label or placeholder for the lastname input field.
 * @param {string} properties.email - The label or placeholder for the email input field.
 * @param {string} properties.password - The label or placeholder for the password input field.
 * @param {string} properties.confirm_password - The label or placeholder for the confirm password input field.
 * @param {string} properties.country_code - The label or placeholder for the country code input field.
 * @param {string} properties.user_company - The label or placeholder for the user company input field.
 * @param {string} properties.job_title - The label or placeholder for the job title input field.
 * @param {string} properties.therapeutic_areas - The label or placeholder for the therapeutic areas input field.
 * @param {string} properties.user_function - The label or placeholder for the user function input field.
 * @param {string} properties.language - The label or placeholder for the language input field.
 * @param {string} properties.timezone_offset - The label or placeholder for the timezone offset input field.
 *
 * @returns {Object} - The schema object for user registration.
 */
const registerSchema = createSchema({
    firstname: 'enterFirstname',
    lastname: 'enterLastname',
    email: 'enterEmail',
    password: 'enterPassword',
    confirm_password: 'confirmPassword',
    country_code: 'enterCountry',
    user_company: 'enterCompany',
    job_title: 'enterJobTitle',
    therapeutic_area: 'enterTherapeuticArea',
    area_subcategories: 'enterAreaSubCategories',
    user_function: 'enterFunction',
    language: 'enterLanguage',
    timezone_offset: 'enterTimezone',
});

/**
 * Password Recovery Schema.
 *
 * @typedef {Object} PasswordRecoverySchema
 * @property {string} email - The user's email address.
 */
const passwordRecoverySchema = createSchema({
    email: 'enterEmail',
});

/**
 * Creates a password reset schema.
 *
 * @param {Object} config - The configuration for the password reset schema.
 * @param {string} config.code - The code used to indicate that password reset is not allowed.
 * @param {string} config.password - The text prompt for entering a new password.
 * @param {string} config.passwordConfirmation - The text prompt for confirming the new password.
 *
 * @returns {Object} - The password reset schema object.
 */
const passwordResetSchema = createSchema({
    code: 'passwordResetNotAllowed',
    password: 'enterPassword',
    passwordConfirmation: 'confirmPassword'
});

/**
 * Creates a password update schema for validating form inputs.
 *
 * @param {Object} input - The input object specifying field names.
 * @param {string} input.currentPassword - The field name for the current password.
 * @param {string} input.password - The field name for the new password.
 * @param {string} input.passwordConfirmation - The field name for confirming the password.
 *
 * @returns {Object} - The password update schema object.
 */
const updatePasswordSchema = createSchema({
    currentPassword: 'enterCurrentPassword',
    password: 'enterPassword',
    passwordConfirmation: 'confirmPassword'
});


export {
    loginSchema,
    registerSchema,
    passwordRecoverySchema,
    passwordResetSchema,
    updatePasswordSchema
};
