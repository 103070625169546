import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styled from "styled-components";
import useWindowDimensions from "../../utils/hooks/use-window-dimensions";
import SquareSpinner from "../square-spinner";

const PDF_RATIO_2PAG = 400/565;
const PDF_RATIO_1PAG = 1414/1000;

const PDF = ({url, primaryColor, doublePage}) => {
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [pageRendered, setPageRendered] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState(null);
    const {width, height} = useWindowDimensions();
    const pdfRef = useRef();

    useEffect(() => {
        const downloadPDF = async () => {
            pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
            try {
                const response = await axios({url, method: 'GET', responseType: 'blob'});
                const {data} = response;
                setData(data);
            } catch (err) {
                console.log(err);
            }
        };
        downloadPDF();
    }, [url]);

    const onDocumentLoaded = ({numPages}) => {
        setDocumentLoaded(true);
        setNumPages(numPages);
    };

    const onRenderedSuccess = () => {
        setPageRendered(true);
    };

    const next = () => {
        const step = doublePage ? 2 : 1;
        if (pageNumber < numPages) {
            setPageRendered(false);
            setPageNumber(Math.min(pageNumber + step, numPages));
        }
    };

    const prev = () => {
        const step = doublePage ? 2 : 1;
        if (pageNumber > 1) {
            setPageRendered(false);
            setPageNumber(Math.max(pageNumber - step, 1));
        }
    };

    const openLinkInNewTab = (e) => {
        e.preventDefault();
        if (e.target.tagName.toLowerCase() === 'a') {
            window.open(e.target.href);
        }
    };

    const containerWidth = doublePage ? Math.min(width - 40, 800) : Math.min(width - 40, 500);
    const actualHeight = doublePage ? containerWidth * PDF_RATIO_2PAG : containerWidth * PDF_RATIO_1PAG;

    return (
        <Viewer ref={pdfRef} height={actualHeight}>
            <Container onClick={openLinkInNewTab} height={actualHeight} width={containerWidth}>
                <PDFDocument
                    height={actualHeight}
                    hidden={!documentLoaded || !pageRendered}
                    file={data}
                    onLoadSuccess={onDocumentLoaded}
                    width={width}
                    loading=""
                    options={{
                        cMapUrl: 'cmaps/',
                        cMapPacked: false,
                    }}
                    renderMode="canvas"
                >
                    <PageWrapper>
                        <Page
                            pageNumber={pageNumber}
                            canvasBackground={'white'}
                            height={actualHeight}
                            width={doublePage ? containerWidth/2 : containerWidth}
                            onRenderSuccess={onRenderedSuccess}
                            renderTextLayer={false}
                            renderAnnotationLayer={true}
                        />
                        {doublePage && pageNumber + 1 <= numPages && (
                            <Page
                                pageNumber={pageNumber + 1}
                                width={doublePage ? containerWidth/2 : containerWidth}
                                canvasBackground={'white'}
                                height={actualHeight}
                                onRenderSuccess={onRenderedSuccess}
                                renderTextLayer={false}
                                renderAnnotationLayer={true}
                            />
                        )}
                    </PageWrapper>
                </PDFDocument>
                <Toolbar hidden={!documentLoaded}>
                    <Button className="fa fa-chevron-left" onClick={prev} color={primaryColor}></Button>
                    <PageNumber color={primaryColor}>{pageNumber}/{numPages}</PageNumber>
                    <Button className="fa fa-chevron-right" onClick={next} color={primaryColor}></Button>
                </Toolbar>
                <Spinner hidden={!(!documentLoaded || !pageRendered)} color={primaryColor}>
                    Loading Page
                </Spinner>
            </Container>
        </Viewer>
    );
}

const Viewer = styled.div`
  height: calc(${props => props.height}px + 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  margin-top: 2rem;
  width: 100%;
`;

const Container = styled.div`
  height: calc(${props => props.height}px + 40px);
  width: ${props => props.width}px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  overflow-x: hidden;
`;

const PDFDocument = styled(({...props}) => (
    <Document {...props} />
))`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(${props => props.height}px + 40px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: ${props => props.hidden ? 0 : 1};
  transition: ${props => props.hidden ? "opacity 0s" : "opacity 0.5s"};
  width: ${props => props.width}
  canvas {
    background-color: white;
  }
`;

const PageNumber = styled.p`
  color: ${props => props.color};
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.0rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0.25rem;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  opacity: ${props => props.hidden ? 0 : 1};
  transition: opacity 1s;
`;

const Button = styled.button`
  background-color: ${props => props.color};
  color: white;
  padding: 0.3rem 0.5rem;
  margin: 0;
  display: inline-block;
  border: 0;
`;

const Spinner = styled(SquareSpinner)`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 0.9rem;
  font-family: "AlternateGotNo2D", sans-serif;
  opacity: ${props => props.hidden ? 0 : 1};
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
  max-width: 100%;
  overflow-x: hidden;
`;

export default PDF;
