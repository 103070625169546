import styled from "styled-components";

const FormInputGroup = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: ${props => props.alignItems || "stretch"};
  justify-content: ${props => props.justifyContent || "flex-start"};
  margin-bottom: 1rem;
`;
export default FormInputGroup;
