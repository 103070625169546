import styled from "styled-components";

const FormOption = styled.option`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
`;

export default FormOption;
