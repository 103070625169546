import styled from "styled-components";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  background-color: #ffffff99;
  display: flex;
  justify-content: center;
  align-items: center;
  //align-items: ${props => props.centered ? 'center' : 'unset'};
  overflow: hidden;
  z-index: 2;
`;

export default Backdrop;
