import styled from "styled-components";

const FormButton = styled.input`
  display: flex;
  width: 100%;
  border: none;
  padding: 0.7rem 0.5rem 0.4rem 0.5rem;
  align-items: center;
  justify-content: center;
  font-family: 'AlternateGotNo2D', sans-serif;
  background-color: ${props => props.primary ? 'rgb(0, 155, 119)' : '#727272'};
  cursor: pointer;
  color: white;
  font-size: 1.1rem;
  font-weight: 400;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default FormButton;
