import { useState } from "react";
import styled from "styled-components";
import "./LobbyGLS.css";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import MediaPlayer from "../../components/media-hub/media-player";
import { getMediaId } from "../../utils/media/media";

const GLS_TILE_TITLE = "GLS24 Live Highlights";
const GLS_TILE_URL =
  "https://playout.3qsdn.com/41017ab1-c090-45d2-b344-f252b9fb9c3a";

const LobbyGLS = () => {
  const [currentMedia, setCurrentMedia] = useState(null);
  return (
<LobbyGridLayout>
<LobbyItemGroup>
      <LobbyItem
        to="#"
        onClick={() => setCurrentMedia({
          title: GLS_TILE_TITLE,
          url: GLS_TILE_URL,
          id: 1,
        })}
        className="lobby-item item-1-by-1 lobby-bg-bluegreen"
        id="lobby-item-3"
      >
        <img
          src="/images/gls/home/3_GLS24_PF_home tile.jpg"
          className="lobby-arrow hidden img-fluid"
        />
      </LobbyItem>
      <LobbyItem
        to="/gls/in-a-box"
        className="lobby-item item-1-by-1 lobby-bg-lightgreen"
        id="lobby-item-4"
      >
        <img
          src="/images/gls/home/6_GLS24_PF_home tile_2.jpg"
          className="lobby-arrow img-fluid"
        />
        {/* <LinkArrow src="/images/gls/arrow.png" /> */}
      </LobbyItem>
      </LobbyItemGroup>
      <LobbyItemGroup>
      <LobbyItem
        to="/gls/mediahub"
        className="lobby-item item-1-by-1 lobby-bg-green"
        id="lobby-item-5"
      >
        <img
          src="/images/gls/home/5_GLS24_PF_home tile.jpg"
          className="lobby-arrow hidden img-fluid"
        />
        {/* <LinkArrow src="/images/gls/arrow.png" /> */}
      </LobbyItem>
      <LobbyItem
        to="/gls/dutube"
        className="lobby-item item-1-by-1 lobby-bg-green"
        id="lobby-item-6"
      >
        <img
          src="/images/gls/home/4_GLS24_PF_home tile.jpg"
          className="lobby-arrow hidden img-fluid"
        />
        {/* <LinkArrow src="/images/gls/arrow.png" /> */}
      </LobbyItem>
      </LobbyItemGroup>
      <Modal
        footer={null}
        isOpen={!!currentMedia?.id}
        title={currentMedia?.title}
        closeModal={() => setCurrentMedia(null)}
      >
        <MediaPlayer mediaId={getMediaId(currentMedia?.url)} />
      </Modal>
    </LobbyGridLayout>
  );
};

const LobbyGridLayout = styled.div`
  flex: 1;
  gap: 0.8rem;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

const LobbyItemGroup = styled.div`
  display: flex;
  gap: 0.8rem;
  flex: 0.5;
  @media (max-width: 768px) {
    flex-direction: row;
    margin: 0.25rem 0;
  }
  max-width: 50vh;
`;

const LinkArrow = styled.img`
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translate(-50%, -50%);
  width: 4.5rem;
`;

const LobbyItem = styled(Link)`
  position: relative;
  cursor: ${(props) => (props.to ? "cursor" : "default")};
  max-width: 
  @media (max-width: 768px) {
    flex: 0.5;
  }
`;

export default LobbyGLS;
