import {SquareLoader} from "react-spinners";

const SquareSpinner = ({className, children, color}) => {
    return (
        <div className={className}>
            <SquareLoader loading={true} size={32} color={color} speedMultiplier={2}/>
            <p>{children}</p>
        </div>
    )
}

export default SquareSpinner;
