import {useRef, useState} from "react";
import styled from "styled-components";
import useImageSelector from "../../utils/hooks/use-image-selector";
import useRefDimensions from "../../utils/hooks/use-ref-dimensions";
import SquareSpinner from "../square-spinner";

const LoadableImage = ({aspectRatio, formats, baseUrl, style, onClick = null, maxHeight = null, color = '#000000'}) => {
    const ref = useRef(null);
    const dimensions = useRefDimensions(ref);

    const {width, height, url} = useImageSelector(formats, dimensions, aspectRatio, maxHeight);

    const [loaded, setLoaded] = useState(false);

    return (
        <StyledLoadableImage
            ref={ref}
            style={{...style, height: height}}
            onClick={onClick}
            width={width}
            maxHeight={height}>
            {
                !loaded ?
                    <SquareSpinner size={50} color={color}/> : null
            }
            <div>
                <img
                    src={`${baseUrl}${url}`}
                    alt="placeholder"
                    loading="lazy"
                    onLoad={() => setLoaded(true)}
                    className={loaded ? 'loaded' : ''}
                    style={{border: 0, padding: 0, margin: 0}}
                />
            </div>
        </StyledLoadableImage>
    );
}

const StyledLoadableImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //overflow: hidden;

  div {
    overflow: hidden;
    aspect-ratio: ${props => props.aspectRatio};
    max-width: ${props => props.width}px;
    max-height: ${props => props.maxHeight}px;

    img {
      opacity: 0;
      max-width: 100%;
      max-height: ${props => props.maxHeight}px;
      transition: opacity 0.3s ease-in-out;

      &.loaded {
        opacity: 1;
      }
    }
  }
`;


export default LoadableImage;
