import React, { Fragment } from "react";
import styled from "styled-components";

export const InlineEditButtons = ({ isEditing, onEdit, onSave, onCancel }) => {
  return (
    <ActionButtons>
      {isEditing ? (
        <Fragment>
          <FormButton onClick={onSave}>
            SAVE <Icon className={"fa fa-pencil"} color={"#fff"} />
          </FormButton>
          <FormButton onClick={onCancel} color="#DC3545">
            CANCEL <Icon className={"fa fa-times"} color={"#fff"} />
          </FormButton>
        </Fragment>
      ) : (
        <EditButton onClick={onEdit}>
          <Icon className={"fa fa-pencil"} color={"#A09DA9"} />
          Edit
        </EditButton>
      )}
    </ActionButtons>
  );
};

const ActionButtons = styled.form`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: row;
`;
const FormButton = styled.button`
  background-color: ${(props) => props.color || "#008478"};
  border: none;
  cursor: pointer;
  color: #fff;
  align-items: center;
  margin: 0 0.25rem;
  padding: 0rem 0.5rem;
  justify-content: center;
`;
const EditButton = styled.button`
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #A09DA9;
  align-items: center;
`;

const Icon = styled.i`
  background-color: transparent;
  color: ${(props) => props.color};
  padding: 0.5rem 0.25rem;
  font-size: 13px;
`;
