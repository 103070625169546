import React, { useEffect, useState } from 'react';
import { SquareLoader } from "react-spinners";

export const MediaPlayer = ({ mediaId, playerToken, playerTimestamp }) => {
  const [isLoading, setIsLoading] = useState(true); // Loader visibility state

  useEffect(() => {
    const scriptId = 'js-3q-sdn-script';
    if (document.getElementById(scriptId)) {
      initializePlayer();
      return;
    }
    
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = 'https://player.3qsdn.com/js3q.latest.js';
    script.onload = initializePlayer;
    document.head.appendChild(script);

    function initializePlayer() {
      if (window.js3q) {
        const js3qVideoPlayer = new window.js3q({
          dataid: mediaId,
          container: 'player1',
          key: playerToken,
          timestamp: playerTimestamp,
        });

        // Listen for the media.ready event to hide the loader
        js3qVideoPlayer.on('*.*', function () {
          setIsLoading(false); // Hide the loader
        });
      }
    }

    return () => {
      const script = document.getElementById(scriptId);
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [mediaId, playerToken, playerTimestamp]);

  // Styles for the loader container
  const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%', // This makes the loader container fill the viewport width
  };

  return (
    <>
    {isLoading && <div style={loaderContainerStyle}>
      <SquareLoader loading={true} size={32} color="#796e65" speedMultiplier={2}/>
    </div>}
    <div id="player1" style={{visibility: isLoading ? 'hidden' : 'visible'}}></div>
    </>
  );
};
