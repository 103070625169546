import axios from "axios";
import {call, put, takeEvery, select} from 'redux-saga/effects';
import {API, GENERAL, NEWS} from "../../../utils/constants";
import {startLoading, stopLoading} from "../../loading/actions";
import {logout} from "../../user/actions";

axios.defaults.headers.common['accept'] = `application/json`;

function* loadTopics(action) {
    const {payload: companyId} = action;
    try {
        yield put(startLoading());
        const { config: {id}} = yield select(state => state.company);
        const {jwt} = yield select(state => state.user);
        const header = {headers: {Authorization: `Bearer ${jwt}`}};
        const topics = yield call(axios, `${API.URL}${API.NEWS_NAV_PATH(companyId, GENERAL.IS_PREVIEW)}`, header);
        const {data} = topics;
        yield put({type: NEWS.LOAD_NAV_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        const {response: {status}} = e;
        // Bearer failed to authenticate
        if(status === 403) {
            yield put(logout());
        }
        yield put({type: NEWS.LOAD_NAV_ERROR});
        yield put(stopLoading(e));
    }
}

function* loadContent(action) {
    const {payload: newsItemId} = action;
    try {
        yield put(startLoading());
        const {jwt} = yield select(state => state.user);
        const header = {headers: {Authorization: `Bearer ${jwt}`}};
        const topics = yield call(axios, `${API.URL}${API.NEWS_ITEM_PATH(newsItemId, GENERAL.IS_PREVIEW)}`, header);
        const {data} = topics;
        console.log("🚀 ~ function*loadContent ~ topics:", topics)
        yield put({type: NEWS.LOAD_CONTENT_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        console.log("🚀 ~ function*loadContent ~ e:", e)
        const {response: {status}} = e;
        // Bearer failed to authenticate
        if(status === 403) {
            yield put(logout());
        }
        yield put({type: NEWS.LOAD_CONTENT_ERROR});
        yield put(stopLoading(e));
    }
}

function* initWatchers() {
    yield takeEvery(NEWS.LOAD_NAV, loadTopics);
    yield takeEvery(NEWS.LOAD_CONTENT, loadContent);
}

export default initWatchers;
