import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const Panel = ({header, children, footer, opaque}) => {
    return (
        <Wrapper opaque={opaque}>
            {
                header &&
                    <Header>
                        {header}
                    </Header>
            }
            <Content>
                {children}
            </Content>
            {
                footer &&
                    <Footer>
                        {footer}
                    </Footer>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  max-width: 40.375rem;
  overflow: hidden;
  background-color: ${props => props.opaque ? 'white' : 'rgba(255, 255, 255, 0.9)'};
  box-shadow: 0 0 25.55px 9.45px rgba(0, 0, 0, .29);
  ${breakpoint('sm')`
    margin: 2rem;
  `}
`;

const Header = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 0.25rem 0.5rem;
`;

const Footer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 0 25.55px 9.45px rgba(0, 0, 0, .15);
  
  padding: 0 0.75rem;
`;

const Content = styled.div`
  display: flex;
  position: relative;
  overflow: scroll;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  gap: 0.5rem;
  ${breakpoint('sm')`
    padding: 2rem 3rem;
  `}
`;

export default Panel;
