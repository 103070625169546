import styled from "styled-components";

const ImageContainer = styled.div`
  aspect-ratio: 4 / 3;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  background-color: #eee;
  padding: ${props => props.selected ? "0.5rem" : "1rem"};
  transition: 0.125s linear;
  border-radius: ${props => props.borderRadius || "0"};
`;

export default ImageContainer;
