import {useRef, useState} from "react";
import styled from "styled-components";
import useOnClickOutside from "../../utils/hooks/use-on-click-outside";
import {TrackingEvent} from "../../store/tracking/TrackingObject";

const ShareDialog = ({path, color, title, onShareSuccess}) => {
    const ref = useRef();
    useOnClickOutside(ref, () => setOpen(false));

    const [open, setOpen] = useState(false);
    const [label, setLabel] = useState("Copy Link");

    const toggleDialog = () => {
        if (!open) {
          onShareSuccess(TrackingEvent.SHARE, { title, path });
          setLabel("Copy Link");
        }
        setOpen(!open);
    }

    const copyLink = () => {
        setLabel("Copied to clipboard");
        onShareSuccess(TrackingEvent.SHARE_COPY_LINK, { title, path });
        navigator.clipboard.writeText(path);
        setTimeout(() => {
            setOpen(false);
        }, 500);
    }

    const mailLink = () => {
        onShareSuccess(TrackingEvent.SHARE_EMAIL, { title, path });
        window.location.href = `mailto:?subject=${title}&body=${path}`
        setOpen(false);
    }

    return (
        <ShareContainer ref={ref}>
            <ShareWindow hidden={!open}>
                <Shadowed>
                    <ShareButton
                        color={color}
                        onClick={copyLink}>
                        <Icon className="far fa-link" color={color}/> {label}
                    </ShareButton>
                    <ShareButton
                        color={color}
                        onClick={mailLink}>
                        <Icon className="far fa-envelope" color={color}/> Email
                    </ShareButton>
                </Shadowed>
                <img src="/images/share-arrow.png" alt="divider" style={{zIndex: 1}}/>
            </ShareWindow>
            <DialogButton
                color={color}
                onClick={toggleDialog}>
                <Icon className="far fa-share" color={color}/> Share
            </DialogButton>
        </ShareContainer>
    )
}

const ShareContainer = styled.div`
  position: relative;
  color: #796e65;
  user-select: none;
  display: flex;
  flex-direction: row;
`;

const DialogButton = styled.div`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  padding-right: 0.5rem;

  &:hover {
    color: ${props => props.color};
  }
`;

const ShareButton = styled.div`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  padding-right: 0.25rem;
  cursor: pointer;

  &:hover {
    color: ${props => props.color};
  }
`;

const Icon = styled.i`
  background-color: ${props => props.color};
  color: white;
  padding: .5rem;
  margin-right: .25rem;
`;

const ShareWindow = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 220px;
  bottom: ${props => props.hidden ? '30px' : '40px'};
  left: -0.4em;
  padding: 0;
  pointer-events: ${props => props.hidden ? 'none' : 'all'};
  transition: all 0.125s;
  opacity: ${props => props.hidden ? 0 : 1};

  img {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Shadowed = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 -0.125em .5em #00000050;
  padding: 0.5em 0.5em 0.5em 0.5em;
  background-color: white;
  gap: 0.5em;
`;

export default ShareDialog;
