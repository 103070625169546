import {Link} from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const ResultItemWrapper = styled(({color, ...props}) => (
    <Link {...props} />
))`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  &:hover,
  &:focus {
    color: ${props => props.color};
  }
  // ${breakpoint('sm')` // 24
  //   width: calc(50% - 10px);
  // `}  
  // ${breakpoint('lg')` // 24
  //   width: calc(33.3333333% - 10px);
  // `}
`;


export const Text = styled.p`
  color: inherit;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  //text-transform: uppercase;
  text-align: left;
  margin: 0.5rem 0.1rem;
  height: 3rem;
`;

export const Image = styled.div`
  background: url('${props => props.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: ${props => props.dimmed ? 0.25 : 1};
  width: 100%;
  height: 100%;
  transition: 0.25s ease-out;
`;

export const ImageContainer = styled.div`
  aspect-ratio: 4 / 3;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  background-color: #eee;
  padding: ${props => props.selected ? "0.5rem" : "1rem"};
  transition: 0.125s linear;
  border-radius: ${props => props.borderRadius || "0"};
`;