import {useRef, useState} from "react";
import styled from "styled-components";
import useOnClickOutside from "../../utils/hooks/use-on-click-outside";

const Dropdown = ({selectedId, items, onSelect, primaryColor}) => {

    const ref = useRef();
    const [open, setOpen] = useState(false);
    useOnClickOutside(ref, () => setOpen(false));

    const selectedItem = items.find(item => item.id === selectedId);
    if(!selectedItem) {
        return null;
    }
    const {title} = selectedItem;
    const onClick = (id) => {
        if(id !== selectedId) {
            onSelect(id);
        }
        setOpen(false);
    }

    return (
        <div>
            <Container ref={ref}>
                <Overlay primaryColor={primaryColor}>
                    <DropDownButton primaryColor={primaryColor} onClick={() => setOpen(!open)}
                                    open={open}>{title}</DropDownButton>
                    {
                        open ? <div>
                            {
                                items.map(item => {
                                    const {id, title, locked} = item;
                                    if (locked) return (
                                        <LockedButton key={id}
                                                      primaryColor={primaryColor}>{title}</LockedButton>
                                    )
                                    return (
                                        <Button primaryColor={primaryColor}
                                                key={id}
                                                onClick={() => onClick(id)}>{title}</Button>
                                    )
                                })
                            }
                        </div> : null
                    }
                </Overlay>
            </Container>
            <HorizontalLine/>
        </div>
    )
}

const Container = styled.div`
  user-select: none;
  position: relative;
  margin: 0.25rem 0 0 0;
  height: 2.25rem;
`;

const Overlay = styled.div`
  position: absolute;
  border: 1px solid ${props => props.primaryColor};
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  overflow: hidden;
  transition-duration: 2s;
  transition-timing-function: ease-in-out;
`;

const HorizontalLine = styled.div`
  height: 1px;
  flex: 1;
  background-color: #cccccc;
  margin: 0.75rem 1rem 0.5rem 1rem;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  padding: 0.6rem 0 0.3rem 0;
  color: ${props => props.primaryColor};
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: ${props => props.primaryColor};
    color: white;
  }
`;

const LockedButton = styled.div`
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  padding: 0.6rem 0 0.3rem 0;
  color: ${props => props.primaryColor};
  text-align: center;
  box-sizing: border-box;

  &:after {
    font-family: "Font Awesome Regular";
    color: ${props => props.primaryColor};
    content: "\f023";
    font-size: .75rem;
    position: absolute;
    margin-top: 0.25rem;
    right: .75rem;
  }
`;

const DropDownButton = styled.div`
  display: inline-block;
  font-family: "Roboto", sans-serif;
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  padding: 0.4rem 0 0.2rem 0;
  color: ${props => props.primaryColor};
  cursor: pointer;
  box-sizing: border-box;
  color: ${props => props.open ? "white" : props.primaryColor};
  background-color: ${props => props.open ? props.primaryColor : "white"};

  &:after {
    font-family: "Font Awesome Regular";
    content: '${props => props.open ? "\f106" : "\f107"}';
    font-size: 1rem;
    position: absolute;
    right: .75rem;
    top: .5rem;
  }

`;

export default Dropdown;
