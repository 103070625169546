import styled from 'styled-components';
import CoverImage from "../cover-image";

const StyledHeader = ({ className, images, children, selected }) => (
  <div className={className}>
    {images.map((image, index) => (
      <CoverImage src={image} key={image} hidden={index !== selected} />
    ))}
    {children}
  </div>
)

const Header = styled(StyledHeader)`
  position: relative;  
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export default Header;

