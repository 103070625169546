import styled from "styled-components";

const Text = styled.p`
  color: inherit;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  //text-transform: uppercase;
  text-align: left;
  margin: 0.5rem 0.1rem;
  height: 3rem;
`;

export default Text;
