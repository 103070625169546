const Video = ({ videoSrc, className }) => {
  return (
    <video
      src={videoSrc}
      className={`md:w-full xs:w-initital ${className}`}
      controls
      autoPlay
    />
  );
};

export default Video;
