import NewsItem from "./news-item";
import Paragraph from "./paragraph";

export default function News({ className, items, title, sub_title }) {
  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <div className="flex flex-col gap-4 max-w-[600px] justify-center items-center mt-8 px-4">
        <Paragraph className="text-black text-[32px] text-center py-1">
          {title}
        </Paragraph>
        <Paragraph className="text-black text-[24px] text-center">
          {sub_title}
        </Paragraph>
      </div>
      <div
        className={`max-w-screen-5xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ${className}`}
      >
        {items?.map((item) => (
          <NewsItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}
