import styled from "styled-components";

const FormNotice = styled.div`
  font-size: 1rem;
  color: #000;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  a {
    font-weight: 700;
    color: #000;
  }
`;

export default FormNotice;
