import styled from 'styled-components';
import {API} from "../../../utils/constants";
import ExternalLink from "../../external-link";
import ImageModal from "../../image-modal";
import LightBox from "../../light-box";
import Markdown from "../../markdown";
import LinkRenderer from "../../markdown/LinkRenderer";
import ShareDialog from "../../share-dialog";
import Video from "../../video";

const MediaContent = ({closePath, id, content, sharePath, primaryColor, onExternalLinkClick, onPlayVideo, onShareSuccess}) => {
    const {title, description, image, elements} = content;
    const {url, caption, aspectRatio, formats} = image;
    return (
        <LightBox
            closePath={closePath}
            primaryColor={primaryColor}
            padding={true}>
            <Headline style={{color: primaryColor}}>{title}</Headline>
            <Markdown components={{a: LinkRenderer}}>{description}</Markdown>
            <ImageModal image={image}
                        baseUrl={API.URL}
                        color={primaryColor}
                        key={url}
                        maxHeight={500}
                        style={{marginBottom: '2em', cursor: 'pointer'}}
            />
            {
                elements ?
                    <Elements>
                        {
                            elements.map((el, i) => <RenderElement el={el}
                                                                   key={i}
                                                                   primaryColor={primaryColor}
                                                                   onPlayVideo={onPlayVideo}
                                                                   onExternalLinkClick={onExternalLinkClick}/>)
                        }
                    </Elements>
                    : null
            }
            <div style={{display: "flex", flexDirection: 'row'}}>
                <ShareDialog onShareSuccess={onShareSuccess} color={primaryColor} title={title} path={`${sharePath}`} />
            </div>
        </LightBox>
    )
}

const RenderElement = ({el, primaryColor, onPlayVideo, onExternalLinkClick}) => {
    const {type, url, title, cover, caption} = el;
    switch (type) {
        case "video":
            return (
                <Video src={`${API.URL}${url}`}
                       controls
                       onPlay={() => onPlayVideo({url, caption})}
                       poster={`${API.URL}${cover}`}
                       key={url}>
                    Sorry, your browser doesn't support embedded videos,
                    but don't worry, you can <a href={url}>download it</a>
                    and watch it with your favorite video player!
                </Video>
            )
        case "link":
            return <ExternalLink
                href={url}
                key={url}
                color={primaryColor}
                onClick={() => onExternalLinkClick({title, url})}
                iconClassName="far fa-link">
                {title}
            </ExternalLink>
        default:
            return null;
    }
}

const Headline = styled.h1`
  text-transform: unset;
`;

const Elements = styled.div`
  display: flex;
  flex-direction: column;
`;

export default MediaContent;
