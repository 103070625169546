import styled from "styled-components";
import { ClipLoader } from "react-spinners";

const FormButton = ({ title, loading, ...props }) => {
  return (
    <FormButtonWrapper disabled={loading} {...props}>
      {loading && (
        <FormButtonLoading>
          <ClipLoader size={12} />
        </FormButtonLoading>
      )}
      <FormButtonLabel>{title}</FormButtonLabel>
    </FormButtonWrapper>
  );
};

const FormButtonLabel = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 0.5rem;
`;

const FormButtonLoading = styled.div`
  display: flex;
  justify-content: center;
`;

const FormButtonWrapper = styled.button`
  box-shadow: 2px 5px 4px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-color: #fff;
  text-transform: uppercase;
  color: #1a181b;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1;
  min-width: 8rem;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-evenly;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default FormButton;
