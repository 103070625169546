import {useEffect, } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useLocation, useParams, Navigate } from "react-router-dom";
import Modal from "../../components/modal";
import { useState } from "react";
import { SideBar } from "./SideBar";
import MediaPlayer from "../../components/media-hub/media-player";
import { getMediaId } from "../../utils/media/media";
import { 
  MEDIA_HUB_CONTENT, IN_A_BOX_CONTENT, MEDIA_HUB_TITLE_TILE, 
  IN_A_BOX_TITLE_TILE, IN_A_BOX, MEDIA_HUB, VIDEO_TYPE, LINK_TYPE
} from "../../utils/constants/gls-hub-content";


const getContents = (mediaHubContent, section) => {
  const item = mediaHubContent.find((item) => item.slug === section);
  return item?.contents;
};

const MediaHub = ({selectedHub}) => {
  const [mediaHubContent, setMediaHubContent] = useState([]);
  const [titleTile, setTitleTile] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    switch(selectedHub) {
      case MEDIA_HUB:
        setMediaHubContent(MEDIA_HUB_CONTENT);
        setTitleTile(MEDIA_HUB_TITLE_TILE)
        break;
      case IN_A_BOX:
        setMediaHubContent(IN_A_BOX_CONTENT);
        setTitleTile(IN_A_BOX_TITLE_TILE)
        break;
      default:
        break;
    }
  }, [selectedHub]);
  const [currentMedia, setCurrentMedia] = useState(null);

  const onClick = (media) => {
    const type = media.type ? media.type : VIDEO_TYPE;
    if (type === VIDEO_TYPE) {
      setCurrentMedia({
        title: media?.title,
        url: media?.url,
        id: 1,
      });
    } else if (type === LINK_TYPE) {
      if (media.url.startsWith('http') || media.url.startsWith('https')) {
        window.open(media.url, '_blank');
      } else {
        navigate(media.url);
      }
    }
  };

  const { pathname } = useLocation();
  const { section } = useParams();
  const contents = getContents(mediaHubContent, section);
  if (mediaHubContent.length > 0 && !contents) {
    return <Navigate to={mediaHubContent[0].path} replace />;
  }
  return (
    <OuterWrapper className="page-content-outter media-hub">
      <div className="page-content-inner">
        <SideBar
          menuItems={mediaHubContent}
          currentItem={pathname}
          title={<img alt={pathname} src={titleTile} />}
        />
        <ContentWrapper>
          <div className="page-close-wrapper">
            <Link role="link" to="/gls/lobby" className="page-close-link">
              <i className="fal fa-times" />
            </Link>
          </div>
          <MediaHubWrapper>
            {contents?.map((media) => (
              <MediaHubItem key={media.id} onClick={() => onClick(media)}>
                <div className="media-item-link">
                  <img src={media?.image} alt={media?.title} className="img-fluid" />
                </div>
              </MediaHubItem>
            ))}
          </MediaHubWrapper>
        </ContentWrapper>
      </div>
      <Modal
        footer={null}
        isOpen={!!currentMedia?.id}
        title={currentMedia?.title}
        closeModal={() => setCurrentMedia(null)}
      >
        <MediaPlayer mediaId={getMediaId(currentMedia?.url)} />
      </Modal>
    </OuterWrapper>
  );
};

export default MediaHub;

const MediaHubItem = styled.div`
  padding: 1rem;
  cursor: pointer; /* This makes the cursor a pointer by default */
  max-width: 200px;
  
  &:hover {
    cursor: pointer; /* This ensures the cursor remains a pointer when hovered */
  }
  
  img {
    max-width: 100%;
    height: auto;
    transition: transform 0.2s; /* Optional: Adds a smooth transition effect */
  }
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 1rem;
  }
`;

const MediaHubWrapper = styled.div`
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  @media (max-width: 1200px) {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const ContentWrapper = styled.div`
  padding: 2rem;
  flex-grow: 10;
  display: flex;
  @media (max-width: 1200px) {
    padding: 1rem 0;
  }
  @media (max-width: 768px) {
    flex: 1;
  }
`;

const OuterWrapper = styled.div`
  margin-right: 10px;

  max-width: calc(100vh + 0.8rem);
  @media (max-width: 768px) {
    margin-bottom: 260px;
    max-width: calc(100vw - 3rem - 10px);
  }
`;