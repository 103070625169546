import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../components/gls/form/FormInput";
import { updatePasswordSchema } from "../../utils/form-schemata/FormSchemata";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserPassword } from "../../store/user/actions/UserActions";
import { MessageBox } from "../../components/message-box";
import { useEffect } from "react";

export const UpdatePassword = () => {
  const dispatch = useDispatch();
  const { changePassword } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  const onSubmit = (data) => {
    dispatch(updateUserPassword(data));
  };

  useEffect(() => {
    if (isSubmitSuccessful && changePassword?.success === true) {
      reset()
    }
  }, [changePassword]);

  return (
    <UpdatePasswordForm onSubmit={handleSubmit(onSubmit)}>
      <ProfileItem>
        <ProfileLabel>Current Password</ProfileLabel>
        <ProfileValue>
          <FormInput
            label="Current Password"
            type="password"
            disabled={changePassword?.isUpdating}
            {...register("currentPassword", { required: true })}
            error={errors.currentPassword}
          />
        </ProfileValue>
      </ProfileItem>
      <ProfileItem>
        <ProfileLabel>New Password</ProfileLabel>
        <ProfileValue>
          <FormInput
            label="New Password"
            type="password"
            disabled={changePassword?.isUpdating}
            {...register("password", { required: true })}
            error={errors.password}
          />
        </ProfileValue>
      </ProfileItem>
      <ProfileItem>
        <ProfileLabel>Confirm Password</ProfileLabel>
        <ProfileValue>
          <FormInput
            label="Confirm Password"
            type="password"
            disabled={changePassword?.isUpdating}
            {...register("passwordConfirmation", { required: true })}
            error={errors.passwordConfirmation}
          />
        </ProfileValue>
      </ProfileItem>
      {changePassword?.success === true && (
        <MessageBox
          type="success"
          message="Your action has been successfully executed."
        />
      )}
      {changePassword?.success === false && (
        <MessageBox
          type="error"
          message="Something went wrong. Please try again."
        />
      )}
      <FormButtons>
        <UpdateFormButton type="submit">Save</UpdateFormButton>
      </FormButtons>
    </UpdatePasswordForm>
  );
};

const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;

const UpdateFormButton = styled.button`
  background-color: ${(props) => props.color || "#000"};
  border: none;
  cursor: pointer;
  color: #fff;
  align-items: center;
  padding: 0.5rem 1rem;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const ProfileItem = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ProfileLabel = styled.div`
  width: 8rem;
  padding-right: 0.25rem;
  font-size: 0.9rem;
  margin-top: 8px;
`;

const ProfileValue = styled.div`
  display: flex;
  flex: 1;
  font-weight: bold;
`;

const UpdatePasswordForm = styled.form`
  width: 100%;
  //padding: 1.5rem 0;
`;
