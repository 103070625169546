import { Link } from "react-router-dom";
import styled from "styled-components";

export const SideBar = ({ title, menuItems, currentItem }) => {
  return (
    <SideBarWrapper>
      {title}
      <SideBarNavigation>
        {menuItems?.length > 1 && menuItems.map((item) => (
          <SideBarMenuItem key={item.id} active={currentItem === item?.path}>
            <Link to={item?.path}>{item.sectionTitle}</Link>
          </SideBarMenuItem>
        ))}
      </SideBarNavigation>
    </SideBarWrapper>
  );
};

const SideBarWrapper = styled.div`
  flex: 0 0 25%;
  overflow: auto;
  img {
    max-width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    flex: 1;
    margin-bottom: 60px;
  }
`;

const SideBarNavigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem;
  font-size: 1.2rem;
  font-family: "Roboto Light", sans-serif;
  @media (max-width: 768px) {
    margin: 1rem;
  }
`;

const SideBarMenuItem = styled.div`
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
`;
