import qs from "qs";
import { API } from "../../../utils/constants";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getJwtToken } from "./common";

const getLearningJourneyQuery = (learningJourneySlug) => {
  return qs.stringify({
    populate: {
      sections: {
        "on": {
          "elements.title-section": {
            populate: "*",
          },
          "elements.video-embed": {
            populate: "*",
          },
          "elements.paragraph": {
            populate: "*",
          },
          "elements.carousel": {
            populate: "*",
          },
          "elements.key-priorities": {
            populate: "*",
          },
          "elements.flash-cards": {
            populate: "*",
          },
          "elements.news": {
            populate: {
              news_items: {
                populate: {
                  cover: {
                    populate: "*",
                  },
                },
              },
            },
          },
        },
      },
    },
    filters: {
      slug: {
        $eq: learningJourneySlug,
      },
    },
  });
};

export const useLearningJourney = (learningJourneySlug) => {
  return useQuery({
    queryKey: ["learning-journey", learningJourneySlug],
    queryFn: () =>
      axios
        .get(
          `${API?.URL}/api/journeys?${getLearningJourneyQuery(
            learningJourneySlug
          )}`,
          {
            headers: {
              Authorization: `Bearer ${getJwtToken()}`,
            },
          }
        )
        .then((res) => {
            return res?.data?.data[0]
        }),
  });
};
