import mixpanel from 'mixpanel-browser';
import {put, select, takeEvery} from "redux-saga/effects";
import {MIXPANEL_TOKEN, TRACKING} from "../../../utils/constants";
import {trackingInitialized} from "../actions";

function* init(action) {
    const {payload: {debug}} = action;
    const {tracking: {initialized, disabled}} = yield select();
    if (initialized || disabled) {
        return;
    }
    mixpanel.init(MIXPANEL_TOKEN, {debug, ignore_dnt: true});
    yield put(trackingInitialized());
}

function extractDomainFromEmail(userEmail) {
    if (userEmail && typeof userEmail === 'string') {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(userEmail)) {
            return userEmail.split('@')[1];
        }
    }
    return undefined;
}

function* trackIdentity(action) {
    const {payload: {userId, userEmail}} = action;
    const {tracking: {initialized, disabled}} = yield select();
    if (!initialized || disabled) {
        return;
    }
    mixpanel.identify(userEmail);
    mixpanel.people.set({
        "Email": userEmail,
        "Domain": extractDomainFromEmail(userEmail),
        "User_id": userId
    })
}

function* trackEvent(action) {
    const {payload} = action;
    const {tracking: {disabled}, company: {platformArea}} = yield select();
    if (disabled) {
        console.warn('Tracking Saga: Did not track event because tracking was not initialized or is disabled');
        return;
    }
    if (payload === null || payload === undefined) {
        console.warn('Tracking Saga: Did not track event because payload was null or undefined');
        return;
    }
    const {event, params} = payload;
    mixpanel.track(event, {
        'platformArea': platformArea,
        ...params,
    });
}

function* initWatchers() {
    yield takeEvery(TRACKING.INIT, init);
    yield takeEvery(TRACKING.IDENTITY, trackIdentity);
    yield takeEvery(TRACKING.EVENT, trackEvent);
}

export default initWatchers;
