import styled from "styled-components";

const ExternalLink = ({href, color, children, iconClassName, onClick}) => (
    <div style={{display: 'flex'}}>
        <StyledLink
            href={href}
            key={href}
            target="_blank"
            color={color}
            onClick={onClick}
            rel="noreferrer">
            <Icon className={iconClassName} color={color}/> {children}
        </StyledLink>
    </div>
)

const Icon = styled.i`
  background-color: ${props => props.color};
  color: white;
  padding: .5rem;
  margin-right: .25rem;
`;

const StyledLink = styled.a`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  padding-right: 0.5rem;
  
  &:hover {
    color: ${props => props.color};
  }
`;

export default ExternalLink;
