import axios from "axios";
import {call, put, select, takeEvery} from 'redux-saga/effects';
import {API, USER} from "../../../utils/constants";
import {startLoading, stopLoading} from "../../loading/actions";
import {
    loadTherapeuticAreasError,
    loadTherapeuticAreasSuccess,
    loginError,
    loginSuccess,
    recoverPasswordError,
    recoverPasswordSuccess,
    registerError,
    registerSuccess, resetPasswordError, resetPasswordSuccess
} from "../actions";
import {
    loadUserCompaniesError,
    loadUserCompaniesSuccess,
    loadUserFunctionsError,
    loadUserFunctionsSuccess,
    loadUserLanguagesError,
    loadUserLanguagesSuccess,
    updateUserProfile as updateUserProfileAction,
    updateUserProfileSuccess,
    updateUserProfileError,
    updatePasswordError,
    updatePasswordSuccess,
    uploadUserAvatarError,
    uploadUserAvatarSuccess,
    loadUserProfile as loadUserProfileAction,
    loadUserProfileSuccess,
    loadUserProfileError,
} from "../actions/UserActions";

axios.defaults.headers.common['accept'] = `application/json`;

function* getSavedUserData() {
    const hasStoredItems = !!localStorage.getItem('gls-live');
    if (hasStoredItems) {
        yield put(loginSuccess(getUserData()));
    } else {
    }
}

function* login(action) {
    const {payload} = action;
    const {email, password} = payload;
    const body = {identifier: email, password}

    try {
        yield put(startLoading());
        const response = yield call(axios.post, `${API.URL}${API.LOGIN_PATH_LOCAL}`, body);
        console.log(response);
        const {status, data: {jwt, user: {id}, profile}} = response
        if(status && status === 200) {
            const userData = {jwt, id, profile};
            storeUserData(userData);
            yield put(loginSuccess(userData));
        } else {
            console.log('loginError');
            //yield put(loginError({error}));
        }
        yield put(stopLoading());
    } catch (e) {
        console.log(e);
        const {response: {status}} = e;
        switch(status) {
            case 400:
                const {response: {data: {error}}} = e;
                yield put(loginError({error}));
                break;
            case 429:
                const {response: {data: {message}}} = e;
                const rateError = message.shift().messages.shift();
                yield put(loginError({error: rateError}));
                break;
            default:
                yield put(loginError({error: {message: 'Something went wrong. Please try again later.'}}));
                break;
        }
        yield put(stopLoading(e));
    }
}

function* recover(action) {
    const {payload} = action;
    const {email} = payload;
    const body = {email}

    try {
        yield put(startLoading());
        const response = yield call(axios.post, `${API.URL}${API.FORGOT_PASSWORD_PATH}`, body);
        yield put(recoverPasswordSuccess);
    } catch (e) {
        const {response: {status}} = e;
        switch(status) {
            case 400:
                const {response: {data: {error}}} = e;
                yield put(recoverPasswordError({error}));
                break;
            case 429:
                const {response: {data: {message}}} = e;
                const rateError = message.shift().messages.shift();
                yield put(recoverPasswordError({error: rateError}));
                break;
            default:
                yield put(recoverPasswordError({error: {message: 'Something went wrong. Please try again later.'}}));
                break;
        }
        yield put(stopLoading(e));
    }
}

function* resetPassword(action) {
    const {payload} = action;

    try {
        yield put(startLoading());
        const response = yield call(axios.post, `${API.URL}${API.RESET_PASSWORD_PATH}`, payload);
        yield put(resetPasswordSuccess);
    } catch (e) {
        const {response: {data: {error}}} = e;
        yield put(resetPasswordError({error}));
        yield put(stopLoading(e));
    }
}

function logout(action) {
    removeUserData();
}

function* register(action) {
    const {payload} = action;
    const {
        email,
        user_company,
        therapeutic_area,
        user_function,
        language,
        firstname,
        lastname,
        job_title,
        country_code,
        timezone_offset,
        area_subcategories,
        password
    } = payload;
    const profileBody = {
        data: {
            firstname,
            lastname,
            job_title,
            country_code,
            timezone_offset,
            area_subcategories: { connect: area_subcategories},
            user_company: { connect: [user_company]},
            therapeutic_area: { connect: [therapeutic_area]},
            user_function: { connect: [user_function]},
            language: { connect: [language]}
        }
    }

    try {
        yield put(startLoading());
        const profileResponse = yield call(axios.post, `${API.URL}${API.USER_PROFILE}`, profileBody);
        const { data: { data: { id } } } = profileResponse;
        const body = {
            ...payload,
            username: email,
            user_profile: {
                connect: [id]
            }
        }
        const registerResponse = yield call(axios.post, `${API.URL}${API.REGISTER_PATH_LOCAL}`, body);
        yield put(registerSuccess);
        yield put(stopLoading());
    } catch (e) {
        const {response: {data: {error}}} = e;
        yield put(registerError({error}));
        yield put(stopLoading(e));
    }
}

function* loadUserProfile() {
    try {
        yield put(startLoading());
        const {jwt} = yield select(state => state.user);
        const response = yield call(axios.get, `${API.URL}${API.GET_USER_PROFILE_ME}`, {
            headers: {Authorization: `Bearer ${jwt}`},
        });
        yield put(loadUserProfileSuccess(response?.data));
    } catch (e) {
        console.log("🚀 ~ file: UserSaga.js:189 ~ function*loadUserProfile ~ e:", e)
        const {response: {data: {error}}} = e;
        yield put(loadUserProfileError({error}));
    }
}


function* loadUserCompanies() {
    try {
        yield put(startLoading());
        const response = yield call(axios, `${API.URL}${API.USER_COMPANY_PATH}`);
        yield put(loadUserCompaniesSuccess(response.data));
        yield put(stopLoading());
    } catch (e) {
        const {response: {data: {error}}} = e;
        yield put(loadUserCompaniesError({error}));
        yield put(stopLoading(e));
    }
}

function* loadUserFunctions() {
    try {
        yield put(startLoading());
        const response = yield call(axios, `${API.URL}${API.USER_FUNCTION_PATH}`);
        console.log("🚀 ~ function*loadUserFunctions ~ response:", response)

        yield put(loadUserFunctionsSuccess(response?.data));
        yield put(stopLoading());
    } catch (e) {
        const {response: {data: {error}}} = e;
        yield put(loadUserFunctionsError({error}));
        yield put(stopLoading(e));
    }
}

function* loadUserLanguages() {
    try {
        yield put(startLoading());
        const response = yield call(axios, `${API.URL}${API.USER_LANGUAGES_PATH}`);
        yield put(loadUserLanguagesSuccess(response.data));
        yield put(stopLoading());
    } catch (e) {
        const {response: {data: {error}}} = e;
        yield put(loadUserLanguagesError({error}));
        yield put(stopLoading(e));
    }
}

function* loadUserTherapeuticAreas() {
try {
        yield put(startLoading());
        const response = yield call(axios, `${API.URL}${API.USER_THERAPEUTIC_AREAS_PATH}`);
        console.log("🚀 ~ function*loadUserTherapeuticAreas ~ response:", response)
        yield put(loadTherapeuticAreasSuccess(response?.data));
        yield put(stopLoading());
    } catch (e) {
        const {response: {data: {error}}} = e;
        yield put(loadTherapeuticAreasError({error}));
        yield put(stopLoading(e));
    }
}

function storeUserData(params) {
    localStorage.setItem('gls-live', JSON.stringify(params));
}

function getUserData() {
    return JSON.parse(localStorage.getItem('gls-live'));
}

function removeUserData() {
    localStorage.removeItem('gls-live');
}

function* updateUserProfile(action) {
    const {payload} = action;

    try {
        yield put(startLoading());
        const {jwt , profile} = yield select(state => state.user);

        const response = yield call(axios.put, `${API.URL}${API.UPDATE_USER_PROFILE(profile?.id)}`, payload, {
            headers: {Authorization: `Bearer ${jwt}`},
        });
        const {status} = response
        if(status && status === 200) {
            // const userData = {jwt, id, profile};
            // storeUserData(userData);
            yield put(loadUserProfileAction());
            yield put(updateUserProfileSuccess());
        } else {
            // console.log('loginError');
            yield put(updateUserProfileError());
        }
        yield put(stopLoading());
    } catch (e) {
        console.log(e);
        const {response: {status}} = e;
        switch(status) {
            case 400:
                const {response: {data: {error}}} = e;
                yield put(updateUserProfileError({error}));
                break;
            case 429:
                const {response: {data: {message}}} = e;
                const rateError = message.shift().messages.shift();
                yield put(updateUserProfileError({error: rateError}));
                break;
            default:
                yield put(updateUserProfileError({error: {message: 'Something went wrong. Please try again later.'}}));
                break;
        }
        yield put(stopLoading(e));
    }
}

function* updateUserPassword(action) {
    const {payload} = action;

    try {
        yield put(startLoading());
        const {jwt} = yield select(state => state.user);
        const response = yield call(axios.post, `${API.URL}${API.UPDATE_PASSWORD_PATH}`, payload, {
            headers: {Authorization: `Bearer ${jwt}`},
        });
        const {status} = response
        if(status && status === 200) {
            // const userData = {jwt, id, profile};
            // storeUserData(userData);
            yield put(updatePasswordSuccess());
        } else {
            // console.log('loginError');
            yield put(updatePasswordError());
        }
        yield put(stopLoading());
    } catch (e) {
        console.log(e);
        const {response: {status}} = e;
        switch(status) {
            case 400:
                const {response: {data: {error}}} = e;
                yield put(updatePasswordError({error}));
                break;
            case 429:
                const {response: {data: {message}}} = e;
                const rateError = message.shift().messages.shift();
                yield put(updatePasswordError({error: rateError}));
                break;
            default:
                yield put(updatePasswordError({error: {message: 'Something went wrong. Please try again later.'}}));
                break;
        }
        yield put(stopLoading(e));
    }
}


function* uploadUserAvatar(action) {
    const {payload} = action;
    try {
        yield put(startLoading());
        const {jwt, profile} = yield select(state => state.user);
        const data = new FormData();
        data.append('files', payload);
        data.append('refId', profile?.id);
        data.append('ref', 'api::user-profile.user-profile');
        data.append('field', 'image');
        const response = yield call(axios.post, `${API.URL}${API.UPLOAD_PATH}`, data, {
            headers: {Authorization: `Bearer ${jwt}`, 'Content-Type': 'multipart/form-data'},
        });
        const {status} = response
        if(status && status === 200) {
            // const userData = {jwt, id, profile};
            // storeUserData(userData);
            yield put(uploadUserAvatarSuccess());
            const payloadUserAvatar = {
                data: { image: response.data[0].id }
            }
            yield put(updateUserProfileAction(payloadUserAvatar));
        } else {
            // console.log('loginError');
            yield put(uploadUserAvatarError());
        }
        yield put(stopLoading());
    } catch (e) {
        console.log(e);
        const {response: {status}} = e;
        switch(status) {
            case 400:
                const {response: {data: {error}}} = e;
                yield put(uploadUserAvatarError({error}));
                break;
            case 429:
                const {response: {data: {message}}} = e;
                const rateError = message.shift().messages.shift();
                yield put(uploadUserAvatarError({error: rateError}));
                break;
            default:
                yield put(uploadUserAvatarError({error: {message: 'Something went wrong. Please try again later.'}}));
                break;
        }
        yield put(stopLoading(e));
    }
}

function* initWatchers() {
    yield takeEvery(USER.LOGIN, login);
    yield takeEvery(USER.LOGOUT, logout);
    yield takeEvery(USER.REGISTER, register);
    yield takeEvery(USER.RECOVER_PASSWORD, recover);
    yield takeEvery(USER.CHECK_USER_EXISTS, getSavedUserData);
    yield takeEvery(USER.LOAD_USER_PROFILE, loadUserProfile);
    yield takeEvery(USER.LOAD_USER_COMPANIES, loadUserCompanies);
    yield takeEvery(USER.LOAD_USER_FUNCTIONS, loadUserFunctions);
    yield takeEvery(USER.LOAD_USER_LANGUAGES, loadUserLanguages);
    yield takeEvery(USER.LOAD_USER_THERAPEUTIC_AREAS, loadUserTherapeuticAreas);
    yield takeEvery(USER.RESET_PASSWORD, resetPassword);
    yield takeEvery(USER.UPDATE_USER_PROFILE, updateUserProfile);
    yield takeEvery(USER.UPDATE_USER_PASSWORD, updateUserPassword);
    yield takeEvery(USER.UPLOAD_USER_AVATAR, uploadUserAvatar);
}

export default initWatchers;
