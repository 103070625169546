import qs from "qs";

const companyQuery = qs.stringify(
  {
    populate: {
      topLogo: {
        populate: "*",
      },
      mediaHubHeroImage: {
        populate: "*",
      },
      newsHeroImage: {
        populate: "*",
      },
      newsBannerImage: {
        populate: "*",
      },
      networkingHeroImage: {
        populate: "*",
      },
      horizontalLine: {
        populate: "*",
      },
      lobbyImage: {
        populate: "*",
      },
      media_hubs: {
        populate: {
          bannerImage: {
            populate: "*",
          },
        },
      },
      journey_containers: {
        populate: '*'
      },
      news_categories: {
        populate: {
          items: {
            populate: {
              cover: {
                populate: "*",
              },
              // versions: {
              //     populate: '*',
              //     sort: ['versionNumber:desc'],
              //     limit: 1
              // }
            },
          },
        },
      },
    },
    sort: ["title:asc"],
  },
  { encodeValuesOnly: true }
);

const loginQuery = qs.stringify({
  populate: {
    user_profile: {
      populate: "*",
    },
  },
});

const whatsNewQuery = (companyId) =>
  qs.stringify({
    populate: {
      media: {
        populate: "*",
      },
      company: {
        populate: "*",
      },
    },
    filters: {
      company: {
        id: {
          $eq: companyId,
        },
      },
    },
  });

const mediaHubsQuery = (companyId) =>
  qs.stringify({
    populate: {
      bannerImage: {
        populate: "*",
      },
    },
    filters: {
      company: {
        id: {
          $eq: companyId,
        },
      },
    },
  });

const mediaHubNavQuery = (status, id) =>
  qs.stringify({
    filters: {
      $and: [
        {
          id: {
            $eq: id,
          },
        },
      ],
    },
    populate: {
      media_hub_categories: {
        // status,
        populate: {
          children: {
            // status,
            populate: {
              video: {
                populate: "*",
              },
            },
          },
        },
      },
    },
  });

const learningJourneyNavQuery = (status) =>
  qs.stringify({
    populate: {
      journey_categories: {
        populate: '*',
      },
    },
  });

const mediaHubItemsQuery = (categoryId, status) =>
  qs.stringify({
    populate: {
      image: {
        populate: {
          formats: "*",
        },
      },
      elements: {
        populate: {
          videoFile: {
            populate: "*",
          },
          coverImage: {
            populate: "*",
          },
        },
      },
    },
    filters: {
      categories: {
        id: {
          $eq: categoryId,
        },
      },
    },
    sort: ["title:asc"],
    pagination: {
      pageSize: 100,
    },
  });

const mediaHubItemsByCategoryQuery = (status, id) =>
  qs.stringify({
    filters: {
        id: {
            $eq: id,
        }
    },
    populate: {
        items: {
            populate: [
                'image',
                'elements.image',
                'elements.videoFile',
                'elements.coverImage'
            ]
        },
    },
    pagination: {
      pageSize: 100,
    },
  });

const mediaHubItemQuery = qs.stringify({
  populate: [
    'image',
    'elements.image',
    'elements.videoFile',
    'elements.coverImage',
  ],
  pagination: {
    pageSize: 100,
  },
});

const mediaHubItemQueryPreview = qs.stringify({
  populate: {
    image: {
      populate: "*",
    },
    elements: {
      populate: "*",
    },
  },
  pagination: {
    pageSize: 100,
  },
});

const stickerQuery = (companyId) =>
  qs.stringify({
    populate: {
      sticker: {
        populate: "*",
      },
      company: {
        populate: "*",
      },
    },
    filters: {
      company: {
        id: {
          $eq: companyId,
        },
      },
    },
  });

const areaSubCategoryQuery = (therapeuticId) =>
  qs.stringify({
    populate: "*",
    filters: {
      therapeutic_areas: {
        id: {
          $eq: therapeuticId,
        },
      },
    },
  });

const eventsPagesQuery = (companyId) =>
  qs.stringify({
    populate: {
      image: {
        populate: "*",
      },
      company: {
        populate: "*",
      },
      links: {
        populate: "*",
      },
      background_image: {
        populate: "*",
      },
      back_button_color: {
        populate: "*",
      },
    },
    filters: {
      company: {
        id: {
          $eq: companyId,
        },
      },
    },
  });

const newsNavQuery = (companyId) =>
  qs.stringify({
    populate: {
      items: {
        populate: {
          cover: {
            populate: "*",
          },
        },
      },
      company: {
        populate: "*",
      },
    },
    pagination: {
      pageSize: 100,
    },
    sort: ["title:desc"],
    filters: {
      company: {
        id: {
          $eq: companyId,
        },
      },
    },
  });

const newsItemQuery = (id) =>
  qs.stringify({
    populate: [
      'cover',
      'cover.image',
      'elements.image',
      'elements.videoFile',
      'elements.coverImage',
      'elements.external-link',
      'elements.pdfFile',
      'elements.media_hub_items',
      'elements.media_hub_items.image'
    ],
    pagination: {
      pageSize: 100,
    },
    filters: {
      id: {
        $eq: id,
      },
    },
  });

export {
  companyQuery,
  loginQuery,
  whatsNewQuery,
  mediaHubsQuery,
  mediaHubNavQuery,
  mediaHubItemsQuery,
  mediaHubItemQuery,
  mediaHubItemQueryPreview,
  stickerQuery,
  eventsPagesQuery,
  newsNavQuery,
  newsItemQuery,
  mediaHubItemsByCategoryQuery,
  areaSubCategoryQuery,
  learningJourneyNavQuery
};
