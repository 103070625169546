import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Center from '../../components/center';
import Layout from "../../components/content-area";
import MediaContent from "../../components/media-hub/media-content";
import MediaList from "../../components/media-list";
import SectionHeader from "../../components/section-header";
import SideBar from "../../components/side-bar";
import TopLogo from "../../components/top-logo";
import {API, GENERAL} from "../../utils/constants";
import {loadContents, loadNavigation, resetNavigation} from "../../store/media-hub/actions";
import {TrackingEvent, TrackingObject, TrackingPageName} from "../../store/tracking/TrackingObject";
import {trackEvent} from "../../store/tracking/actions";
import { SearchForm } from "../../components/search-form";


const MediaHub = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [item, setItem] = useState(null);
    const {video = null, cover = null, caption = null} = item || {};
    const [itemContent, setItemContent] = useState(null);
    const { pathname} = useLocation();
    const sharePath = GENERAL.LIVE_DEEPLINK + pathname;

    const {
        companyName,
        mediaHubId,
        sectionId,
        itemId,
        mediaId,
    } = useParams();

    const {
        items,
        initialSectionId,
        initialItemId,
        content,
        primaryColor,
        mediaHubs,
        topLogo,
        cornerRadius,
    } = useSelector(state => {
        const {
            mediaHub: {
                items,
                initialSectionId,
                initialItemId,
                content
            },
            company: {
                config: {primaryColor, topLogo, cornerRadius},
                mediaHubs,
            }
        } = state;
        return {items, initialSectionId, initialItemId, content, primaryColor, mediaHubs, topLogo, cornerRadius};
    });

    const setPath = (selected) => {
        navigate(`/${companyName}/mediahub/${mediaHubId}/${selected[0]}/${selected[1]}#content`);
    }

    const mediaHub = mediaHubs.find(item => item.id === mediaHubId) || mediaHubs[0];
    const {bannerImage = "", title = ""} = mediaHub || {};

    const onSelectMediaHub = (id) => {
        dispatch(resetNavigation());
        navigate(`/${companyName}/mediahub/${id}`,);
    }

    useEffect(() => {
        dispatch(loadNavigation(mediaHubId));
    }, [mediaHubId, dispatch]);

    useEffect(() => {
        if (items?.length && mediaHubId && sectionId && itemId) {
            if(mediaId && itemContent) {
                dispatch(trackEvent(TrackingObject(TrackingEvent.CONTENT_VIEW, {
                    pageName: TrackingPageName.MEDIA_HUB,
                    indication: mediaHub.title,
                    mainCategory: items.find(item => item.id === sectionId)?.title,
                    subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
                    contentTitle: itemContent.title,
                })));
            } else {
                dispatch(trackEvent(TrackingObject(TrackingEvent.PAGE_VIEW, {
                    pageName: TrackingPageName.MEDIA_HUB,
                    indication: mediaHub.title,
                    mainCategory: items.find(item => item.id === sectionId)?.title,
                    subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
                })));
            }
        }
    }, [items, mediaHubId, sectionId, itemId, mediaId, itemContent, dispatch, title]);

    const trackVideoPlay = ({caption, url, isContent = true}) => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.VIDEO_PLAY, {
            pageName: TrackingPageName.MEDIA_HUB,
            indication: mediaHub.title,
            mainCategory: items.find(item => item.id === sectionId)?.title,
            subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
            contentTitle: isContent ? itemContent.title : null,
            videoCaption: caption,
            videoFileName: url,
        })));
    }

    const trackExternalLinkClick = ({title, url}) => {
        dispatch(trackEvent(TrackingObject(TrackingEvent.EXTERNAL_LINK_CLICK, {
            pageName: TrackingPageName.MEDIA_HUB,
            indication: mediaHub.title,
            mainCategory: items.find(item => item.id === sectionId)?.title,
            subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
            contentTitle: itemContent.title,
            externalContentTitle: title,
            externalContentUrl: url,
        })));
    }

    const trackShareEvent = (event, {title, path}) => {
        const events = [TrackingEvent.SHARE_COPY_LINK, TrackingEvent.SHARE_EMAIL, TrackingEvent.SHARE]
        if (events.includes(event)) {
            dispatch(trackEvent(TrackingObject(event, {
                pageName: TrackingPageName.NEWS,
                mainCategory: items.find(item => item.id === sectionId)?.title,
                subCategory: items.find(item => item.id === sectionId)?.children.find(child => child.id === itemId)?.title,
                contentTitle: itemContent.title,
                externalContentTitle: title,
                externalContentUrl: path,
                path,
            })));
        } 
    }

    useEffect(() => {
        if (!sectionId && !itemId && initialSectionId && initialItemId) {
            // setPath([initialSectionId, initialItemId])
        }
        
        if (items?.length && initialSectionId && initialItemId) {
            if (sectionId && itemId) {
                dispatch(loadContents(itemId));
            } else if (sectionId && !itemId) {
                dispatch(loadContents(sectionId));
                return;
            } else {
                dispatch(loadContents(initialItemId));
            }
        }
        if (items?.length > 0 && (sectionId || initialSectionId) && (itemId || initialItemId)) {
            const itemToSet = items.find(item => item.id === (sectionId || initialSectionId))
            if (itemToSet) {
                setItem(itemToSet?.children.find(child => child.id === (itemId || initialItemId)));
            }
        }
    }, [items, itemId, initialSectionId, initialItemId, dispatch, sectionId])

    useEffect(() => {
        if (content && mediaId) {
            setItemContent(content.find(item => item.id === mediaId));
        }
    }, [content, mediaId]);

    return (
        <Center hidden={false}>
            <SectionHeader>
                <img src={`${API.URL}${bannerImage}`} alt="Media Hub Header"/>
                <TopLogo src={`${API.URL}${topLogo}`}/>
            </SectionHeader>
            {
                items && initialSectionId && initialItemId ?
                    <Layout hidden={!items} sideBar={
                        <SideBar items={items}
                                 pageName={TrackingPageName.MEDIA_HUB}
                                 primaryColor={primaryColor}
                                 selected={[sectionId || initialSectionId, itemId || initialItemId]}
                                 setSelected={setPath}
                                 divisionId={mediaHubId}
                                 divisions={mediaHubs}
                                 onDivisionSelect={onSelectMediaHub}
                        />
                    }>
                        {
                            // TODO: Implement tracking here
                            video ? <Video
                                src={`${API.URL}${video}`}
                                controls
                                poster={`${API.URL}${cover}`}
                                onPlay={() => trackVideoPlay({url: video, caption, isContent: false})}
                                key={video}>
                                Sorry, your browser doesn't support embedded videos,
                                but don't worry, you can <a href={video}>download it</a>
                                and watch it with your favorite video player!
                            </Video> : null
                        }
                        <SearchForm primaryColor={primaryColor}/>
                        {
                            content ?
                                <MediaList items={content}
                                           primaryColor={primaryColor}
                                           borderRadius={cornerRadius}
                                           path={`/${companyName}/mediahub/${mediaHubId}/${sectionId || initialSectionId}/${itemId || initialItemId}/`}/>
                                : null
                        }
                    </Layout>
                    : null
            }
            {
                mediaId && itemContent ?
                    <MediaContent
                        primaryColor={primaryColor}
                        closePath={`/${companyName}/mediahub/${mediaHubId}/${sectionId || initialSectionId}/${itemId || initialItemId}`}
                        onPlayVideo={trackVideoPlay}
                        onExternalLinkClick={trackExternalLinkClick}
                        sharePath={sharePath}
                        onShareSuccess={trackShareEvent}
                        content={itemContent}/>
                    : null
            }
        </Center>
    )
}

const Video = styled.video`
  display: flex;
  width: calc(100% - 5px);
  margin-bottom: 2rem;
  ${breakpoint('lg')` // 24
    width: 66%;
  `}
`;

export default MediaHub;
