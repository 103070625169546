import React from 'react';
import styled from "styled-components";
import { useAppConfig } from '../../contexts/app-config/app-config-context';

// Define the styled component without the background-image property
const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
`;

// Functional component that uses the AppConfig context
const GenericBackground = ({ children }) => {
  const { config } = useAppConfig() || {}; 

  // Styles object to handle dynamic background
  const backgroundStyle = {
    backgroundImage: config && config.background_image ? `url(${config.background_image})` : 'none'
  };

  return (
    <StyledBackground style={backgroundStyle}>
      {children}
    </StyledBackground>
  );
};

export default GenericBackground;
