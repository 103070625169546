import {LEARNING_JOURNEY} from "../../../utils/constants";

const resetNavigation = () => (
    {
        type: LEARNING_JOURNEY.RESET_NAV,
    }
)

const loadNavigation = (learningJourneyId) => (
    {
        type: LEARNING_JOURNEY.LOAD_NAV,
        payload: learningJourneyId,
    }
)


export {resetNavigation, loadNavigation};
