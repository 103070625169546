import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import GenericBackground from "../../components/generic-background";
import {loginUser} from "../../store/user/actions";

const Connect = () => {
    const [text, setText] = useState('Validating your credentials.');
    const [deeplink, setDeeplink] = useState(null); // Initialize with null
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loggedIn } = useSelector(state => {
        const { user: { loggedIn } } = state;
        return { loggedIn };
    });

    const retrieveDeeplink = () => {
        const deeplink = localStorage.getItem('deeplink');
        if(deeplink) {
            localStorage.removeItem('deeplink');
            setDeeplink(deeplink);
        }
    }

    useEffect(() => {
        retrieveDeeplink();
        dispatch(loginUser(location.search));
    }, [dispatch, location.search]);

    useEffect(() => {
        if (loggedIn) {
            setText('Login successful.');
            setTimeout(() => {
                if (deeplink) {
                    navigate(deeplink, { replace: true });
                } else {
                    navigate('/', { replace: true });
                }
            }, 500);
        }
    }, [loggedIn, navigate, deeplink]);

    return (
        <GenericBackground noNavbar>
            <p>{text}</p>
        </GenericBackground>
    )
}

export default Connect;
