import {Fragment, useState} from "react";
import Modal from 'react-modal';
import LoadableImage from "../loadable-image";

const customStyles = {
    content: {
        border: 0,
        borderRadius: 0,
        padding: 0,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '80%',
        backgroundColor: 'transparent',
    },
    overlay: {
        zIndex: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }
};

const ImageModal = ({image, style, baseUrl, maxHeight = 500, color}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const {caption, formats, aspectRatio} = image;
    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }
    return (
        <Fragment>
            <LoadableImage aspectRatio={aspectRatio}
                           formats={formats}
                           baseUrl={baseUrl}
                           caption={caption}
                           maxHeight={maxHeight}
                           color={color}
                           onClick={openModal}
                           style={style}/>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                preventScroll={true}
                style={customStyles}
            >
                <LoadableImage aspectRatio={aspectRatio}
                               formats={formats}
                               baseUrl={baseUrl}
                               caption={caption}
                               maxHeight={window.innerHeight - 80}
                               color={color}
                               onClick={closeModal}
                               style={{cursor: 'pointer'}}/>
            </Modal>
        </Fragment>
    );
}

export default ImageModal;
