import { getTimeZones, timeZonesNames } from "@vvo/tzdb";

export const getCurrentTimeZone = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
};

export const transformTimezones = () =>
  getTimeZones().map((timezone) => {
    const { name, group } = timezone;
    return { value: name, label: name, group};
  });

export const getTimeZoneValue = (name) => {
  return timeZonesNames.find((timezone) => timezone === name);
};

export const getDefaultTimezone = (timezones, current) => {
  const value = timezones.find(
    (timezone) => timezone.value === current || timezone.group.includes(current)
  );
  return value;
};