import {NEWS} from "../../../utils/constants";

const loadNavigation = (companyId) => (
    {
        type: NEWS.LOAD_NAV,
        payload: companyId,
    }
)

const loadContents = (sections) => (
    {
        type: NEWS.LOAD_CONTENT,
        payload: sections,
    }
)

export {loadNavigation, loadContents};
