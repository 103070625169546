import {TRACKING} from "../../../utils/constants";

const STATE = {
    initialized: false,
    disabled: false,
}

const tracking = (state = STATE, action) => {
    const {type} = action;
    switch (type) {
        case TRACKING.INITIALIZED:
            return {...state, initialized: true};
        case TRACKING.DISABLE:
            return {...state, initialized: false, disabled: true};
        default:
            return state;
    }
}

export default tracking;
