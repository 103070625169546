import { useState, useEffect } from "react";
import MeiliSearch from "meilisearch";
import { useAppConfig } from "../../../contexts/app-config/app-config-context";


const useSearchEngine = (query, company) => {
  const { config } = useAppConfig()
  const client = new MeiliSearch({
    host: config?.search_engine_url,
    apiKey: config?.search_engine_apikey,
  });
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const search = async () => {
      try {
        setIsLoading(true);
        const searchResults = await client.multiSearch({
          queries: [
            {
              indexUid: "media-hub-item",
              q: query,
              filter: `(categories.media_hub.company.slug = '${company}' OR categories.parent.media_hub.company.slug = '${company}')`,
            },
            {
              indexUid: "news-item",
              q: query,
            },
          ],
        });
        setResults(searchResults.results);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    if (query) {
      search();
    } else {
      setResults([]);
    }
  }, [query]);

  return { results, isLoading, error };
};

export default useSearchEngine;
