import {useSelector} from "react-redux";
import {Outlet, ScrollRestoration} from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Center from "../../components/center";
import ColoredDivider from "../../components/section-header/colored-divider";
import {API} from "../../utils/constants";
import { useAppConfig } from "../../contexts/app-config/app-config-context";
import { transformBlockToText } from "../../utils/mappings/app-config.util";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";


const FooterLayout = () => {
    const { config } = useAppConfig();

    const { horizontalLine } = useSelector((state) => {
        const {
            company: {
                config: { horizontalLine },
            },
        } = state;
        return { horizontalLine };
    });
    const footerDisclaimerText = transformBlockToText(config?.footer_text)

    return (
        <Center>
            <Outlet />
            <ColoredDivider image={`${API.URL}${horizontalLine}`} />
            <Footer>
                <FooterLogos>
                    <img src={config?.footer_left_logo} alt="logo" />
                    <img src={config?.footer_right_logo} alt="logo" />
                </FooterLogos>
                <FooterDisclaimer><BlocksRenderer content={footerDisclaimerText} /></FooterDisclaimer>
            </Footer>
            <ScrollRestoration />
        </Center>
    );
};

const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  width: 100%;
  background-color: #eee;
  padding: 1rem 2rem 1rem 2rem;

  img {
    height: 40px;
  }
`;

const FooterLogos = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
  background-color: #eee;
  padding: 1rem 2rem 0rem 2rem;
  width: 100%;

  img {
    height: 40px;
  }

  @media (max-width: 576px) { // Adjust the width as per your xs breakpoint definition
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

const FooterDisclaimer = styled.p`
    font-size: 0.6rem;
    text-align: center;
    p {
        font-size: 0.6rem;
    }
`;

export default FooterLayout;
