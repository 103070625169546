import styled from "styled-components";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 15px;
  padding: 0.1rem 0 0 0;
`;

export default Grid;
