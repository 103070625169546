import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const NonClickable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: calc(50% - 10px);
  ${breakpoint('xl')` // 24
    width: calc(33.3333333% - 10px);
  `}
  color: #796e65;
`;

export default NonClickable;
