import { API } from "../constants";
import { get } from "lodash";

export const getMediaPath = (image, type = "thumbnail") => {
  const media = get(image?.formats, type);
  if (!image || !media) return null;

  return API?.URL +  media?.url;
};

export const getMediaId = (url) => {
  if (!url) return null;
  // Create a URL object
  const urlObj = new URL(url);

  // Split the pathname by '/' and filter out empty strings
  const pathSegments = urlObj.pathname.split('/').filter(Boolean);

  // Check if there are at least two segments in the path
  if (pathSegments.length >= 1) {
      // Return the second segment
      return pathSegments[0];
  } else {
      // Return null or a default value if there are less than two segments
      return null;
  }
}
